// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse, Typography } from '@mui/material'

import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import SelectorLight from '../../../../../../ui/selector/index.tsx'

import { setName } from '../../../../../../store/slices/app/controlers/pageName.js'
import { toggleEnableStep } from '../../../../../../store/slices/app/controlers/stepper.js'

import Input from '../../../../../../ui/input/index.tsx'
import Tooltip from '../../../../../../ui/tooltip/index.tsx'

import R from '../../../../../../services/app/client-server/request.service.ts'

import useGenerateProtocolData from '../../../../../hooks/useGenerateProtocolDataMi1592'
import AddImage from '../../../water/Gost-8-1012-2022/components/AddImage.jsx'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions.js'

import CheckCircleIcon from '../../../../../../img/icon/CheckCircleIcon.jsx'
import VisibilityToggleIcon from '../../../../../../img/icon/VisibilityToggleIcon.jsx'

import { useParams } from 'react-router-dom'

const MeterData = props => {
  const {
    meterFactoryNumber,
    meterYear,
    meterId,
    modifications,
    selectedAdditionals,
    valueMod,
    valueAdditional,
    valueAnotherMod,
    is_custom_modification,
    changeMeter,
    defaultData,
    edit = false,
    conditions,
    meterViewStatus,
  } = props
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { defaultValuesStartFinish } = useGenerateProtocolData()

  const { protocol_id } = useParams()

  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const [metersList, setMetersList] = useState([])
  const measurementId = useSelector(state => state.protocolReducer.measurementId)

  const enableNextStep = useMemo(() => {
    if (meterId && (valueMod || valueAnotherMod) && meterFactoryNumber && meterYear) return true
    else return false
  }, [meterId, valueMod, valueAnotherMod, meterFactoryNumber, meterYear])

  const translateStatus = status => {
    switch (status) {
      case 'default':
        return ''
      case 'outdated':
        return 'старая версия'
      case 'deleted':
        return 'удален'
      case 'updated':
        return 'изменённая версия'
      default:
        return ''
    }
  }

  const optionsMeter = metersList
    ? metersList.map(item => ({
        value:
          edit && item?.meter_view_status
            ? `${item.fif_number} ${item.meter_view_status}`
            : item.fif_number,
        label:
          edit && item?.meter_view_status && item.meter_view_status !== 'default'
            ? `${item.fif_number} (${translateStatus(item.meter_view_status)}), ${item.name_si}, ${item.manufacturer_name}`
            : `${item.fif_number}, ${item.name_si}, ${item.manufacturer_name}`,
      }))
    : []

  const getValueMeter = selectedOption => {
    if (selectedOption) {
      const [selectedValue, ...statusParts] = selectedOption.value.split(' ')
      const selectedStatus = statusParts.join(' ') || 'default'

      dispatch(changeMeter({ value: 'meterId', label: selectedValue }))
      dispatch(changeMeter({ value: 'meterViewStatus', label: selectedStatus }))

      defaultValuesStartFinish()

      dispatch(changeMeter({ value: 'selectedMod', label: null }))
      dispatch(changeMeter({ value: 'valueMod', label: null }))
      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: 'vertical' }))

      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))

      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))

      dispatch(changeMeter({ value: 'selectedAdditionals', label: [] }))
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))

      if (conditions && conditions.length > 0) {
        const updatedConditions = conditions.map(condition => ({
          ...condition,
          water_temp: '',
        }))
        dispatch(changeMeter({ value: 'conditionsValuesEdit', label: updatedConditions }))
      }
    }
  }

  const optionsMod = modifications
    ? modifications
        .map(item => ({
          value: item.name,
          label: item.name,
        }))
        .concat([
          {
            value: 'Нет модификации',
            label: 'Нет модификации',
          },
          {
            value: 'another',
            label: 'Другая модификация',
          },
        ])
    : []

  const getValueMod = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueMod',
          label: selectedOption.value,
        }),
      )

      const selectedMod = modifications.find(item => item.name === selectedOption.value)

      if (selectedMod) {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: selectedMod.q_max_limit_cold ?? '',
              q_max_limit_hot: selectedMod.q_max_limit_hot ?? '',
              q_min_limit_cold: selectedMod.q_min_limit_cold ?? '',
              q_min_limit_hot: selectedMod.q_min_limit_hot ?? '',
              conversion_factor: selectedMod.conversion_factor ?? '',
              mpi_cold: defaultData.mpi_cold ?? '',
              mpi_hot: defaultData.mpi_hot ?? '',
            },
          }),
        )

        const selectedAdditionals =
          selectedMod.additionals && selectedMod.additionals.length > 0
            ? selectedMod.additionals
            : []

        dispatch(
          changeMeter({
            value: 'conversion_factor',
            label: selectedMod.conversion_factor,
          }),
        )
        dispatch(
          changeMeter({
            value: 'selectedAdditionals',
            label: selectedAdditionals,
          }),
        )
        dispatch(
          changeMeter({
            value: 'selectedMod',
            label: selectedMod,
          }),
        )
      } else {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: '',
              q_max_limit_hot: '',
              q_min_limit_cold: '',
              q_min_limit_hot: '',
              conversion_factor: '',
              mpi_cold: defaultData.mpi_cold ?? '',
              mpi_hot: defaultData.mpi_hot ?? '',
            },
          }),
        )
        dispatch(changeMeter({ value: 'selectedAdditionals', label: [] }))
        dispatch(changeMeter({ value: 'selectedMod', label: null }))
      }

      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: 'vertical' }))
      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))
      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))
    }
  }

  const optionsAdditionals = selectedAdditionals
    ? selectedAdditionals.map(item => ({
        value: item.text,
        label: item.text,
      }))
    : []

  const getValueAdditionals = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueAdditional',
          label: selectedOption.value,
        }),
      )

      const selectedAdditional = selectedAdditionals.find(
        item => item.text === selectedOption.value,
      )
      if (selectedAdditional) {
        dispatch(
          changeMeter({
            value: 'mpiColdAdditionals',
            label: selectedAdditional.mpi_cold,
          }),
        )

        dispatch(
          changeMeter({
            value: 'mpiHotAdditionals',
            label: selectedAdditional.mpi_hot,
          }),
        )
      }
    }
  }

  const handleValueModChange = param => {
    dispatch(
      changeMeter({
        value: 'valueAnotherMod',
        label: param,
      }),
    )
  }

  useEffect(() => {
    if (valueMod === 'another') {
      dispatch(changeMeter({ value: 'is_custom_modification', label: true }))
    } else {
      dispatch(changeMeter({ value: 'is_custom_modification', label: false }))
    }
  }, [valueMod, dispatch])

  useEffect(() => {
    if (is_custom_modification) {
      dispatch(changeMeter({ value: 'valueMod', label: 'another' }))
    }
  }, [is_custom_modification])

  const handleMeterFactoryNumberChange = param => {
    dispatch(
      changeMeter({
        value: 'meterFactoryNumber',
        label: param,
      }),
    )
  }

  const yearlist = Array.from({ length: 25 }, (item, index) => (2000 + index).toString())

  yearlist.push('Не выбрано')

  const optionsYear = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValueYear = selectedOption => {
    dispatch(
      changeMeter({
        value: 'meterYear',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  useEffect(() => {
    ;(async () => {
      let metersData

      if (edit) {
        const { data: metersListByProtocol } = await R.getMetersListByProtocol(`${protocol_id}`)
        metersData = metersListByProtocol.data
      } else {
        const { data: metersListResponse } = await R.getMetersList(
          `?measurement_id=${measurementId}`,
        )
        metersData = metersListResponse.data
      }

      setMetersList(metersData)

      if (meterId) {
        let selectedData = metersData.find(item => item.fif_number === meterId)

        if (selectedData && meterViewStatus && meterViewStatus !== 'default') {
          selectedData =
            metersData.find(
              item => item.fif_number === meterId && item.meter_view_status === meterViewStatus,
            ) || selectedData
        }

        if (selectedData) {
          dispatch(changeMeter({ value: 'modifications', label: selectedData.modifications || [] }))

          dispatch(
            changeMeter({
              value: 'defaultData',
              label: { mpi_cold: selectedData.mpi_cold, mpi_hot: selectedData.mpi_hot },
            }),
          )
        }
      }
    })()
  }, [measurementId, protocol_id, edit, meterId, meterViewStatus])

  useEffect(() => {
    dispatch(setName('Данные о поверяемом приборе'))
  }, [dispatch])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: width > 1050 ? '24px' : '15px' }}>
      {width > 1050 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: width > 1050 ? '8px' : '0px',
          }}
        >
          <Typography variant='h2'>Данные о поверяемом приборе</Typography>

          {edit && meterViewStatus !== 'default' && (
            <Typography variant='h6' sx={{ color: '#E57004' }}>
              {meterViewStatus === 'outdated' &&
                'Некоторые данные указанного СИ были изменены, новая версия прибора доступна в выпадающем списке'}
              {meterViewStatus === 'deleted' && 'Указанный СИ был удалён из системы'}
              {meterViewStatus === 'updated' &&
                'Данные указанного СИ были изменены пользователем при создании протокола, оригинальная версия СИ доступна в выпадающем списке'}
            </Typography>
          )}

          {(pointId === 4 || pointId === 5) && (
            <AddImage
              title={
                'Согласно пункту № 4.3.1 Методика поверки «Рг» ГОСТ 8.1012-2022 (с изменением №1), при проведении поверки осуществляют цифровую фиксацию (ЦФ) поверки. В рамках ЦФ происходит фотофиксация  воды до начала поверки, на которой необходимо отобразить заводской номер счетчика воды и показания накопленного объема.'
              }
              count={2}
            />
          )}
        </div>
      )}
      <div className='flexContainerWithGap' style={{ margin: 0, gap: width > 1050 ? '' : '15px' }}>
        <div className='width50Percent'>
          {width <= 1050 && (
            <React.Fragment>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  position: 'relative',
                  marginBottom: '9px',
                  gap: '6px',
                }}
              >
                <span style={{ display: 'block' }}>
                  Поверяемый прибор ( № в Госреестре, завод производитель )
                </span>
                {optionsMeter.find(option => option.value === `${meterId} ${meterViewStatus}`) ||
                optionsMeter.find(option => option.value === meterId) ? (
                  <span
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                      }}
                    />
                  </span>
                )}
              </span>
              <SelectorLight
                placeholder={'Выберите прибор'}
                options={optionsMeter}
                onChange={getValueMeter}
                value={
                  meterId
                    ? optionsMeter.find(
                        option => option.value === `${meterId} ${meterViewStatus}`,
                      ) ||
                      optionsMeter.find(option => option.value === meterId) ||
                      null
                    : null
                }
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => 'Нет доступных приборов'}
              />
            </React.Fragment>
          )}

          {width > 1050 && (
            <Selector
              placeholder={'Выберите прибор'}
              options={optionsMeter}
              onChange={getValueMeter}
              value={
                meterId
                  ? optionsMeter.find(option => option.value === `${meterId} ${meterViewStatus}`) ||
                    optionsMeter.find(option => option.value === meterId) ||
                    null
                  : null
              }
              isClearable={true}
              isRequired={true}
              isSearchable={true}
              noOptionsMessage={() => 'Нет доступных приборов'}
              label={
                width > 1050
                  ? {
                      text:
                        width > 1050
                          ? 'Поверяемый прибор ( № Госреестра, Завод производитель )'
                          : 'Поверяемый прибор',
                      topBg: 'white',
                      bottomBg: 'white',
                    }
                  : null
              }
            />
          )}
        </div>
        <div className={'width50Percent'}>
          {width <= 1050 && (
            <span
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                position: 'relative',
                marginBottom: '9px',
                gap: '6px',
              }}
            >
              <span style={{ display: 'block' }}>Модификация прибора</span>
              {optionsMod.find(option => option.value === valueMod) ? (
                <span
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    marginBottom: '-2px',
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: '#63E6BE',
                    }}
                  />
                </span>
              ) : (
                <span
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    marginBottom: '-1px',
                  }}
                >
                  <VisibilityToggleIcon
                    sx={{
                      color: '#FFD43B',
                    }}
                  />
                </span>
              )}
            </span>
          )}
          {edit && meterViewStatus !== 'default' ? (
            <Tooltip title={'Недоступно для выбранного СИ'} placement={'top-start'}>
              <Typography
                sx={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  padding: '14px 16px',
                  borderRadius: '12px',
                  fontSize: '15px',
                }}
              >
                {valueMod}
              </Typography>
            </Tooltip>
          ) : (
            <React.Fragment>
              {width > 1050 ? (
                <Selector
                  placeholder={'Выберите модификацию'}
                  options={optionsMod}
                  onChange={getValueMod}
                  isClearable={true}
                  isSearchable={false}
                  isRequired={true}
                  noOptionsMessage={() => 'Выберите поверяемый прибор'}
                  value={valueMod ? optionsMod.find(option => option.value === valueMod) : null}
                  label={
                    width > 1050
                      ? {
                          text: 'Модификация прибора',
                          topBg: 'white',
                          bottomBg: 'white',
                        }
                      : null
                  }
                />
              ) : (
                <SelectorLight
                  placeholder={'Выберите модификацию'}
                  options={optionsMod}
                  onChange={getValueMod}
                  isClearable={true}
                  isSearchable={false}
                  noOptionsMessage={() => 'Выберите поверяемый прибор'}
                  value={valueMod ? optionsMod.find(option => option.value === valueMod) : null}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <Collapse
        in={selectedAdditionals && selectedAdditionals.length > 0}
        timeout={500}
        unmountOnExit
      >
        <div className='width24Point5Percent' style={{ width: '100%' }}>
          {width <= 1050 && (
            <React.Fragment>
              <span style={{ display: 'block', marginBottom: '9px' }}>Дополнительное условие</span>
              <SelectorLight
                placeholder={'Выберите доп. условие'}
                options={optionsAdditionals}
                onChange={getValueAdditionals}
                isClearable={false}
                isSearchable={false}
                value={
                  selectedAdditionals
                    ? !!optionsAdditionals.find(option => option.value === valueAdditional)
                      ? optionsAdditionals.find(option => option.value === valueAdditional)
                      : null
                    : null
                }
              />
            </React.Fragment>
          )}
          {width > 1050 && (
            <Selector
              placeholder={'Выберите доп. условие'}
              options={optionsAdditionals}
              onChange={getValueAdditionals}
              isClearable={false}
              isSearchable={false}
              isRequired={true}
              value={
                selectedAdditionals
                  ? !!optionsAdditionals.find(option => option.value === valueAdditional)
                    ? optionsAdditionals.find(option => option.value === valueAdditional)
                    : null
                  : null
              }
              label={
                width > 1050
                  ? {
                      text: 'Дополнительное условие',
                      topBg: 'white',
                      bottomBg: 'white',
                    }
                  : null
              }
            />
          )}
        </div>
      </Collapse>

      <Collapse in={valueMod === 'another'} timeout={500} unmountOnExit>
        <div className='width50Percent'>
          <Input
            type={'text'}
            placeholder={'Введите модификацию прибора'}
            value={valueAnotherMod}
            actions={{
              change: handleValueModChange,
            }}
            label={width > 1050 ? 'Другая модификация прибора' : null}
          />
        </div>
      </Collapse>

      <div className='flexContainerWithGap' style={{ margin: 0, gap: width > 1050 ? '' : '15px' }}>
        <div className='width50Percent'>
          {width <= 1050 && (
            <span
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                position: 'relative',
                marginBottom: '9px',
                gap: '6px',
              }}
            >
              <span style={{ display: 'block' }}>Заводской номер прибора</span>
              {meterFactoryNumber ? (
                <span
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    marginBottom: '-2px',
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: '#63E6BE',
                    }}
                  />
                </span>
              ) : (
                <span
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    marginBottom: '-1px',
                  }}
                >
                  <VisibilityToggleIcon
                    sx={{
                      color: '#FFD43B',
                    }}
                  />
                </span>
              )}
            </span>
          )}
          <Input
            placeholder={'Введите заводской номер'}
            value={meterFactoryNumber}
            type={'text'}
            actions={{
              change: handleMeterFactoryNumberChange,
            }}
            label={width > 1050 ? 'Заводской номер прибора' : null}
          />
        </div>
        <div className='width50Percent'>
          {width <= 1050 && (
            <React.Fragment>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  position: 'relative',
                  marginBottom: '9px',
                  gap: '6px',
                }}
              >
                <span style={{ display: 'block' }}>Год выпуска прибора</span>
                {optionsYear.find(option => option.value === meterYear) ? (
                  <span
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                      marginBottom: '-2px',
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                      marginBottom: '-1px',
                    }}
                  >
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                      }}
                    />
                  </span>
                )}
              </span>
              <SelectorLight
                placeholder={'Выберите год'}
                options={optionsYear}
                onChange={getValueYear}
                value={optionsYear.find(option => option.value === meterYear)}
                isClearable={true}
                isSearchable={true}
              />
            </React.Fragment>
          )}
          {width > 1050 && (
            <Selector
              placeholder={'Выберите год'}
              options={optionsYear}
              onChange={getValueYear}
              value={optionsYear.find(option => option.value === meterYear)}
              isRequired={true}
              isClearable={true}
              isSearchable={width > 1050 ? true : true}
              label={
                width > 1050
                  ? {
                      text: 'Год выпуска прибора',
                      topBg: 'white',
                      bottomBg: 'white',
                    }
                  : null
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(MeterData)
