// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-redeclare */
// ----------------------------------------------------------------
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { settingsFileViewer } from '../../../../store/slices/app/comps/modal.js'
import { ContainerDescDev, FileBox, FileInfo, FileRow } from './styles.ts'
import { IMeterData } from '../../../types.models.views.ts'
import Pdf from '../../../../img/icon/PdfIcon.tsx'

interface DescriptionDevice {
  data: IMeterData
}

const DescriptionDevice: FC<DescriptionDevice> = ({ data }) => {
  const dispatch = useDispatch()

  const openViewer = () => {
    dispatch(settingsFileViewer({ value: 'open', label: true }))
  }

  const hasFile = data?.pdf && data?.pdf.length > 0
  const fileName = hasFile ? data?.pdf[0]?.name : 'Файл отсутствует'
  const fileSize =
    hasFile && data?.pdf[0]?.size ? `${Math.round(data?.pdf[0].size / 1024)} KB` : '0 KB'

  return (
    <ContainerDescDev>
      <Typography variant='h2'>Описание типа прибора</Typography>

      <FileBox onClick={hasFile ? openViewer : undefined}>
        <Pdf width={32} height={32} color='#899298' />
        <FileInfo>
          <Typography variant='subtitle2'>{fileName}</Typography>
          <FileRow>
            <Typography variant='subtitle2'>Размер файла:</Typography>
            <Typography variant='subtitle2'>{fileSize}</Typography>
          </FileRow>
        </FileInfo>
      </FileBox>
    </ContainerDescDev>
  )
}

export default DescriptionDevice
