// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import R from '../../../../../services/app/client-server/request.service.ts'

import { getAcceptanceChecks } from '../Gost8324-2002/helpers/helpers.js'
import formatPhoneNumber from '../../../../../utils/formatPhoneNumber.js'

import MeterData from '../../views/MeterData/MeterGas/MeterGas.jsx'
import Customer from '../../views/Customer/Customer'
import Accepted from '../../views/Accepted/Accepted'
import Conditions from './ProtocolComponents/Condition'
import Indications from './ProtocolComponents/Indications.jsx'
import DescriptionType from './ProtocolComponents/DescriptionType'
import TotalAccepted from '../../views/TotalAccepted/TotalAccepted'
import VerificationMark from '../../views/VerificationMark/VerificationMark.jsx'
import ProtocolDate from '../../views/ProtocolDate/ProtocolDate'
import ImageUpload from '../../views/ImagesUploading/ImagesUploading.jsx'
import DataTransfer from '../../views/DataTransfer/DataTransfer.jsx'

import { changeProperty, setDefaultState } from './state.js'
import { setShow } from '../../../../../store/slices/app/comps/feedback.js'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'
import {
  refreshReceiveds,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshTickets,
  refreshProtocols,
} from '../../../../../store/slices/app/controlers/updater.js'
import { setMeasurementId } from '../../../../../store/slices/app/views/protocols'

import css from '../../styles.css'
const { PageContainer, BoxContainer } = css

const Edit = () => {
  const { protocol_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const capitalizeFirstLetter = input => {
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const [detailsId, setDetailsId] = useState('')
  const [conditionId, setConditionId] = useState('')
  const [indicationId_1, setIndicationId_1] = useState('')
  const [indicationId_2, setIndicationId_2] = useState('')
  const [indicationId_3, setIndicationId_3] = useState('')

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const customerName = useSelector(state => state.gost83242002Reducer.customerName)
  const customerNameType = useSelector(state => state.gost83242002Reducer.customerNameType)
  const customerAddress = useSelector(state => state.gost83242002Reducer.customerAddress)
  const customerFullName = useSelector(state => state.gost83242002Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.gost83242002Reducer.customerPhoneNumber)
  const agreementNumber = useSelector(state => state.gost83242002Reducer.agreementNumber)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const meterFifNum = useSelector(state => state.gost83242002Reducer.meterFifNum)
  const modifications = useSelector(state => state.gost83242002Reducer.modifications)
  const selectedMod = useSelector(state => state.gost83242002Reducer.selectedMod)
  const selectedAdditionals = useSelector(state => state.gost83242002Reducer.selectedAdditionals)
  const modification = useSelector(state => state.gost83242002Reducer.modification)
  const valueAdditional = useSelector(state => state.gost83242002Reducer.valueAdditional)
  const valueAnotherMod = useSelector(state => state.gost83242002Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    state => state.gost83242002Reducer.is_custom_modification,
  )
  const mpi = useSelector(state => state.gost83242002Reducer.mpi)
  const meterFactoryNumber = useSelector(state => state.gost83242002Reducer.meterFactoryNumber)
  const meterYear = useSelector(state => state.gost83242002Reducer.meterYear)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const standartSize = useSelector(state => state.gost83242002Reducer.standartSize)
  const q_min = useSelector(state => state.gost83242002Reducer.q_min)
  const q_t = useSelector(state => state.gost83242002Reducer.q_t)
  const q_max = useSelector(state => state.gost83242002Reducer.q_max)
  const q_nom = useSelector(state => state.gost83242002Reducer.q_nom)
  const q_t_formula = useSelector(state => state.gost83242002Reducer.q_t_formula)
  const q_max_limit_negative = useSelector(state => state.gost83242002Reducer.q_max_limit_negative)
  const q_max_limit_positive = useSelector(state => state.gost83242002Reducer.q_max_limit_positive)
  const q_min_limit_negative = useSelector(state => state.gost83242002Reducer.q_min_limit_negative)
  const q_min_limit_positive = useSelector(state => state.gost83242002Reducer.q_min_limit_positive)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMechanicalAccepted = useSelector(state => state.gost83242002Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.gost83242002Reducer.isTightnessAccepted)
  const indicationPass = useSelector(state => state.gost83242002Reducer.indicationPass)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isMarkPassport = useSelector(state => state.gost83242002Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.gost83242002Reducer.isMarkSi)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const nextVerificationDate = useSelector(state => state.gost83242002Reducer.nextVerificationDate)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const isTotalAccepted = useSelector(state => state.gost83242002Reducer.isTotalAccepted)

  const correction = useSelector(state => state.gost83242002Reducer.correction)
  const pressureLoss = useSelector(state => state.gost83242002Reducer.pressureLoss)

  const conditions = useSelector(state => state.gost83242002Reducer.conditions)
  const indications = useSelector(state => state.gost83242002Reducer.indications)

  const doChecked = useSelector(state => state.gost83242002Reducer.doChecked)

  const acceptanceChecks = getAcceptanceChecks(isMechanicalAccepted, isTightnessAccepted, dispatch)

  const changeName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerName', label: newValue }))
  }

  const changeNameType = event => {
    dispatch(changeProperty({ value: 'customerNameType', label: event.target.value }))
    dispatch(changeProperty({ value: 'customerName', label: event.target.value }))
  }

  const changeAddress = selectedOption => {
    dispatch(
      changeProperty({
        value: 'customerAddress',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const changeFullName = param => {
    const newValue = capitalizeFirstLetter(param)
    dispatch(changeProperty({ value: 'customerFullName', label: newValue }))
  }

  const changePhone = param => {
    const formattedPhone = formatPhoneNumber(param)
    dispatch(changeProperty({ value: 'customerPhoneNumber', label: formattedPhone }))
  }

  const handleAgreementChange = param => {
    dispatch(changeProperty({ value: 'agreementNumber', label: param }))
  }

  const handleMarkPassport = event => {
    dispatch(
      changeProperty({
        value: 'isMarkPassport',
        label: event.target.checked,
      }),
    )
  }

  const handleMarkSi = event => {
    dispatch(
      changeProperty({
        value: 'isMarkSi',
        label: event.target.checked,
      }),
    )
  }

  const handleNextDateChange = date => {
    if (date) {
      dispatch(
        changeProperty({
          value: 'nextVerificationDate',
          label: date.format('YYYY-MM-DD HH:mm:ss'),
        }),
      )
    }
  }

  const nextDateAutoGenerate = useMemo(() => {
    if (dateVerification && mpi) {
      return dayjs(dateVerification)
        .add(mpi, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    return null
  }, [dateVerification, mpi])

  const handleDoChecked = event => {
    dispatch(
      changeProperty({
        value: 'doChecked',
        label: event.target.checked,
      }),
    )
  }

  useEffect(() => {
    const totalAccepted = isMechanicalAccepted && isTightnessAccepted && indicationPass

    dispatch(changeProperty({ value: 'isTotalAccepted', label: totalAccepted }))
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass, dispatch])

  useEffect(() => {
    if (conditions.length === 0 || conditions[0].id !== conditionId) {
      const updatedCondition = {
        ...(conditions.length > 0 ? conditions[0] : {}),
        id: conditionId,
      }

      dispatch(
        changeProperty({
          value: 'conditions',
          label: [updatedCondition],
        }),
      )
    }
  }, [conditionId, conditions, dispatch])

  useEffect(() => {
    if (
      indications.length === 3 &&
      (indications[0].id !== indicationId_1 ||
        indications[1].id !== indicationId_2 ||
        indications[2].id !== indicationId_3)
    ) {
      const updatedIndications = indications.map((indication, index) => {
        return {
          ...indication,
          id: index === 0 ? indicationId_1 : index === 1 ? indicationId_2 : indicationId_3,
        }
      })

      dispatch(
        changeProperty({
          value: 'indications',
          label: updatedIndications,
        }),
      )
    }
  }, [indicationId_1, indicationId_2, indicationId_3, indications, dispatch])

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))

      const { data } = await R.getProtocol(protocol_id, () => dispatch(setShow(false)))

      setDetailsId(data.data?.details?.id)

      dispatch(setMeasurementId(data.data?.measurement?.id))

      dispatch(
        changeProperty({
          value: 'customerName',
          label: data.data.customer_arshin_name,
        }),
      )

      dispatch(
        changeProperty({
          value: 'customerAddress',
          label: data.data.customer_address,
        }),
      )

      dispatch(
        changeProperty({
          value: 'customerFullName',
          label: data.data.customer_fullname || null,
        }),
      )

      dispatch(
        changeProperty({
          value: 'customerPhoneNumber',
          label: data.data.customer_phone || null,
        }),
      )

      dispatch(
        changeProperty({
          value: 'agreementNumber',
          label: data.data.agreement_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'isMechanicalAccepted',
          label: data.data?.mechanical_accepted,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isTightnessAccepted',
          label: data.data?.tightness_accepted,
        }),
      )

      dispatch(
        changeProperty({
          value: 'isMarkPassport',
          label: data.data?.mark_passport,
        }),
      )
      dispatch(
        changeProperty({
          value: 'isMarkSi',
          label: data.data?.mark_si,
        }),
      )

      dispatch(
        changeProperty({
          value: 'agreementNumber',
          label: data.data.agreement_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterYear',
          label: String(data.data.details.meter_factory_year),
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterFactoryNumber',
          label: data.data.details.meter_factory_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterFifNum',
          label: data.data?.meterable_fif_number,
        }),
      )

      dispatch(
        changeProperty({
          value: 'meterViewStatus',
          label: data.data?.details?.meter_view_status,
        }),
      )

      dispatch(
        changeProperty({
          value: 'modification',
          label: data.data?.details?.modification,
        }),
      )

      dispatch(
        changeProperty({
          value: 'valueAdditional',
          label: data.data?.details?.additional_option,
        }),
      )
      dispatch(
        changeProperty({
          value: 'valueAnotherMod',
          label: data.data?.details?.modification,
        }),
      )
      dispatch(
        changeProperty({
          value: 'is_custom_modification',
          label: data.data?.details?.is_custom_modification,
        }),
      )

      dispatch(
        changeProperty({
          value: 'temperature',
          label: data.data?.protocolCondition?.[0]?.temperature_environment,
        }),
      )
      dispatch(
        changeProperty({
          value: 'humidity',
          label: data.data?.protocolCondition?.[0]?.relative_humidity,
        }),
      )
      dispatch(
        changeProperty({
          value: 'pressure',
          label: data.data?.protocolCondition?.[0]?.atmospheric_pressure,
        }),
      )
      dispatch(
        changeProperty({
          value: 'voltageDeviation',
          label: data.data?.protocolCondition?.[0]?.voltage_deviation,
        }),
      )
      dispatch(
        changeProperty({
          value: 'frequencyDeviation',
          label: data.data?.protocolCondition?.[0]?.frequency_deviation,
        }),
      )
      dispatch(
        changeProperty({
          value: 'airTempDifference',
          label: data.data?.protocolCondition?.[0]?.air_temp_difference,
        }),
      )
      dispatch(
        changeProperty({
          value: 'airTempSpeed',
          label: data.data?.protocolCondition?.[0]?.air_temp_speed,
        }),
      )

      dispatch(
        changeProperty({
          value: 'correction',
          label: parseFloat(data.data?.details?.correction),
        }),
      )

      dispatch(
        changeProperty({
          value: 'q_min',
          label: parseFloat(data.data?.details?.q_min),
        }),
      )

      dispatch(
        changeProperty({
          value: 'q_t',
          label: parseFloat(data.data?.details?.q_t),
        }),
      )

      dispatch(
        changeProperty({
          value: 'q_max',
          label: parseFloat(data.data?.details?.q_max),
        }),
      )

      dispatch(
        changeProperty({
          value: 'q_nom',
          label: parseFloat(data.data?.details?.q_nom),
        }),
      )

      dispatch(
        changeProperty({
          value: 'standartSize',
          label: data.data?.details?.standart_size,
        }),
      )

      dispatch(
        changeProperty({
          value: 'pressureLoss',
          label: parseFloat(data.data?.details?.q_max_pressure_loss),
        }),
      )

      setIndicationId_1(data.data?.protocolIndication?.[0]?.id)
      setIndicationId_2(data.data?.protocolIndication?.[1]?.id)
      setIndicationId_3(data.data?.protocolIndication?.[2]?.id)

      dispatch(
        changeProperty({
          value: 'start_1',
          label: data.data?.protocolIndication?.[0]?.start_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_1',
          label: data.data?.protocolIndication?.[0]?.measurement_consumption_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_1',
          label: data.data?.protocolIndication?.[0]?.finish_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_1',
          label: data.data?.protocolIndication?.[0]?.volume_etalon_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_2',
          label: data.data?.protocolIndication?.[0]?.start_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_2',
          label: data.data?.protocolIndication?.[0]?.measurement_consumption_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_2',
          label: data.data?.protocolIndication?.[0]?.finish_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_2',
          label: data.data?.protocolIndication?.[0]?.volume_etalon_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_3',
          label: data.data?.protocolIndication?.[0]?.start_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_3',
          label: data.data?.protocolIndication?.[0]?.measurement_consumption_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_3',
          label: data.data?.protocolIndication?.[0]?.finish_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_3',
          label: data.data?.protocolIndication?.[0]?.volume_etalon_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_2',
          label: data.data?.protocolIndication?.[1]?.start_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_2',
          label: data.data?.protocolIndication?.[1]?.measurement_consumption_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_2',
          label: data.data?.protocolIndication?.[1]?.finish_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_2',
          label: data.data?.protocolIndication?.[1]?.volume_etalon_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_4',
          label: data.data?.protocolIndication?.[1]?.start_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_4',
          label: data.data?.protocolIndication?.[1]?.measurement_consumption_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_4',
          label: data.data?.protocolIndication?.[1]?.finish_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_4',
          label: data.data?.protocolIndication?.[1]?.volume_etalon_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_5',
          label: data.data?.protocolIndication?.[1]?.start_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_5',
          label: data.data?.protocolIndication?.[1]?.measurement_consumption_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_5',
          label: data.data?.protocolIndication?.[1]?.finish_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_5',
          label: data.data?.protocolIndication?.[1]?.volume_etalon_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_3',
          label: data.data?.protocolIndication?.[2]?.start_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_3',
          label: data.data?.protocolIndication?.[2]?.measurement_consumption_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_3',
          label: data.data?.protocolIndication?.[2]?.finish_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_3',
          label: data.data?.protocolIndication?.[2]?.volume_etalon_1,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_6',
          label: data.data?.protocolIndication?.[2]?.start_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_6',
          label: data.data?.protocolIndication?.[2]?.measurement_consumption_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_6',
          label: data.data?.protocolIndication?.[2]?.finish_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_6',
          label: data.data?.protocolIndication?.[2]?.volume_etalon_2,
        }),
      )

      dispatch(
        changeProperty({
          value: 'start_extra_7',
          label: data.data?.protocolIndication?.[2]?.start_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'measurementConsumption_extra_7',
          label: data.data?.protocolIndication?.[2]?.measurement_consumption_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'finish_extra_7',
          label: data.data?.protocolIndication?.[2]?.finish_3,
        }),
      )

      dispatch(
        changeProperty({
          value: 'volumeEtalon_extra_7',
          label: data.data?.protocolIndication?.[2]?.volume_etalon_3,
        }),
      )

      setConditionId(data.data?.protocolCondition?.[0]?.id)

      dispatch(setShow(false))
    })()
  }, [protocol_id, dispatch])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      verification_date: dateVerification,
      next_verification_date: nextDateAutoGenerate || nextVerificationDate,
      mark_passport: isMarkPassport,
      mark_si: isMarkSi,
      meterable_fif_number: meterFifNum,
      customer_address: customerAddress,
      customer_fullname: customerFullName,
      customer_arshin_name: customerName,
      customer_phone: customerPhoneNumber,
      agreement_number: agreementNumber,
      total_accepted: isTotalAccepted,
      point_id: pointId,

      mechanical_accepted: isMechanicalAccepted,
      tightness_accepted: isTightnessAccepted,

      indications: indications,
      conditions: conditions,

      details: {
        id: detailsId,
        meter_factory_number: meterFactoryNumber,
        meter_factory_year: meterYear,
        modification: modification === 'another' ? valueAnotherMod : modification,
        is_custom_modification: is_custom_modification,
        standart_size: standartSize,
        q_t: q_t,
        q_min: q_min,
        q_max: q_max,
        q_nom: q_nom ?? null,
        q_t_formula: q_t_formula,
        mpi: mpi,
        q_min_limit_positive: q_min_limit_positive,
        q_min_limit_negative: q_min_limit_negative,
        q_max_limit_positive: q_max_limit_positive,
        q_max_limit_negative: q_max_limit_negative,
        q_max_pressure_loss: pressureLoss,
        correction: correction,
      },
    }

    const { status } = await R.editProtocol(protocol_id, data)

    if (status === 200) {
      dispatch(setMessage('Протокол успешно отредактирован'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(refreshConditions())
      dispatch(refreshIssueCase())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      navigate('/metriva/protocols')
    } else if (status === 422) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Протокол не отредактирован - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Произошла ошибка обращения к API'))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setDefaultState())
    }
  }, [])

  return (
    <PageContainer>
      <BoxContainer margin={'14px 0px 14px'}>
        <Customer
          valueName={customerName}
          changeName={changeName}
          valueNameType={customerNameType}
          changeNameType={changeNameType}
          valueAddress={customerAddress}
          changeAddress={changeAddress}
          valueFullName={customerFullName}
          changeFullName={changeFullName}
          valuePhone={customerPhoneNumber}
          changePhone={changePhone}
          changeAgreement={handleAgreementChange}
        />
      </BoxContainer>

      <BoxContainer>
        <MeterData
          meterFactoryNumber={meterFactoryNumber}
          meterYear={meterYear}
          meterFifNum={meterFifNum}
          modifications={modifications}
          selectedMod={selectedMod}
          selectedAdditionals={selectedAdditionals}
          modification={modification}
          valueAdditional={valueAdditional}
          valueAnotherMod={valueAnotherMod}
          is_custom_modification={is_custom_modification}
          changeMeter={changeProperty}
        />
      </BoxContainer>

      <BoxContainer>
        <DescriptionType />
      </BoxContainer>

      {acceptanceChecks.map((check, index) => {
        return (
          <BoxContainer key={index}>
            <Accepted
              title={check.title}
              description={check.description}
              label={check.label}
              value={check.value}
              onChange={check.onChange}
              notMatch={true}
            />
          </BoxContainer>
        )
      })}

      <BoxContainer>
        <Conditions />
      </BoxContainer>

      <BoxContainer>
        <Indications />
      </BoxContainer>

      <BoxContainer
        style={{
          backgroundColor: 'transparent',
          padding: '0px',
          border: 'none',
        }}
      >
        <TotalAccepted value={isTotalAccepted} />
      </BoxContainer>

      <BoxContainer>
        <VerificationMark
          isTotalAccepted={isTotalAccepted}
          valuePassport={isMarkPassport}
          onChangePassport={handleMarkPassport}
          valueSi={isMarkSi}
          onChangeSi={handleMarkSi}
        />
      </BoxContainer>

      <BoxContainer>
        <ProtocolDate
          currentDate={dateVerification}
          nextDate={nextVerificationDate}
          nextDateAutoGenerate={nextDateAutoGenerate}
          changeNextDate={handleNextDateChange}
        />
      </BoxContainer>

      {!isTrusted ? (
        <BoxContainer margin={'0px 0px 14px'}>
          <ImageUpload />
        </BoxContainer>
      ) : (
        <BoxContainer>
          <DataTransfer doChecked={doChecked} handleDoChecked={handleDoChecked} />
        </BoxContainer>
      )}

      <Button
        variant='contained'
        onClick={handleSave}
        disabled={isDisabledBtn}
        sx={{ mt: 1, mr: 1 }}
        style={{
          textTransform: 'none',
          fontSize: '15px',
          fontFamily: '"Wix Madefor Display", sans-serif',
          padding: '11px 20px 11px',
          margin: 0,
          borderRadius: '12px',
          width: '100%',
        }}
      >
        Редактировать протокол
      </Button>
    </PageContainer>
  )
}

export default Edit
