// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ChangeEvent, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'

import Input from '../../../ui/input/index.tsx'
import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'
import DatePicker from '../../components/LocalElements/DatePicker/DatePicker'
import TabsContainer from '../../components/Etalon/TabsContainer.tsx'
import StatusContainer from '../../components/Etalon/StatusContainer.tsx'
import BottomSideContainer from '../../components/LocalElements/BottomSideContainer/BottomSideContainer.jsx'

import BackButton from '../../../ui/back-button/index.tsx'

import R from '../../../services/app/client-server/request.service.ts'
import dayjs from 'dayjs'

import { resetCreateData, changeEtalon } from '../../../store/slices/app/views/etalon'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  refreshEtalons,
  refreshKitPassport,
  refreshKitVerifications,
} from '../../../store/slices/app/controlers/updater'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from '../../../store/slices/app/comps/modal.js'

import {
  IModelEtalonCreate,
  IEtalonDevicesList,
  IEtalonClassesList,
} from '../../types.models.create.ts'

const AddEtalon = () => {
  const userRole = useSelector((state: any) => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector((state: any) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state: any) => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const title = useSelector((state: any) => state.bottomContainerReducer.title)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [validationsErrors, setValidationErrors] = useState<any>({})
  const [kitGroupId, setKitGroupId] = useState<{ value: number; label: string } | null>(null)

  const [statusResponse, setStatusResponse] = useState<{ tab: number; title: string } | null>()
  const [selectedDevice, setSelectedDevice] = useState<{ value: number; label: string } | null>(
    null,
  )
  const [selectedOneClass, setSelectedOneClass] = useState<{ value: number; label: string } | null>(
    null,
  )

  const [devicesList, setDevicesList] = useState<Array<{ value: number; label: string }> | null>(
    null,
  )
  const [classesList, setClassesList] = useState<Array<{ value: number; label: string }> | null>(
    null,
  )

  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData,
  )

  const validate = useMemo(() => {
    if (!!!etalonCreateData.kit_group_id) {
      return false
    } else if (etalonCreateData.kit_group_id === 1) {
      return (
        etalonCreateData.device_id.toString().length > 0 &&
        etalonCreateData.name.toString().length > 0 &&
        etalonCreateData.type.toString().length > 0 &&
        ((etalonCreateData.is_registered === true &&
          etalonCreateData.register_number.toString().length > 0 &&
          etalonCreateData.fif_register_number.toString().length > 0) ||
          etalonCreateData.is_registered === false) &&
        etalonCreateData.manufacturer_name.toString().length > 0 &&
        etalonCreateData.release_year.toString().length > 0 &&
        etalonCreateData.manufacturer_number.toString().length > 0 &&
        (etalonCreateData.meas_range.toString().length > 0 ||
          etalonCreateData.repr_range.toString().length > 0 ||
          (etalonCreateData.meas_range.toString().length > 0 &&
            etalonCreateData.repr_range.toString().length > 0)) &&
        etalonCreateData.kit_accuracy_class_id.toString().length > 0 &&
        etalonCreateData.error.toString().length > 0 &&
        etalonCreateData.receipt_date.toString().length > 0 &&
        etalonCreateData.commission_date.toString().length > 0 &&
        etalonCreateData.inventory_number.toString().length > 0 &&
        etalonCreateData.location.toString().length > 0 &&
        etalonCreateData.responsible_passport_name.toString().length > 0 &&
        // etalonCreateData.interval_attestations &&
        // etalonCreateData.interval_maintenances &&
        etalonCreateData.interval_verifications.toString().length > 0
      )
    } else if (etalonCreateData.kit_group_id === 2) {
      return (
        etalonCreateData.device_id.toString().length > 0 &&
        etalonCreateData.name.toString().length > 0 &&
        etalonCreateData.type.toString().length > 0 &&
        ((etalonCreateData.is_registered === true &&
          etalonCreateData.register_number.toString().length > 0) ||
          etalonCreateData.is_registered === false) &&
        etalonCreateData.manufacturer_name.toString().length > 0 &&
        etalonCreateData.release_year.toString().length > 0 &&
        etalonCreateData.manufacturer_number.toString().length > 0 &&
        (etalonCreateData.meas_range.toString().length > 0 ||
          etalonCreateData.repr_range.toString().length > 0 ||
          (etalonCreateData.meas_range.toString().length > 0 &&
            etalonCreateData.repr_range.toString().length > 0)) &&
        etalonCreateData.kit_accuracy_class_id.toString().length > 0 &&
        etalonCreateData.receipt_date.toString().length > 0 &&
        etalonCreateData.commission_date.toString().length > 0 &&
        etalonCreateData.inventory_number.toString().length > 0 &&
        etalonCreateData.location.toString().length > 0 &&
        etalonCreateData.responsible_passport_name.toString().length > 0 &&
        // etalonCreateData.interval_attestations &&
        // etalonCreateData.interval_maintenances &&
        etalonCreateData.interval_verifications.toString().length > 0
      )
    } else if (etalonCreateData.kit_group_id === 3) {
      return true
    }
  }, [
    etalonCreateData.device_id,
    etalonCreateData.kit_group_id,
    etalonCreateData.is_send_to_arshin,
    etalonCreateData.status,
    etalonCreateData.name,
    etalonCreateData.type,
    etalonCreateData.register_number,
    etalonCreateData.fif_register_number,
    etalonCreateData.is_registered,
    etalonCreateData.manufacturer_name,
    etalonCreateData.release_year,
    etalonCreateData.manufacturer_number,
    etalonCreateData.meas_range,
    etalonCreateData.repr_range,
    etalonCreateData.error,
    etalonCreateData.kit_accuracy_class_id,
    etalonCreateData.deviations,
    etalonCreateData.software_info,
    etalonCreateData.component_info,
    etalonCreateData.receipt_date,
    etalonCreateData.commission_date,
    etalonCreateData.inventory_number,
    etalonCreateData.location,
    etalonCreateData.interval_verifications,
    etalonCreateData.interval_attestations,
    etalonCreateData.interval_maintenances,
    etalonCreateData.kitVerifications,
    etalonCreateData.kitAttestations,
    etalonCreateData.kitCalibrations,
    etalonCreateData.kitRepairs,
    etalonCreateData.kitMaintenances,
  ])

  // const handleDateChange = (date: { format: (arg0: string) => any }) => {
  //   if (date) {
  //     dispatch(
  //       changeEtalon({
  //         value: 'dateFrom',
  //         label: date.format('YYYY-MM-DD HH:mm:ss'),
  //       }),
  //     )
  //   }
  // }

  const handleKitGroupId = (option: any) => {
    setKitGroupId(option)
    dispatch(
      changeEtalon({
        value: 'kit_group_id',
        label: option.value,
      }),
    )

    dispatch(
      changeEtalon({
        value: 'device_id',
        label: '',
      }),
    )

    setSelectedDevice(null)
  }

  const handleDevice = (option: any) => {
    setSelectedDevice(option)
    dispatch(
      changeEtalon({
        value: 'device_id',
        label: option.value,
      }),
    )
  }

  const handleYear = (option: any) => {
    dispatch(
      changeEtalon({
        value: 'release_year',
        label: option.value,
      }),
    )
  }

  const handleClass = (option: any) => {
    setSelectedOneClass(option)
    dispatch(
      changeEtalon({
        value: 'kit_accuracy_class_id',
        label: option.value,
      }),
    )
  }

  const handleSave = async () => {
    const data: IModelEtalonCreate = {
      device_id: etalonCreateData.device_id,
      kit_group_id: etalonCreateData.kit_group_id,
      is_send_to_arshin: etalonCreateData.is_send_to_arshin,
      company_id: companyId,
      status: etalonCreateData.status,
      name: etalonCreateData.name,
      type: etalonCreateData.type,
      register_number: etalonCreateData.register_number,
      fif_register_number: etalonCreateData.fif_register_number,
      is_registered: etalonCreateData.is_registered,
      manufacturer_name: etalonCreateData.manufacturer_name,
      release_year: +etalonCreateData.release_year,
      manufacturer_number: etalonCreateData.manufacturer_number,
      meas_range: etalonCreateData.meas_range,
      repr_range: etalonCreateData.repr_range,
      error: etalonCreateData.error,
      kit_accuracy_class_id: etalonCreateData.kit_accuracy_class_id,
      deviations: etalonCreateData.deviations,
      software_info: etalonCreateData.software_info,
      component_info: etalonCreateData.component_info,
      receipt_date: etalonCreateData.receipt_date,
      commission_date: etalonCreateData.commission_date,
      inventory_number: etalonCreateData.inventory_number,
      responsible_passport_name: etalonCreateData.responsible_passport_name,
      location: etalonCreateData.location,
      interval_verifications: etalonCreateData.interval_verifications,
      interval_attestations: etalonCreateData.interval_attestations,
      interval_maintenances: etalonCreateData.interval_maintenances,
      kitVerifications: etalonCreateData.kitVerifications.map((item: any) => ({
        ...item,
        is_suitable: item.is_suitable === 'true' || item.is_suitable === true,
        date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : null,
      })),
      kitAttestations: etalonCreateData.kitAttestations.map((item: any) => ({
        ...item,
        is_suitable: item.is_suitable === 'true' || item.is_suitable === true,
        date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : null,
      })),
      kitCalibrations: etalonCreateData.kitCalibrations.map((item: any) => ({
        ...item,
        date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : null,
      })),
      kitRepairs: etalonCreateData.kitRepairs.map((item: any) => ({
        ...item,
        date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : null,
      })),
      kitMaintenances: etalonCreateData.kitMaintenances.map((item: any) => ({
        ...item,
        date: item.date ? dayjs(item.date).format('YYYY-MM-DD') : null,
      })),
    }

    false && console.log(data)

    const { status, data: etalonAddData } = await R.addEtalon(data)

    if (status === 200) {
      dispatch(setMessage('Эталон успешно создан'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())

      dispatch(refreshEtalons(''))
      dispatch(refreshKitPassport(''))
      dispatch(refreshKitVerifications(''))

      navigate('/metriva/etalon')
    } else if (status === 400) {
      if (etalonAddData.errors) {
        const errors = etalonAddData.errors
        setValidationErrors(errors)

        let errorString = ''

        for (let i in errors) {
          if (errors[i] !== null) {
            errorString = errors[i]
          }
        }

        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage(errorString))

        setStatusResponse({ tab: 0, title: errorString })
      }
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Эталон не изменен - ошибка валидации или обращения к серверу'))
    }
  }

  useEffect(() => {
    ;(async () => {
      if (kitGroupId) {
        const { data }: { data: { data: Array<IEtalonDevicesList> } } =
          await R.getEtalonDevicesList(kitGroupId.value)

        setDevicesList(data.data.map(item => ({ value: item.id, label: item.name })))
      } else {
        setDevicesList([])
        setSelectedDevice(null)

        dispatch(
          changeEtalon({
            value: 'kit_group_id',
            label: '',
          }),
        )
        dispatch(
          changeEtalon({
            value: 'device_id',
            label: '',
          }),
        )
      }
    })()
  }, [kitGroupId])

  useEffect(() => {
    ;(async () => {
      if (!!!selectedDevice) {
        dispatch(
          changeEtalon({
            value: 'device_id',
            label: '',
          }),
        )
      }
    })()
  }, [selectedDevice])

  useEffect(() => {
    ;(async () => {
      if (!!!selectedOneClass) {
        dispatch(
          changeEtalon({
            value: 'kit_accuracy_class_id',
            label: '',
          }),
        )
      }
    })()
  }, [selectedOneClass])

  useEffect(() => {
    ;(async () => {
      const { data }: { data: { data: Array<IEtalonClassesList> } } = await R.getEtalonClassesList()

      setClassesList(data.data.map(item => ({ value: item.id, label: item.name })))
    })()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  // ----------------------------------------------------------------
  // когда станет понятно, что эта версия формы - финальная
  // финальная прямо точно, подробить на компоненты
  // ----------------------------------------------------------------

  return (
    <main className={'main'}>
      <BottomSideContainer blockTitle={title}>
        {<span>Информация отсутствует</span>}
      </BottomSideContainer>

      <BackButton to='/metriva/etalon' label='Назад к списку средств поверки' />

      {/* ---------------------------------------------------------------- */}
      {/* компонент основные данные */}
      {/* ---------------------------------------------------------------- */}

      {/* <div className='box' style={{ backgroundColor: 'transparent' }}>
        <h2 style={{ marginBottom: '20px', color: 'gray' }}>На хранении</h2>
        <span style={{ color: 'gray' }}>
          Средство хранения ожидает ввода в эксплуатацию или переведено на длительное хранение ( в
          консервацию )
        </span>
      </div> */}

      <StatusContainer status={etalonCreateData.status} />

      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          gap: '14px',
          marginTop: '20px',
        }}
      >
        <div className='width50Percent'>
          <Selector
            placeholder={'Выберите группу СП'}
            isClearable={true}
            isCreatable={false}
            isSearchable={true}
            // ----------------------------------------------------------------
            // временно - хардкодом, до завершения окончательного тестирования
            // ----------------------------------------------------------------
            options={[
              {
                value: 1,
                label: 'СИ, применяемые в качестве эталонов',
              },
              {
                value: 2,
                label: 'СИ, применяемые при поверке',
              },
              {
                value: 3,
                label: 'Вспомогательное оборудование',
              },
            ]}
            onChange={handleKitGroupId}
            value={kitGroupId || ''}
            isRequired={true}
            label={{
              text: 'Группа средств поверки',
              topBg: '#f8f9fc',
              bottomBg: 'white',
            }}
            noOptionsMessage={() => 'Нет доступных вариантов'}
            onInputChange={undefined}
          />
        </div>
        <div className='width50Percent'>
          <Selector
            placeholder={
              devicesList?.length === 0 || !!!devicesList
                ? 'Сначала выберите группу СП'
                : 'Выберите средство поверки'
            }
            isClearable={true}
            isCreatable={false}
            isSearchable={true}
            isDisabled={devicesList?.length === 0 || !!!devicesList ? true : false}
            options={devicesList}
            onChange={handleDevice}
            value={selectedDevice || ''}
            isRequired={true}
            label={{
              text: 'Средство поверки',
              topBg: '#f8f9fc',
              bottomBg: 'white',
            }}
            noOptionsMessage={() => 'Нет доступных вариантов'}
            onInputChange={undefined}
          />
        </div>
      </div>

      <div className='box'>
        <h2 style={{ marginBottom: '20px' }}>Основные данные</h2>
        <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
          <div className='width50Percent'>
            <Input
              label={'Наименование'}
              placeholder={'Введите полное наименование'}
              type={'text'}
              value={etalonCreateData.name}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'name',
                      label: param,
                    }),
                  ),
              }}
              error={!!validationsErrors.name}
            />
          </div>

          <div className='width50Percent'>
            <Input
              label={'Тип'}
              placeholder={'Укажите тип'}
              type={'text'}
              value={etalonCreateData.type}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'type',
                      label: param,
                    }),
                  ),
              }}
              notRequired={kitGroupId?.value === 3}
              error={!!validationsErrors.type}
            />
          </div>
        </div>

        {kitGroupId?.value !== 3 && (
          <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
            <div className='width32Percent'>
              <span>Является утвержденным типом СИ</span>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={etalonCreateData.is_registered}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    changeEtalon({
                      value: 'is_registered',
                      label: event.target.value === 'true',
                    }),
                  )
                }}
                sx={{
                  flexDirection: 'row',
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  color: '#123532',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginTop: '0px',
                  paddingLeft: '5px',
                }}
              >
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Да'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Нет'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              </RadioGroup>
            </div>

            <div
              className={etalonCreateData.kit_group_id === 1 ? 'width32Percent' : 'width67Percent'}
            >
              <Input
                label={'Регистрационный номер типа СИ'}
                placeholder={'Пример ввода - 55964-13'}
                type={'text'}
                value={etalonCreateData.register_number}
                actions={{
                  change: (param: string) =>
                    dispatch(
                      changeEtalon({
                        value: 'register_number',
                        label: param,
                      }),
                    ),
                }}
                notRequired={!etalonCreateData.is_registered}
                error={!!validationsErrors.register_number}
              />
            </div>

            {etalonCreateData.kit_group_id === 1 && (
              <div className='width32Percent'>
                <Input
                  label={'Номер в перечне ФИФ ОЕИ'}
                  placeholder={'Пример ввода - 55964.13.3Р.0070081'}
                  type={'text'}
                  value={etalonCreateData.fif_register_number}
                  actions={{
                    change: (param: string) =>
                      dispatch(
                        changeEtalon({
                          value: 'fif_register_number',
                          label: param,
                        }),
                      ),
                  }}
                  notRequired={!etalonCreateData.is_registered}
                  error={!!validationsErrors.fif_register_number}
                />
              </div>
            )}
          </div>
        )}

        <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
          <div className='width32Percent'>
            <Input
              label={'Изготовитель'}
              placeholder={'Введите название изготовителя'}
              type={'text'}
              value={etalonCreateData.manufacturer_name}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'manufacturer_name',
                      label: param,
                    }),
                  ),
              }}
              error={!!validationsErrors.manufacturer_name}
            />
          </div>
          <div className='width32Percent'>
            <Selector
              placeholder={'Выберите год выпуска'}
              isClearable={false}
              isCreatable={false}
              isSearchable={true}
              options={Array.from({ length: 2024 - 1980 + 1 }, (_, i) => {
                const year = 2024 - i
                return { value: year, label: year }
              })}
              onChange={handleYear}
              value={
                etalonCreateData.release_year
                  ? { value: etalonCreateData.release_year, label: etalonCreateData.release_year }
                  : ''
              }
              isRequired={true}
              label={{
                text: 'Год выпуска',
                topBg: 'white',
                bottomBg: 'white',
              }}
              noOptionsMessage={() => 'Нет доступных вариантов'}
              onInputChange={undefined}
            />
          </div>
          <div className='width32Percent'>
            <Input
              label={'Заводской № '}
              placeholder={'Пример ввода - 3829JH39'}
              type={'text'}
              value={etalonCreateData.manufacturer_number}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'manufacturer_number',
                      label: param,
                    }),
                  ),
              }}
              error={!!validationsErrors.manufacturer_number}
            />
          </div>
        </div>

        {kitGroupId?.value !== 3 && (
          <>
            <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
              <h2>Метрологические точностные характеристики</h2>
            </div>
            <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
              <div className='width50Percent'>
                <Input
                  label={'Диапазон измерений'}
                  placeholder={'Введите данные о диапазоне измерений'}
                  type={'text'}
                  value={etalonCreateData.meas_range}
                  actions={{
                    change: (param: string) =>
                      dispatch(
                        changeEtalon({
                          value: 'meas_range',
                          label: param,
                        }),
                      ),
                  }}
                  notRequired={!!etalonCreateData.repr_range}
                  error={!!validationsErrors.meas_range}
                />
              </div>

              <div className='width50Percent'>
                <Input
                  label={'Диапазон воспроизведения'}
                  placeholder={'Введите данные о диапазоне воспроизведения'}
                  type={'text'}
                  value={etalonCreateData.repr_range}
                  actions={{
                    change: (param: string) =>
                      dispatch(
                        changeEtalon({
                          value: 'repr_range',
                          label: param,
                        }),
                      ),
                  }}
                  notRequired={!!etalonCreateData.meas_range}
                  error={!!validationsErrors.repr_range}
                />
              </div>
            </div>
            <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
              <div className='width32Percent'>
                <Selector
                  placeholder={'Выберите класс точности'}
                  isClearable={true}
                  isCreatable={false}
                  isSearchable={true}
                  options={classesList}
                  onChange={handleClass}
                  value={selectedOneClass}
                  label={{
                    text: 'Класс точности (разряд)',
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                  isRequired={true}
                  noOptionsMessage={() => 'Нет доступных вариантов'}
                  onInputChange={undefined}
                />
              </div>
              <div className='width32Percent'>
                <Input
                  label={'Погрешность'}
                  placeholder={'Введите данные о погрешности'}
                  type={'text'}
                  value={etalonCreateData.error}
                  actions={{
                    change: (param: string) =>
                      dispatch(
                        changeEtalon({
                          value: 'error',
                          label: param,
                        }),
                      ),
                  }}
                  notRequired={etalonCreateData.kit_group_id !== 1}
                  error={!!validationsErrors.error}
                />
              </div>
              <div className='width32Percent'>
                <Input
                  label={'Отклонения'}
                  placeholder={'Укажите отклонения '}
                  type={'text'}
                  value={etalonCreateData.deviations}
                  notRequired={true}
                  actions={{
                    change: (param: string) =>
                      dispatch(
                        changeEtalon({
                          value: 'deviations',
                          label: param,
                        }),
                      ),
                  }}
                  error={!!validationsErrors.deviations}
                />
              </div>
            </div>
          </>
        )}

        <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
          <h2>Сведения о ПО</h2>
        </div>

        <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
          <div style={{ width: '100%' }}>
            <Input
              label={'Сведения о программном обеспечении, включая встроенное ( при наличии )'}
              placeholder={'Введите сведения о ПО'}
              type={'text'}
              value={etalonCreateData.software_info}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'software_info',
                      label: param,
                    }),
                  ),
              }}
              notRequired={true}
              error={!!validationsErrors.sofware_info}
            />
          </div>
        </div>

        <div className='flexContainerWithGap' style={{ marginBottom: '20px' }}>
          <h2>Комплектность</h2>
        </div>

        <div className='flexContainerWithGap' style={{ marginBottom: '0px' }}>
          <div style={{ width: '100%' }}>
            <Input
              label={'Сведения о комплектующем оборудовании ( при наличии )'}
              placeholder={'Введите сведения о комплектующих'}
              type={'text'}
              value={etalonCreateData.component_info}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'component_info',
                      label: param,
                    }),
                  ),
              }}
              notRequired={true}
              error={!!validationsErrors.component_info}
            />
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------- */}

      {/* ---------------------------------------------------------------- */}
      {/* компонент со сведениями по учету */}
      {/* ---------------------------------------------------------------- */}

      <div className='box'>
        <h2 style={{ marginBottom: '20px' }}>Сведения по учету</h2>
        <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
          <div className='width32Percent'>
            <DatePicker
              isRequired={true}
              selectedDate={etalonCreateData.receipt_date ? etalonCreateData.receipt_date : null}
              dateChange={(date: { format: (arg0: string) => any }) =>
                dispatch(
                  changeEtalon({
                    value: 'receipt_date',
                    label: date.format('YYYY-MM-DD'),
                  }),
                )
              }
              label='Дата поступления СП / Составления карточки'
            />
          </div>
          <div className='width32Percent'>
            <DatePicker
              isRequired={true}
              selectedDate={
                etalonCreateData.commission_date ? etalonCreateData.commission_date : null
              }
              dateChange={(date: { format: (arg0: string) => any }) =>
                dispatch(
                  changeEtalon({
                    value: 'commission_date',
                    label: date.format('YYYY-MM-DD'),
                  }),
                )
              }
              label='Дата ввода в эксплуатацию'
            />
          </div>
          <div className='width32Percent'>
            <Input
              label={'Инвентарный номер'}
              placeholder={'Введите полное наименование'}
              type={'text'}
              value={etalonCreateData.inventory_number}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'inventory_number',
                      label: param,
                    }),
                  ),
              }}
              error={!!validationsErrors.inventory_number}
            />
          </div>
        </div>
        <div className='flexContainerWithGap' style={{ marginBottom: '0px' }}>
          <div className='width50Percent'>
            <Input
              label={'Место нахождения'}
              placeholder={'Введите адрес местонахождения'}
              type={'text'}
              value={etalonCreateData.location}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'location',
                      label: param,
                    }),
                  ),
              }}
            />
          </div>
          <div className='width50Percent'>
            <Input
              label={'Ответственный за ведение рег. карточки (паспорта)'}
              placeholder={'Введите ФИО ответственного'}
              type={'text'}
              value={etalonCreateData.responsible_passport_name || ''}
              actions={{
                change: (param: string) =>
                  dispatch(
                    changeEtalon({
                      value: 'responsible_passport_name',
                      label: param,
                    }),
                  ),
              }}
              error={!!validationsErrors.responsible_passport_name}
            />
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------- */}

      {/* ---------------------------------------------------------------- */}
      {/* компонент-папка с вкладками */}
      {/* ---------------------------------------------------------------- */}

      <TabsContainer status={statusResponse} kitGroupId={kitGroupId?.value} />

      {/* ---------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------- */}

      {/* ---------------------------------------------------------------- */}
      {/* компонент смены статусов */}
      {/* ---------------------------------------------------------------- */}

      <div className='box'>
        <h2 style={{ marginBottom: '14px' }}>Смена текущего статуса</h2>
        <div className='flexContainerWithGap' style={{ marginBottom: '0px' }}>
          <div style={{ width: '100%' }}>
            <span>Выберите новый статус</span>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={etalonCreateData.status}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value !== 'repair') {
                  dispatch(
                    changeEtalon({
                      value: 'status',
                      label: event.target.value,
                    }),
                  )
                } else {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите изменить текущий статус на "Ремонт"?'))
                  dispatch(
                    setChildren(
                      <React.Fragment>
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <span style={{ lineHeight: '24px' }}>
                            После присвоения данного статуса создание новой записи о ремонте станет
                            обязательным, а также действующая поверка может быть признана
                            недействительной
                          </span>
                        </div>
                      </React.Fragment>,
                    ),
                  )
                  dispatch(
                    setConfirmAction(() => {
                      dispatch(
                        changeEtalon({
                          value: 'status',
                          label: event.target.value,
                        }),
                      )
                      dispatch(setOpen(false))
                    }),
                  )
                }
              }}
              sx={{
                flexDirection: 'row',
                fontFamily: "'Wix Madefor Display', sans-serif",
                color: '#123532',
                fontSize: '16px',
                fontWeight: '400',
                marginTop: '0px',
                paddingLeft: '5px',
              }}
            >
              <FormControlLabel
                value={'in_storage'}
                control={
                  <Radio
                    disabled={false}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='На хранении'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
              <FormControlLabel
                value={'in_use'}
                control={
                  <Radio
                    disabled={false}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='В эксплуатации'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />

              {kitGroupId?.value !== 3 && (
                <FormControlLabel
                  value={'verification'}
                  control={
                    <Radio
                      disabled={false}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Поверка'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              )}

              {kitGroupId?.value !== 3 && (
                <FormControlLabel
                  value={'attestation'}
                  control={
                    <Radio
                      disabled={false}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '22px !important',
                        },
                      }}
                    />
                  }
                  label='Аттестация'
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: "'Wix Madefor Display', sans-serif !important",
                      fontSize: '15px !important',
                      fontWeight: 400,
                      color: '#132532',
                    },
                  }}
                />
              )}

              <FormControlLabel
                value={'calibration'}
                control={
                  <Radio
                    disabled={false}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='Калибровка'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
              <FormControlLabel
                value={'repair'}
                control={
                  <Radio
                    disabled={false}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='Ремонт'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
              <FormControlLabel
                value={'maintenance'}
                control={
                  <Radio
                    disabled={false}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '22px !important',
                      },
                    }}
                  />
                }
                label='Техобслуживание'
                sx={{
                  '& .MuiTypography-root': {
                    fontFamily: "'Wix Madefor Display', sans-serif !important",
                    fontSize: '15px !important',
                    fontWeight: 400,
                    color: '#132532',
                  },
                }}
              />
            </RadioGroup>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------- */}

      <div style={{ marginTop: '14px' }}>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={validate === false ? true : false}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 20px 11px',
            margin: 0,
            borderRadius: '12px',
            width: '100%',
          }}
        >
          {validate === false ? 'Заполните все даты и поля, отмеченные звездочкой' : 'Сохранить'}
        </Button>
      </div>
    </main>
  )
}

export default AddEtalon
