// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import moment from 'moment'
import Table from '../LocalElements/Table/Table'
import Tooltip from '../../../ui/tooltip/index.tsx'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import PencilIcon from '../../../img/icon/PencilIcon.tsx'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro'

import { reUpdateEtalonMaintenances, changeEtalon } from '../../../store/slices/app/views/etalon'

interface RowDataType {
  id: number
  serviceUserName: string
  checkDate: string
  nextDate: string
  mark: string
}

interface TableDataType {
  id?: number
  by_name?: string
  date?: string | any
  interval?: string
  is_checked?: boolean
}

const TabsContainerCheckTable: React.FC = () => {
  const dispatch = useDispatch()

  const tableData = useSelector(
    (state: any) => state.etalonReducer.createData.kitMaintenances,
  ) as TableDataType[]

  const interval: string | number = useSelector(
    (state: any) => state.etalonReducer.createData.interval_maintenances,
  )

  const journalId = useSelector((state: any) => state.journalReducer.journalId)

  const initializeRows = (): RowDataType[] => {
    return tableData && tableData.length > 0
      ? tableData.map((row, index) => ({
          id: index,
          serviceUserName: row.by_name || '',
          checkDate:
            typeof row.date !== 'string' ? row.date.format('YYYY-MM-DD') : row.date ? row.date : '',
          nextDate: row.date
            ? dayjs(row.date)
                .add(Number(row?.interval) || 0, 'month')
                .add(-1, 'day')
                .format('YYYY-MM-DD')
            : '',
          mark: row.is_checked ? 'Проведено' : '-',
        }))
      : []
  }

  const [rows, setRows] = useState<RowDataType[]>(initializeRows)
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    setRows(initializeRows())
  }, [tableData, interval])

  const handleEditClick = (id: GridRowId) => () => {
    if (id === rows[0].id) {
      setRowModesModel(prevModel => ({
        ...prevModel,
        [id]: { mode: GridRowModes.Edit },
      }))
    }
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }))
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    // ----------------------------------------------------------------
    // setRows(rows.filter(row => row.id !== id))
    // ----------------------------------------------------------------

    false && console.log(tableData)
    false && console.log(id)

    dispatch(
      changeEtalon({
        value: 'kitMaintenances',
        label: tableData.filter((row, index) => index !== id),
      }),
    )
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
  }

  const processRowUpdate = (newRow: RowDataType) => {
    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)))
    return newRow
  }

  let colData: Array<any> = [
    { field: 'serviceUserName', headerName: 'ФИО лица, проводившего ТО', editable: true },
    { field: 'checkDate', headerName: 'Дата проведения', editable: false },
    { field: 'mark', headerName: 'Отметка о проведении', editable: false, type: 'boolean' },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: ({ id }: { id: GridRowId }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        const isLastRow = id === rows[0].id

        if (isInEditMode && isLastRow) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ]
        }
        if (isLastRow) {
          return [
            <Tooltip title={'Редактировать запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<PencilIcon onClick={() => {}} />}
                label='Edit'
                onClick={handleEditClick(id)}
                color='inherit'
              />
            </Tooltip>,
            <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
              <GridActionsCellItem
                icon={<DeleteIcon onClick={() => {}} />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />
            </Tooltip>,
          ]
        }

        return [
          <Tooltip
            title={'Редактирование старых записей недоступно'}
            disableHoverListener={undefined}
          >
            <GridActionsCellItem
              icon={<PencilIcon onClick={() => {}} />}
              label='Edit'
              onClick={() => {}}
              color='inherit'
              sx={{ opacity: 0.6 }}
            />
          </Tooltip>,
          <Tooltip title={'Удалить запись целиком'} disableHoverListener={undefined}>
            <GridActionsCellItem
              icon={<DeleteIcon onClick={() => {}} />}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
            />
          </Tooltip>,
        ]
      },
    },
  ]

  if (!journalId) {
    colData.splice(4, 0, {
      field: 'nextDate',
      headerName: 'Дата следующего ТО',
      editable: false,
      renderCell: (params: {
        row: {
          nextDate: any
        }
      }) => {
        const { nextDate } = params.row

        if (!nextDate) {
          return <span style={{ fontSize: '0.875rem' }}>—</span>
        }

        const today = moment().startOf('day')
        const checkDate = moment(nextDate, 'YYYY-MM-DD')

        false && console.log(checkDate.isBefore(today, 'day'))

        if (checkDate.isBefore(today, 'day')) {
          return (
            <React.Fragment>
              <Tooltip
                title={'Срок действия техобслуживания истек'}
                disableHoverListener={undefined}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#E74953', fontSize: '0.875rem', marginRight: '10px' }}>
                    {nextDate}
                  </span>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M15.6172 7.79688C15.6172 12.077 12.1472 15.5469 7.86719 15.5469C3.58715 15.5469 0.117188 12.077 0.117188 7.79688C0.117188 3.51671 3.58715 0.046875 7.86719 0.046875C12.1472 0.046875 15.6172 3.51671 15.6172 7.79688ZM9.20339 11.3378C9.20339 11.6941 9.07395 11.9971 8.8161 12.2464C8.55721 12.4958 8.24091 12.6205 7.86719 12.6205C7.68555 12.6205 7.51748 12.5912 7.36402 12.5324C7.20013 12.4702 7.05189 12.3748 6.91827 12.2464C6.81179 12.1429 6.72724 12.0303 6.6646 11.9085C6.57587 11.7366 6.53098 11.5463 6.53098 11.3378C6.53098 10.9726 6.66043 10.6653 6.91827 10.4158C7.17716 10.1664 7.49347 10.0417 7.86719 10.0417C8.24091 10.0417 8.55721 10.1664 8.8161 10.4158C8.95077 10.5458 9.04994 10.6915 9.11466 10.8529C9.17416 11.0013 9.20339 11.163 9.20339 11.3378ZM7.10618 8.22449C7.15942 8.60747 7.20848 8.94596 7.25232 9.23995H8.48205L8.56243 8.69829L8.62924 8.22449C8.68248 7.84137 8.73572 7.44495 8.78896 7.03521C8.8516 6.62548 8.90484 6.23792 8.94972 5.87268C8.98417 5.62723 9.01445 5.40671 9.03741 5.21111L9.06977 4.92403C9.10527 4.66566 9.12301 4.50085 9.12301 4.42961C9.12301 4.09112 8.99879 3.81057 8.74929 3.58782C8.50919 3.36508 8.21481 3.25377 7.86719 3.25377C7.51957 3.25377 7.22205 3.36508 6.97256 3.58782C6.73141 3.81057 6.61136 4.09112 6.61136 4.42961L6.61345 4.49994C6.61867 4.58841 6.6312 4.72973 6.65103 4.92403C6.68757 5.1824 6.72724 5.49857 6.77212 5.87268C6.82536 6.23792 6.8786 6.62548 6.93184 7.03521C6.99448 7.44495 7.05189 7.84137 7.10618 8.22449Z'
                      fill='#E74953'
                    />
                  </svg>
                </div>
              </Tooltip>
            </React.Fragment>
          )
        }

        if (checkDate.diff(today, 'days') <= 30) {
          return (
            <React.Fragment>
              <Tooltip
                title={'Срок действия техобслуживания подходит к концу'}
                disableHoverListener={undefined}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#E57004', fontSize: '0.875rem', marginRight: '10px' }}>
                    {nextDate}
                  </span>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M15.6172 7.79688C15.6172 12.077 12.1472 15.5469 7.86719 15.5469C3.58715 15.5469 0.117188 12.077 0.117188 7.79688C0.117188 3.51671 3.58715 0.046875 7.86719 0.046875C12.1472 0.046875 15.6172 3.51671 15.6172 7.79688ZM9.20339 11.3378C9.20339 11.6941 9.07395 11.9971 8.8161 12.2464C8.55721 12.4958 8.24091 12.6205 7.86719 12.6205C7.68555 12.6205 7.51748 12.5912 7.36402 12.5324C7.20013 12.4702 7.05189 12.3748 6.91827 12.2464C6.81179 12.1429 6.72724 12.0303 6.6646 11.9085C6.57587 11.7366 6.53098 11.5463 6.53098 11.3378C6.53098 10.9726 6.66043 10.6653 6.91827 10.4158C7.17716 10.1664 7.49347 10.0417 7.86719 10.0417C8.24091 10.0417 8.55721 10.1664 8.8161 10.4158C8.95077 10.5458 9.04994 10.6915 9.11466 10.8529C9.17416 11.0013 9.20339 11.163 9.20339 11.3378ZM7.10618 8.22449C7.15942 8.60747 7.20848 8.94596 7.25232 9.23995H8.48205L8.56243 8.69829L8.62924 8.22449C8.68248 7.84137 8.73572 7.44495 8.78896 7.03521C8.8516 6.62548 8.90484 6.23792 8.94972 5.87268C8.98417 5.62723 9.01445 5.40671 9.03741 5.21111L9.06977 4.92403C9.10527 4.66566 9.12301 4.50085 9.12301 4.42961C9.12301 4.09112 8.99879 3.81057 8.74929 3.58782C8.50919 3.36508 8.21481 3.25377 7.86719 3.25377C7.51957 3.25377 7.22205 3.36508 6.97256 3.58782C6.73141 3.81057 6.61136 4.09112 6.61136 4.42961L6.61345 4.49994C6.61867 4.58841 6.6312 4.72973 6.65103 4.92403C6.68757 5.1824 6.72724 5.49857 6.77212 5.87268C6.82536 6.23792 6.8786 6.62548 6.93184 7.03521C6.99448 7.44495 7.05189 7.84137 7.10618 8.22449Z'
                      fill='#E57004'
                    />
                  </svg>
                </div>
              </Tooltip>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment>
            <span style={{ fontSize: '0.875rem', marginRight: '10px' }}>{nextDate}</span>
          </React.Fragment>
        )
      },
    })
  }

  return (
    <Table
      colData={colData}
      rowData={rows}
      rowCallback={() => {}}
      onRowUpdate={(updatedRows: any) => dispatch(reUpdateEtalonMaintenances(updatedRows))}
      rowSelectionCallback={() => {}}
      hasCheckboxSelection={false}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10 },
        },
      }}
      rowModesModel={rowModesModel}
      onRowModesModelChange={setRowModesModel}
      onProcessRowUpdate={processRowUpdate}
    />
  )
}

export default TabsContainerCheckTable
