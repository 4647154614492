import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@mui/material'
import Input from '../../../../LocalElements/Input/InputMUI'

import R from '../../../../../../services/app/client-server/request.service.ts'

import { changeProperty } from '../state'
import { sanitizeInput } from '../../../../../../utils/sanitizeInput'

const Conditions = () => {
  const dispatch = useDispatch()
  const [tariffIds, setTariffIds] = useState([])
  const [startCondition, setStartCondition] = useState(false)
  const [resetCondition, setResetCondition] = useState(false)

  const temperature = useSelector(state => state.gost83242002Reducer.temperature)
  const humidity = useSelector(state => state.gost83242002Reducer.humidity)
  const pressure = useSelector(state => state.gost83242002Reducer.pressure)
  const voltageDeviation = useSelector(state => state.gost83242002Reducer.voltageDeviation)
  const frequencyDeviation = useSelector(state => state.gost83242002Reducer.frequencyDeviation)
  const airTempDifference = useSelector(state => state.gost83242002Reducer.airTempDifference)
  const airTempSpeed = useSelector(state => state.gost83242002Reducer.airTempSpeed)

  const getRandomNumber = (min, max) => {
    const result = (Math.random() * (max - min) + min).toFixed(1)
    return result === '-0.0' || result === '0.0' ? '0' : result
  }

  const autogenerateProtocol = () => {
    setStartCondition(true)

    setTimeout(() => {
      dispatch(changeProperty({ value: 'temperature', label: getRandomNumber(15, 25) }))
      dispatch(changeProperty({ value: 'humidity', label: getRandomNumber(30, 80) }))
      dispatch(changeProperty({ value: 'pressure', label: getRandomNumber(84, 106) }))
      dispatch(changeProperty({ value: 'voltageDeviation', label: getRandomNumber(-2, 2) }))
      dispatch(changeProperty({ value: 'frequencyDeviation', label: getRandomNumber(-1, 1) }))
      dispatch(changeProperty({ value: 'airTempDifference', label: getRandomNumber(-1, 1) }))
      dispatch(changeProperty({ value: 'airTempSpeed', label: getRandomNumber(-1, 1) }))

      setStartCondition(false)
    }, 1000)
  }

  const Reset = () => {
    setResetCondition(true)

    setTimeout(() => {
      dispatch(changeProperty({ value: 'temperature', label: '' }))
      dispatch(changeProperty({ value: 'humidity', label: '' }))
      dispatch(changeProperty({ value: 'pressure', label: '' }))
      dispatch(changeProperty({ value: 'voltageDeviation', label: '' }))
      dispatch(changeProperty({ value: 'frequencyDeviation', label: '' }))
      dispatch(changeProperty({ value: 'airTempDifference', label: '' }))
      dispatch(changeProperty({ value: 'airTempSpeed', label: '' }))

      setResetCondition(false)
    }, 1000)
  }

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'conditions',
        label: [
          {
            temperature_environment: parseFloat(temperature),
            relative_humidity: parseFloat(humidity),
            atmospheric_pressure: parseFloat(pressure),
            voltage_deviation: parseFloat(voltageDeviation),
            frequency_deviation: parseFloat(frequencyDeviation),
            air_temp_difference: parseFloat(airTempDifference),
            air_temp_speed: parseFloat(airTempSpeed),
          },
        ],
      }),
    )
  }, [
    temperature,
    humidity,
    pressure,
    voltageDeviation,
    frequencyDeviation,
    airTempDifference,
    airTempSpeed,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography variant='h2'>Соблюдение условий поверки</Typography>

        {tariffIds && tariffIds.includes(5) && (
          <div className='width50Percent'>
            <div
              className='flexContainerWithGap'
              style={{
                marginBottom: '15px',
              }}
            >
              <div className='width50Percent'>
                <Button
                  variant='contained'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    color: 'white',
                    width: '100%',
                  }}
                  onClick={autogenerateProtocol}
                  disabled={startCondition}
                >
                  {startCondition ? <CircularProgress size={26} thickness={4} /> : 'Тестовая среда'}
                </Button>
              </div>
              <div className='width50Percent'>
                <Button
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    width: '100%',
                  }}
                  onClick={Reset}
                  disabled={
                    (!temperature &&
                      !humidity &&
                      !pressure &&
                      !voltageDeviation &&
                      !frequencyDeviation &&
                      !airTempDifference &&
                      !airTempSpeed) ||
                    resetCondition
                  }
                >
                  {resetCondition ? (
                    <CircularProgress size={26} thickness={4} />
                  ) : (
                    'Сбросить все значения'
                  )}
                </Button>
              </div>
            </div>
            <Typography fontSize={14} sx={{ fontStyle: 'italic', lineHeight: '22px' }}>
              Условия поверки указаны как пример в помощь поверителю. При создании протокола условия
              поверки необходимо скорректировать.
            </Typography>
          </div>
        )}
      </div>

      <div className='flexContainerWithGap'>
        <div className='width32Percent'>
          <Input
            label={'Температура окружающей среды, °C'}
            placeholder={'0.00'}
            value={temperature}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'temperature', label: sanitizeInput(value) })),
            }}
          />
        </div>

        <div className='width32Percent'>
          <Input
            label={'Относительная влажность, %'}
            placeholder={'0.00'}
            value={humidity}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'humidity', label: sanitizeInput(value) })),
            }}
          />
        </div>

        <div className='width32Percent'>
          <Input
            label={'Атмосферное давление, кПа '}
            placeholder={'0.00'}
            value={pressure}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'pressure', label: sanitizeInput(value) })),
            }}
          />
        </div>
      </div>

      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Input
            label={'Отклонение напряжения питания от номинального (± 2), %'}
            placeholder={'0.00'}
            value={voltageDeviation}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({ value: 'voltageDeviation', label: sanitizeInput(value, true) }),
                ),
            }}
          />
        </div>

        <div className='width50Percent'>
          <Input
            label={'Отклонение частоты питания переменного тока (± 1), Гц'}
            placeholder={'0.00'}
            value={frequencyDeviation}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({
                    value: 'frequencyDeviation',
                    label: sanitizeInput(value, true),
                  }),
                ),
            }}
          />
        </div>
      </div>

      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Input
            label={'Разность температур окружающего воздуха и поверочной среды, °С'}
            placeholder={'0.00'}
            value={airTempDifference}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({ value: 'airTempDifference', label: sanitizeInput(value, true) }),
                ),
            }}
          />
        </div>

        <div className='width50Percent'>
          <Input
            label={'Скорость изменения температуры окружающего воздуха и ПС, °С/ч'}
            placeholder={'0.00'}
            value={airTempSpeed}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({ value: 'airTempSpeed', label: sanitizeInput(value, true) }),
                ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Conditions
