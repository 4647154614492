// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import Selector from '../../../LocalElements/Selector/reactSelect/Selector'
import { changeCase } from '../../../../../store/slices/app/views/protocols'
import { toggleEnableStep } from '../../../../../store/slices/app/controlers/stepper'
import RadioButton from '../../../../../ui/radio-button/index.tsx'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'

import useWindowDimensions from '../../../../hooks/useWindowDimensions'

const TypeMethod = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const verificationMethod = useSelector(state => state.protocolReducer.case.method)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)
  const pointMethod = useSelector(state => state.protocolReducer.case.pointMethod)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const changeType = event => {
    dispatch(changeCase({ value: 'verificationType', label: event.target.value }))
  }
  const changeMethod = event => {
    dispatch(changeCase({ value: 'method', label: event.target.value }))
  }

  const options = pointMethod
    ? pointMethod.map(item => ({
        value: item.id,
        label: item.additional ? `${item.name} ${item.additional}` : item.name,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeCase({
          value: 'pointId',
          label: selectedOption.value,
        }),
      )

      const selectedPoint = pointMethod.find(item => item.id === selectedOption.value)

      if (selectedPoint && selectedPoint.isOpticalSensor !== undefined) {
        dispatch(
          changeCase({
            value: 'opticalSensor',
            label: !!selectedPoint.isOpticalSensor,
          }),
        )
      } else {
        dispatch(changeCase({ value: 'opticalSensor', label: null }))
      }
    }
  }

  useEffect(() => {
    if (options.length === 1 && pointId === null) {
      getValue(options[0])
    }
  }, [options])

  const enableNextStep = useMemo(() => {
    return verificationMethod && verificationType && pointId && dateVerification
  }, [verificationMethod, verificationType, pointId, dateVerification])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Typography variant='h2'>Рамки применения методики</Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: width > 1050 ? 'row' : 'column',
          gap: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            width: width > 1050 ? '30%' : '100%',
          }}
        >
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '15px' }}>
            Способ проведения
            {!!verificationMethod ? (
              <CheckCircleIcon style={{ marginBottom: '-2px' }} />
            ) : (
              <VisibilityToggleIcon style={{ marginBottom: '-2px' }} />
            )}
          </Typography>

          <RadioButton
            value={verificationMethod}
            onChange={changeMethod}
            trueLabel={'На выезде'}
            falseLabel={'В лаборатории'}
            trueValue={'field'}
            falseValue={'laboratory'}
            trueDisabled={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'laboratory'
            }
            falseDisabled={
              verificationMethod &&
              verificationMethod.length === 1 &&
              verificationMethod[0] === 'field'
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            width: width > 1050 ? '30%' : '100%',
          }}
        >
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '15px' }}>
            Тип поверки
            {!!verificationType ? (
              <CheckCircleIcon style={{ marginBottom: '-2px' }} />
            ) : (
              <VisibilityToggleIcon style={{ marginBottom: '-2px' }} />
            )}
          </Typography>

          <RadioButton
            value={verificationType}
            onChange={changeType}
            trueLabel={'Первичная'}
            falseLabel={'Периодическая'}
            trueValue={'initial'}
            falseValue={'periodic'}
            trueDisabled={
              verificationType &&
              verificationType.length === 1 &&
              verificationType[0] === 'periodic'
            }
            falseDisabled={
              verificationType && verificationType.length === 1 && verificationType[0] === 'initial'
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: width > 1050 ? '40%' : '100%',
            gap: '8px',
          }}
        >
          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '15px' }}>
            Конкретные разделы и пункты методики
          </Typography>
          <div style={{ width: '100%' }}>
            <Selector
              placeholder={'Выберете раздел методики'}
              onChange={getValue}
              options={options}
              isClearable={false}
              isSearchable={false}
              noOptionsMessage={() => 'Для этой методики нет доступных пунктов поверки'}
              value={pointId ? options.find(option => option.value === pointId) : null}
              isRequired={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeMethod
