import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import theme from '../../../../../utils/theme.ts'
import { TotalAcceptedTrue, TotalAcceptedFalse } from '../../styles.ts'

import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'
import { setName } from '../../../../../store/slices/app/controlers/pageName.js'

const TotalAccepted = props => {
  const { value, inspectionFuckup = null, testingFuckup = null, indicationsFuckup = null } = props
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setName('Заключение поверки'))
  }, [dispatch])

  return (
    <React.Fragment>
      {value ? (
        <TotalAcceptedTrue style={width > 1050 ? {} : { padding: '12px' }}>
          <Typography
            variant='h2'
            style={width > 1050 ? {} : { marginBottom: '9px' }}
            color={theme.palette.text.secondary}
          >
            Заключение поверки
          </Typography>
          <Typography variant='subtitle2'>
            На основании результатов поверки, средство измерения признано пригодным к использованию.
          </Typography>
        </TotalAcceptedTrue>
      ) : (
        <TotalAcceptedFalse style={width > 1050 ? {} : { padding: '12px' }}>
          <Typography
            variant='h2'
            color={theme.palette.background.error}
            style={width > 1050 ? {} : { marginBottom: '9px' }}
          >
            Заключение поверки
          </Typography>
          <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '16px' }}>
            <Typography variant='subtitle2'>
              На основании результатов поверки, средство измерения признано непригодным к
              использованию
            </Typography>
          </div>
          {inspectionFuckup !== null && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                gap: '6px',
                marginTop: '10px',
              }}
            >
              <div style={{ marginBottom: '-5px', transform: 'scale(1.2)' }}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M15.5 7.75C15.5 12.0302 12.03 15.5 7.75 15.5C3.46996 15.5 0 12.0302 0 7.75C0 3.46983 3.46996 0 7.75 0C12.03 0 15.5 3.46983 15.5 7.75ZM9.08621 11.2909C9.08621 11.6472 8.95676 11.9502 8.69892 12.1995C8.44003 12.4489 8.12372 12.5737 7.75 12.5737C7.56836 12.5737 7.40029 12.5443 7.24683 12.4856C7.08294 12.4233 6.9347 12.3279 6.80108 12.1995C6.69461 12.0961 6.61005 11.9835 6.54741 11.8616C6.45868 11.6897 6.41379 11.4995 6.41379 11.2909C6.41379 10.9257 6.54324 10.6184 6.80108 10.3689C7.05997 10.1195 7.37628 9.9948 7.75 9.9948C8.12372 9.9948 8.44003 10.1195 8.69892 10.3689C8.83358 10.4989 8.93275 10.6446 8.99747 10.8061C9.05698 10.9544 9.08621 11.1161 9.08621 11.2909ZM6.98899 8.17761C7.04223 8.5606 7.09129 8.89909 7.13514 9.19308H8.36486L8.44524 8.65142L8.51205 8.17761C8.56529 7.7945 8.61853 7.39807 8.67177 6.98834C8.73441 6.5786 8.78765 6.19105 8.83254 5.82581C8.86699 5.58036 8.89726 5.35983 8.92022 5.16423L8.95259 4.87715C8.98808 4.61879 9.00583 4.45398 9.00583 4.38273C9.00583 4.04424 8.8816 3.76369 8.6321 3.54095C8.39201 3.3182 8.09762 3.2069 7.75 3.2069C7.40238 3.2069 7.10486 3.3182 6.85537 3.54095C6.61422 3.76369 6.49417 4.04424 6.49417 4.38273L6.49626 4.45307C6.50148 4.54154 6.51401 4.68286 6.53384 4.87715C6.57038 5.13552 6.61005 5.4517 6.65494 5.82581C6.70818 6.19105 6.76142 6.5786 6.81465 6.98834C6.87729 7.39807 6.93471 7.7945 6.98899 8.17761Z'
                    fill='#FF5963'
                  />
                </svg>
              </div>
              <Typography variant='subtitle2' color={theme.palette.background.error}>
                <span style={{ fontWeight: 600 }}>Внешний осмотр:</span> не сооответствует
              </Typography>
            </div>
          )}
          {testingFuckup !== null && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                gap: '6px',
                marginTop: '10px',
              }}
            >
              <div style={{ marginBottom: '-5px', transform: 'scale(1.2)' }}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M15.5 7.75C15.5 12.0302 12.03 15.5 7.75 15.5C3.46996 15.5 0 12.0302 0 7.75C0 3.46983 3.46996 0 7.75 0C12.03 0 15.5 3.46983 15.5 7.75ZM9.08621 11.2909C9.08621 11.6472 8.95676 11.9502 8.69892 12.1995C8.44003 12.4489 8.12372 12.5737 7.75 12.5737C7.56836 12.5737 7.40029 12.5443 7.24683 12.4856C7.08294 12.4233 6.9347 12.3279 6.80108 12.1995C6.69461 12.0961 6.61005 11.9835 6.54741 11.8616C6.45868 11.6897 6.41379 11.4995 6.41379 11.2909C6.41379 10.9257 6.54324 10.6184 6.80108 10.3689C7.05997 10.1195 7.37628 9.9948 7.75 9.9948C8.12372 9.9948 8.44003 10.1195 8.69892 10.3689C8.83358 10.4989 8.93275 10.6446 8.99747 10.8061C9.05698 10.9544 9.08621 11.1161 9.08621 11.2909ZM6.98899 8.17761C7.04223 8.5606 7.09129 8.89909 7.13514 9.19308H8.36486L8.44524 8.65142L8.51205 8.17761C8.56529 7.7945 8.61853 7.39807 8.67177 6.98834C8.73441 6.5786 8.78765 6.19105 8.83254 5.82581C8.86699 5.58036 8.89726 5.35983 8.92022 5.16423L8.95259 4.87715C8.98808 4.61879 9.00583 4.45398 9.00583 4.38273C9.00583 4.04424 8.8816 3.76369 8.6321 3.54095C8.39201 3.3182 8.09762 3.2069 7.75 3.2069C7.40238 3.2069 7.10486 3.3182 6.85537 3.54095C6.61422 3.76369 6.49417 4.04424 6.49417 4.38273L6.49626 4.45307C6.50148 4.54154 6.51401 4.68286 6.53384 4.87715C6.57038 5.13552 6.61005 5.4517 6.65494 5.82581C6.70818 6.19105 6.76142 6.5786 6.81465 6.98834C6.87729 7.39807 6.93471 7.7945 6.98899 8.17761Z'
                    fill='#FF5963'
                  />
                </svg>
              </div>
              <Typography variant='subtitle2' color={theme.palette.background.error}>
                <span style={{ fontWeight: 600 }}>Опробование:</span> не сооответствует
              </Typography>
            </div>
          )}
          {indicationsFuckup !== null && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                gap: '6px',
                marginTop: '10px',
              }}
            >
              <div style={{ marginBottom: '-5px', transform: 'scale(1.2)' }}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M15.5 7.75C15.5 12.0302 12.03 15.5 7.75 15.5C3.46996 15.5 0 12.0302 0 7.75C0 3.46983 3.46996 0 7.75 0C12.03 0 15.5 3.46983 15.5 7.75ZM9.08621 11.2909C9.08621 11.6472 8.95676 11.9502 8.69892 12.1995C8.44003 12.4489 8.12372 12.5737 7.75 12.5737C7.56836 12.5737 7.40029 12.5443 7.24683 12.4856C7.08294 12.4233 6.9347 12.3279 6.80108 12.1995C6.69461 12.0961 6.61005 11.9835 6.54741 11.8616C6.45868 11.6897 6.41379 11.4995 6.41379 11.2909C6.41379 10.9257 6.54324 10.6184 6.80108 10.3689C7.05997 10.1195 7.37628 9.9948 7.75 9.9948C8.12372 9.9948 8.44003 10.1195 8.69892 10.3689C8.83358 10.4989 8.93275 10.6446 8.99747 10.8061C9.05698 10.9544 9.08621 11.1161 9.08621 11.2909ZM6.98899 8.17761C7.04223 8.5606 7.09129 8.89909 7.13514 9.19308H8.36486L8.44524 8.65142L8.51205 8.17761C8.56529 7.7945 8.61853 7.39807 8.67177 6.98834C8.73441 6.5786 8.78765 6.19105 8.83254 5.82581C8.86699 5.58036 8.89726 5.35983 8.92022 5.16423L8.95259 4.87715C8.98808 4.61879 9.00583 4.45398 9.00583 4.38273C9.00583 4.04424 8.8816 3.76369 8.6321 3.54095C8.39201 3.3182 8.09762 3.2069 7.75 3.2069C7.40238 3.2069 7.10486 3.3182 6.85537 3.54095C6.61422 3.76369 6.49417 4.04424 6.49417 4.38273L6.49626 4.45307C6.50148 4.54154 6.51401 4.68286 6.53384 4.87715C6.57038 5.13552 6.61005 5.4517 6.65494 5.82581C6.70818 6.19105 6.76142 6.5786 6.81465 6.98834C6.87729 7.39807 6.93471 7.7945 6.98899 8.17761Z'
                    fill='#FF5963'
                  />
                </svg>
              </div>
              <Typography variant='subtitle2' color={theme.palette.background.error}>
                <span style={{ fontWeight: 600 }}>Погрешность:</span> не сооответствует
              </Typography>
            </div>
          )}
          {width > 1050 && (
            <Typography
              variant='subtitle2'
              color={theme.palette.background.error}
              sx={{ opacity: '0.8' }}
            >
              * Статус заключения зависит от результатов внешнего осмотра, опробования, определения
              относительной погрешности.
            </Typography>
          )}
        </TotalAcceptedFalse>
      )}
    </React.Fragment>
  )
}

export default TotalAccepted
