import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types.models.views'

const { Container, Mobile } = css

const DataOnTheDevice: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.Paper>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Поверяемый прибор</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span
              style={{ color: '#858585' }}
            >{`${viewData.meter.fif_number}, ${viewData.meter.name_si}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Модификация прибора</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{`${viewData.meter.type_si}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Заводской номер прибора</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{`${viewData.details.meter_factory_number}`}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span>Год выпуска прибора</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow style={{ justifyContent: 'flex-start' }}>
            <span style={{ color: '#858585' }}>{`${viewData.details.meter_factory_year}`}</span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default DataOnTheDevice
