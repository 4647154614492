import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@mui/material'
import Tooltip from '../../../../../../../ui/tooltip/index.tsx'

import useGenerateProtocolData from '../../../../../../hooks/useGenerateGasGost8323.js'
import R from '../../../../../../../services/app/client-server/request.service.ts'

export const ExtendedTariffBtn = () => {
  const [tariffIds, setTariffIds] = useState([])
  const [startGenerateIndication, setStartGenerateIndication] = useState(false)
  const [resetIndication, setResetIndication] = useState(false)
  const { generateIndicationData, defaultIndicationData } = useGenerateProtocolData()

  const start_1 = useSelector(state => state.gost83242002Reducer.start_1)
  const finish_1 = useSelector(state => state.gost83242002Reducer.finish_1)
  const q_min = useSelector(state => state.gost83242002Reducer.q_min)

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()
      const tariffIds = data.tariffs.map(tariff => tariff.id)
      setTariffIds(tariffIds.length ? tariffIds : null)
    })()
  }, [])

  const handleGenerateClick = () => {
    setStartGenerateIndication(true)

    setTimeout(() => {
      generateIndicationData()
      setStartGenerateIndication(false)
    }, 1000)
  }

  const handleResetClick = () => {
    setResetIndication(true)

    setTimeout(() => {
      defaultIndicationData()
      setResetIndication(false)
    }, 1000)
  }

  if (!tariffIds || !tariffIds.includes(5)) return null

  return (
    <div
      className='width50Percent'
      style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
    >
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className='width50Percent'>
          <Tooltip title={start_1 ? '' : 'Необходимо ввести показания на начало'}>
            <Button
              disabled={!start_1 || !q_min || startGenerateIndication}
              onClick={handleGenerateClick}
              variant='contained'
              style={{
                textTransform: 'none',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '10px 20px 12px',
                borderRadius: '12px',
                color: 'white',
                width: '100%',
                position: 'relative',
              }}
            >
              {startGenerateIndication ? (
                <CircularProgress size={26} thickness={4} />
              ) : (
                'Тестовая среда'
              )}
            </Button>
          </Tooltip>
        </div>

        <div className='width50Percent'>
          <Button
            disabled={!finish_1 || resetIndication}
            onClick={handleResetClick}
            variant='outlined'
            style={{
              textTransform: 'none',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '10px 20px 12px',
              borderRadius: '12px',
              width: '100%',
            }}
          >
            {resetIndication ? (
              <CircularProgress size={26} thickness={4} />
            ) : (
              'Сбросить все значения'
            )}
          </Button>
        </div>
      </div>

      <Typography fontSize={14} sx={{ fontStyle: 'italic', lineHeight: '22px' }}>
        Генерация данных приведена как пример в помощь поверителю. При создании протокола все данные
        необходимо скорректировать.
      </Typography>
    </div>
  )
}
