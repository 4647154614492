import { styled } from '@mui/material'

interface ActiveDotProps {
  left: string
}

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#fff',
  borderRadius: '16px',
  padding: '20px',
  border: '1px solid #d7ddea',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'flex-start',
  },
}))

export const InfoBlock = styled('div')(({ theme }) => ({
  width: '70%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const InfoRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
})

export const ImageBlock = styled('div')(({ theme }) => ({
  width: '26.6%',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const StyledImage = styled('img')({
  display: 'block',
  position: 'relative',
  width: '100%',
  border: '1px solid #EAEDF3',
  borderRadius: '12px',
  objectFit: 'contain',
  cursor: 'pointer',
})

export const SliderWrapper = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  width: '100%',
  justifyContent: 'center',
  position: 'relative',
  marginTop: '10px',
})

export const SliderInner = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  gap: '10px',
  position: 'relative',
})

export const ActiveDot = styled('div')<ActiveDotProps>(({ left }) => ({
  display: 'block',
  position: 'absolute',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#0084e2',
  left,
}))

export const InactiveDot = styled('div')({
  display: 'block',
  position: 'relative',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: '1px solid #0084e2',
  cursor: 'pointer',
})

export const EmptyDot = styled('div')({
  display: 'block',
  position: 'relative',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#0084e2',
  left: 0,
  filter: 'grayscale(1)',
  opacity: '0.6',
})

export const ModalImage = styled('img')({
  display: 'block',
  position: 'relative',
  width: '550px',
  border: '1px solid #EAEDF3',
  borderRadius: '12px',
  objectFit: 'contain',
  cursor: 'pointer',
  marginTop: '4px',
})

export const ContainerDescDev = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  background: '#fff',
  borderRadius: '16px',
  padding: '20px',
  border: '1px solid #d7ddea',
})

export const FileBox = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  position: 'relative',
  boxSizing: 'border-box',
  border: '1px solid #EAEDF3',
  width: '320px',
  minHeight: '30px',
  padding: '13px 19.6px',
  borderRadius: '8px',
  gap: '19px',
  cursor: 'pointer',
  alignItems: 'center',
})

export const FileIcon = styled('img')({
  width: '32px',
})

export const FileInfo = styled('div')({
  display: 'flex',
  flexFlow: 'column',
})

export const FileRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})

export const TableHeader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '14px',
  borderRadius: '8px',
  backgroundColor: 'rgba(248, 249, 252, 1)',
})

export const TableRow = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '14px',
})

export const TableCell = styled('div')({
  width: '25%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
})

export const CharColumn = styled('div')({
  width: '33.3%',
  padding: '0 40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
})

export const CharLabelRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const CharValueBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '33.3%',
})

export const CharValueCell = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

export const Cell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '2px',
    alignItems: 'flex-start',
  },
}))

export const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '8px',
  },
}))
