import React, { ReactElement, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Tariffs from '../comps/Tarrifs.tsx'
import FilterCompany from '../../app/components/LocalElements/Filter/FilterCompany'
import useWindowDimensions from '../../app/hooks/useWindowDimensions'

import {
  setTariff,
  setTariffs,
  setCompanyId,
  setStatus,
} from '../../store/slices/app/views/companies'
import R from '../../services/app/client-server/request.service.ts'

const TarrifsView: React.FC = (): ReactElement => {
  const userRole = useSelector((state: any) => state.headerReducer.role)
  const company_id = useSelector((state: any) => state.companyIdReducer.companyId)

  const [companyData, setCompanyData] = useState([])

  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(company_id)
      const data = companiesItemData.data
      setCompanyData(data)

      dispatch(setTariff(data.subscription_id))
      dispatch(setCompanyId(data.id))

      const initialTariffs = data.tariffs.map(item => ({
        tariffId: item.id,
      }))

      dispatch(setTariffs(initialTariffs))

      dispatch(setStatus(data.status))
    })()
  }, [company_id, dispatch])

  return (
    <React.Fragment>
      <main className={'main'}>
        {userRole === 'super' && (
          <div
            style={{
              width: width > 1050 ? '100%' : '100%',
              marginBottom: '30px',
            }}
          >
            <FilterCompany disabled={false} />
          </div>
        )}

        {company_id && <Tariffs companyData={companyData} />}
      </main>
    </React.Fragment>
  )
}

export default TarrifsView
