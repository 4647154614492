import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const fuckUpAlert = (
  message: string,
  variant?: 'error' | 'info' | 'warning' | 'success',
  consoleComment?: string | Object,
) => {
  !!!variant && toast.info(message)

  variant === 'error' && toast.error(message)
  variant === 'info' && toast.info(message)
  variant === 'success' && toast.success(message)
  variant === 'warning' && toast.warning(message)

  console.warn(consoleComment)
}

export default fuckUpAlert
