import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { sanitizeInput } from '../../../../../../utils/sanitizeInput'
import { calculateQt } from '../helpers/helpers.js'

import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import Input from '../../../../LocalElements/Input/InputMUI'
import { changeProperty } from '../state'

const DescriptionType = () => {
  const dispatch = useDispatch()

  const selectedModification = useSelector(state => state.gost83242002Reducer.selectedModification)
  const standartSize = useSelector(state => state.gost83242002Reducer.standartSize)
  const q_min = useSelector(state => state.gost83242002Reducer.q_min)
  const q_t = useSelector(state => state.gost83242002Reducer.q_t)
  const q_max = useSelector(state => state.gost83242002Reducer.q_max)
  const q_nom = useSelector(state => state.gost83242002Reducer.q_nom)
  const q_t_formula = useSelector(state => state.gost83242002Reducer.q_t_formula)
  const q_max_limit_negative = useSelector(state => state.gost83242002Reducer.q_max_limit_negative)
  const q_max_limit_positive = useSelector(state => state.gost83242002Reducer.q_max_limit_positive)
  const q_min_limit_negative = useSelector(state => state.gost83242002Reducer.q_min_limit_negative)
  const q_min_limit_positive = useSelector(state => state.gost83242002Reducer.q_min_limit_positive)

  const meterTypeData = [
    'G1,6',
    'G2,5',
    'G4',
    'G6',
    'G10',
    'G14',
    'G16',
    'G25',
    'G40',
    'G65',
    'G100',
    'G160',
    'G250',
    'G400',
    'G650',
    'G1000',
    'G1600',
    'G2500',
    'G4000',
    'G6500',
    'G10000',
    'G16000',
  ]

  const options = meterTypeData
    ? meterTypeData.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = selectedOption => {
    dispatch(
      changeProperty({
        value: 'standartSize',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  useEffect(() => {
    const calculatedQt = calculateQt(q_t_formula, q_min, q_max, q_nom)
    dispatch(changeProperty({ value: 'q_t', label: calculatedQt }))
  }, [q_t_formula, q_min, q_max, q_nom, dispatch])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography variant='h2'>
        Метрологические и технические характеристики (по описанию типа)
      </Typography>

      <div className='flexContainerWithGap'>
        <div className='width32Percent'>
          <Selector
            placeholder={'Выберите типоразмер'}
            options={options}
            onChange={getValue}
            value={options.find(option => option.value === standartSize)}
            isClearable={false}
            isSearchable={true}
            isDisabled={!!selectedModification && !!standartSize}
            label={{
              text: 'Типоразмер',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>

        <div className='width32Percent'>
          <Input
            label={'Минимальный расход (Qmin), м³/ч'}
            placeholder={'0.000'}
            value={q_min}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_min', label: sanitizeInput(value) })),
            }}
          />
        </div>

        <div className='width32Percent'>
          <Input
            label={'Максимальный расход (Qmax), м³/ч'}
            placeholder={'0.000'}
            value={q_max}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_max', label: sanitizeInput(value) })),
            }}
          />
        </div>
      </div>

      <div className='flexContainerWithGap'>
        <div className='width32Percent'>
          <Input
            label={'Формула расчета Qt'}
            placeholder={'0.000'}
            value={q_t_formula}
            isDisabled={true}
          />
        </div>

        {q_nom !== null && q_nom !== undefined && (
          <div className='width32Percent'>
            <Input
              label={'Номинальный расход (Qnom), м³/ч'}
              placeholder={'0.000'}
              value={q_nom}
              actions={{
                change: value =>
                  dispatch(changeProperty({ value: 'q_nom', label: sanitizeInput(value) })),
              }}
            />
          </div>
        )}

        <div className='width32Percent'>
          <Input
            label={'Переходный расход (Qt), м³/ч'}
            placeholder={'0.000'}
            value={q_t}
            isDisabled={true}
          />
        </div>
      </div>

      <Typography variant='h2'>Пределы допускаемой погрешности, %</Typography>

      <div className='flexContainerWithGap'>
        <div className='width32Percent'>
          <Input
            label={'От Qmin до Qt (-), %'}
            placeholder={'0.00'}
            value={q_min_limit_negative}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({
                    value: 'q_min_limit_negative',
                    label: sanitizeInput(value, true),
                  }),
                ),
            }}
          />
        </div>
        <div className='width32Percent'>
          <Input
            label={'От Qmin до Qt (+), %'}
            placeholder={'0.00'}
            value={q_min_limit_positive}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({ value: 'q_min_limit_positive', label: sanitizeInput(value) }),
                ),
            }}
          />
        </div>

        <div className='width32Percent'>
          <Input
            label={'От Qt до Qmax (-), %'}
            placeholder={'0.00'}
            value={q_max_limit_negative}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({
                    value: 'q_max_limit_negative',
                    label: sanitizeInput(value, true),
                  }),
                ),
            }}
          />
        </div>
        <div className='width32Percent'>
          <Input
            label={'От Qt до Qmax (+), %'}
            placeholder={'0.00'}
            value={q_max_limit_positive}
            actions={{
              change: value =>
                dispatch(
                  changeProperty({ value: 'q_max_limit_positive', label: sanitizeInput(value) }),
                ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DescriptionType
