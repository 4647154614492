// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Selector from '../../../LocalElements/Selector/reactSelect/Selector'
import R from '../../../../../services/app/client-server/request.service.ts'

import {
  setMeasurementId,
  setMethodId,
  resetMethodId,
  changeCase,
  resetCase,
  setDateVerification,
} from '../../../../../store/slices/app/views/protocols'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import theme from '../../../../../utils/theme.ts'
import FilterCompany from '../../../../components/LocalElements/Filter/FilterCompany.jsx'
import VerificationDate from '../ProtocolVerificationDate/VerificationDate.jsx'

import { setName } from '../../../../../store/slices/app/controlers/pageName.js'

const Method = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)

  const [measurement, setMeasurement] = useState([])
  const [method, setMethod] = useState([])
  const [cases, setCases] = useState([])
  const [availableDates, setAvailableDates] = useState([])

  const companyId = useSelector(state => state.companyIdReducer.companyId)
  const measurementId = useSelector(state => state.protocolReducer.measurementId)
  const methodId = useSelector(state => state.protocolReducer.methodId)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const dateVerification = useSelector(state => state.protocolReducer.dateVerification)

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMeasurement = measurement
    ? measurement.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : []

  const getValueMeasurement = selectedOption => {
    dispatch(setMeasurementId(selectedOption ? selectedOption.value : ''))
    dispatch(resetMethodId())
    dispatch(resetCase())
    dispatch(setDateVerification(''))
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setMeasurement(measurement)

      if (measurement.length === 1) {
        dispatch(setMeasurementId(measurement[0].id))
      }
    })()
  }, [dispatch])

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsMethod = method
    ? method.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : []

  const getValueMethod = selectedOption => {
    dispatch(setMethodId(selectedOption ? selectedOption.value : ''))
    dispatch(resetCase())
    dispatch(setDateVerification(''))
  }

  useEffect(() => {
    if (measurementId) {
      ;(async () => {
        const { data: listMethod } = await R.getMethodItem(
          `?company_id=${companyId}&measurement_id=${measurementId}`,
        )

        const method = listMethod.data
        setMethod(method)

        if (method.length === 1) {
          dispatch(setMethodId(method[0].id))
        }
      })()
    }
  }, [companyId, measurementId, dispatch])

  // -------------------------------------------------------------
  // -------------------------------------------------------------

  const optionsCases = cases
    ? cases.map(item => ({
        value: item.id,
        label: (
          <span
            onClick={() => {
              !item.is_usable && navigate('/metriva/cases')
            }}
            style={{
              color: item.is_usable ? 'inherit' : theme.palette.background.error,
              fontSize: '13px',
            }}
          >
            {item.inner_name} -
            {item.is_usable ? ' доступен' : ' недоступен, cм. причины в разделе «Комплекты СП»'}
          </span>
        ),
        isDisabled: !item.is_usable,
      }))
    : []

  const getValueCases = selectedOption => {
    if (selectedOption) {
      dispatch(setDateVerification(''))

      dispatch(changeCase({ value: 'caseId', label: selectedOption.value }))
      const selectedComplect = cases.find(item => item.id === selectedOption.value)

      dispatch(
        changeCase({
          value: 'pointMethod',
          label: selectedComplect.points.map(point => ({
            id: point.id,
            name: point.name,
            additional: point.additional,
          })),
        }),
      )

      dispatch(
        changeCase({
          value: 'method',
          label: selectedComplect.verification_methods,
        }),
      )

      dispatch(
        changeCase({
          value: 'verificationType',
          label: selectedComplect.verification_types,
        }),
      )

      setAvailableDates(selectedComplect.available_dates || [])
    }
  }

  useEffect(() => {
    if (methodId) {
      ;(async () => {
        const { data: listCases } = await R.getCasesItem(
          `?company_id=${companyId}&method_id=${methodId}`,
        )
        const data = listCases.data
        setCases(data)

        const exists = data.find(item => item.id === caseId)
        if (!exists) {
          dispatch(resetCase())
          setAvailableDates([])
        }

        const usableCases = data.filter(item => item.is_usable)
        if (usableCases.length === 1) {
          const selectedComplect = usableCases[0]

          dispatch(setDateVerification(''))
          dispatch(changeCase({ value: 'caseId', label: selectedComplect.id }))
          dispatch(
            changeCase({
              value: 'pointMethod',
              label: selectedComplect.points.map(point => ({
                id: point.id,
                name: point.name,
                additional: point.additional,
              })),
            }),
          )
          dispatch(
            changeCase({
              value: 'method',
              label: selectedComplect.verification_methods,
            }),
          )
          dispatch(
            changeCase({
              value: 'verificationType',
              label: selectedComplect.verification_types,
            }),
          )
          setAvailableDates(selectedComplect.available_dates || [])
        }
      })()
    }
  }, [companyId, methodId, caseId, dispatch])

  useEffect(() => {
    dispatch(setName('Основная информация'))
  }, [])

  const handleDateChange = date => {
    if (date) {
      dispatch(setDateVerification(date.format('YYYY-MM-DD HH:mm:ss')))
    }
  }

  const noOptionsMessageCases = methodId ? 'Комплекты СП не найдены' : 'Выберите методику поверки'

  return (
    <React.Fragment>
      {userRole === 'super' && (
        <div
          className='flexContainerWithGap'
          style={
            width <= 1050 ? { marginTop: '0px', marginBottom: '12px' } : { marginBottom: '13px' }
          }
        >
          <div
            className='width50Percent'
            style={{ display: 'flex', flexFlow: 'column', gap: '8px', width: '100%' }}
          >
            <FilterCompany />
          </div>
        </div>
      )}
      <div className='flexContainerWithGap' style={width <= 1050 ? { margin: '0px' } : {}}>
        <div
          className='width32Percent'
          style={{
            display: 'flex',
            flexFlow: 'column',
            gap: '8px',
          }}
        >
          <span style={{ fontSize: '15px' }}>Область измерения</span>
          <Selector
            placeholder={'Область измерения'}
            onChange={getValueMeasurement}
            options={optionsMeasurement}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Выберите область измерения'}
            value={
              measurementId
                ? optionsMeasurement.find(option => option.value === measurementId)
                : null
            }
          />
        </div>
        <div className='width32Percent' style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <span style={{ fontSize: '15px' }}>Выберите методику</span>
          <Selector
            placeholder={'Методика'}
            onChange={getValueMethod}
            options={optionsMethod}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => 'Выберите область измерения'}
            value={methodId ? optionsMethod.find(option => option.value === methodId) : null}
          />
        </div>

        <div className='width32Percent' style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <span style={{ fontSize: '15px' }}>Выберите комплект</span>
          <Selector
            placeholder={'Комплект СП'}
            onChange={getValueCases}
            options={optionsCases}
            isClearable={false}
            isSearchable={true}
            noOptionsMessage={() => noOptionsMessageCases}
            value={caseId ? optionsCases.find(option => option.value === caseId) : null}
          />
        </div>

        <div
          className='width32Percent'
          style={{
            ...(!caseId && { pointerEvents: 'none', opacity: 0.5 }),
            display: 'flex',
            flexFlow: 'column',
            gap: '8px',
          }}
        >
          <VerificationDate
            dateVerification={dateVerification}
            handleDateChange={handleDateChange}
            availableDates={availableDates}
            isDisabled={!caseId}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Method
