import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types.models.views'

const { Container, Mobile } = css

const Images: React.FC = (): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.ContentRow>
          <h3>Комментарий поверителя</h3>
        </Mobile.ContentRow>
        <Mobile.Paper>
          <Mobile.ContentRow>
            <span>Комментарии отсутствуют</span>
          </Mobile.ContentRow>
        </Mobile.Paper>
        <Mobile.ContentRow>
          <h3>Изображения приборов</h3>
        </Mobile.ContentRow>
        <Mobile.ContentRow style={{ flexWrap: 'wrap', gap: '10px' }}>
          {viewData.files.map(image => (
            <div
              style={{
                display: 'block',
                position: 'relative',
                aspectRatio: '1 / 1',
                backgroundColor: '#DFE2E5',
                width: 'calc(33.33333% - 10px)',
                borderRadius: '10px',
              }}
            ></div>
          ))}

          {viewData.files.length === 0 && (
            <Mobile.Paper>
              <Mobile.ContentRow>
                <span>Изображения отсутствуют</span>
              </Mobile.ContentRow>
            </Mobile.Paper>
          )}
        </Mobile.ContentRow>
      </Container>
    </React.Fragment>
  )
}

export default Images
