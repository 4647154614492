import { styled } from '@mui/material'

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}))

export const ConclusionBlock = styled('div')<{ accepted: boolean }>(({ accepted }) => ({
  padding: '12px',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  border: `1px solid ${accepted ? '#0084E2' : '#FF5963'}`,
  background: accepted ? '#E5F3FC' : '#FFF0F2',
}))

export const CardBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px',
  borderRadius: '12px',
  background: '#fff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}))

export const TwoColumns = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}))

export const HalfCard = styled(CardBlock)(() => ({
  width: '50%',
}))

export const InfoRow = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
