// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import AllProtocols from '../app/pages/Protocols/desktop/ProtocolsIndex.jsx'
import AllProtocolsMobile from '../app/pages/Protocols/mobile/ProtocolsIndex.jsx'

import EditProtocol from '../app/pages/Protocols/desktop/EditProtocol.jsx'
import CopyProtocol from '../app/pages/Protocols/desktop/CopyProtocol.jsx'
import ShowMobile from '../app/pages/Protocols/mobile/ShowProtocol.tsx'
import Show from '../app/pages/Protocols/desktop/ShowProtocol.tsx'
import Save from '../app/pages/Protocols/Save'

import NotForMobile from '../app/pages/NotForMobile'
import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteProtocols: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route path='' element={width > 1050 ? <AllProtocols /> : <AllProtocolsMobile />} />
      <Route path=':protocol_id' element={width > 1050 ? <Show /> : <ShowMobile />} />
      <Route
        path=':protocol_id/copy'
        element={width > 1050 ? <CopyProtocol /> : <NotForMobile />}
      />
      <Route
        path=':protocol_id/edit'
        element={width > 1050 ? <EditProtocol /> : <NotForMobile />}
      />

      <Route path='save' element={<Save />} />
    </Routes>
  )
}

export default RouteProtocols
