// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import css from '../../style.css'

import { IModelProtocolMi1592MobileShow } from '../../../../../../types.models.views'

const { Container, Mobile } = css

const VerificationResult: React.FC<{ success: boolean }> = ({ success }): ReactElement => {
  const viewData: IModelProtocolMi1592MobileShow = useSelector(
    (state: any) => state.showProtocolReducer.data,
  )

  return (
    <React.Fragment>
      <Container>
        <Mobile.BlueRedResultsContainer {...({ success } as any)}>
          <h2 style={{ color: success ? '#0084e2' : '#F00' }}>Заключение</h2>
          <span>
            {success === true
              ? 'На основании результатов поверки средство измерения признано пригодным к использованию'
              : 'На основании результатов поверки средство измерения признано непригодным к использованию'}
          </span>
        </Mobile.BlueRedResultsContainer>
      </Container>
      <Container>
        <Mobile.Paper>
          <h3>Рамки применения методики</h3>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Методика</span>
            <span style={{ width: '60%', color: '#858585' }}>{viewData.method.name}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Применяемые пункты или разделы</span>
            <span style={{ width: '60%', color: '#858585' }}>{viewData.point.name}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Тип поверки</span>
            <span style={{ width: '60%', color: '#858585' }}>
              {viewData.point.verification_type}
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Способ проведения</span>
            <span style={{ width: '60%', color: '#858585' }}>
              {viewData.point.verification_method}
            </span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper>
          <h3>Даты поверки</h3>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Поверка проведена</span>
            <span style={{ width: '60%', color: '#858585' }}>{viewData.verification_date}</span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '40%' }}>Следующая поверка</span>
            <span style={{ width: '60%', color: '#858585' }}>
              {viewData.next_verification_date}
            </span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
      <Container>
        <Mobile.Paper>
          <h3>Знаки поверки</h3>
          <Mobile.ContentRow>
            <span style={{ width: '60%' }}>Знак поверки в паспорте</span>
            <span style={{ width: '40%', color: '#858585' }}>
              {viewData.mark_passport === true ? 'Есть' : 'Нет'}
            </span>
          </Mobile.ContentRow>
          <Mobile.ContentRow>
            <span style={{ width: '60%' }}>Знак поверки на СИ</span>
            <span style={{ width: '40%', color: '#858585' }}>
              {viewData.mark_si === true ? 'Есть' : 'Нет'}
            </span>
          </Mobile.ContentRow>
        </Mobile.Paper>
      </Container>
    </React.Fragment>
  )
}

export default VerificationResult
