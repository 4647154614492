import React, { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import JournalsSettings from '../app/pages/Journals/JournalsSettings'
import Settings from '../admin/Settings.tsx'
import NotForMobile from '../app/pages/NotForMobile'

import useWindowDimensions from '../app/hooks/useWindowDimensions'

const RouteSettings: React.FC = (): ReactElement => {
  const { width } = useWindowDimensions()

  return (
    <Routes>
      <Route path='journals' element={width > 1050 ? <JournalsSettings /> : <NotForMobile />} />
      <Route
        path='tarrifs'
        element={width > 1050 ? <Settings view='tarrifs' /> : <NotForMobile />}
      />
      <Route
        path='notifications'
        element={width > 1050 ? <Settings view='notifications' /> : <NotForMobile />}
      />
    </Routes>
  )
}

export default RouteSettings
