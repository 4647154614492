import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { changeData } from '../../../store/slices/app/views/cases'

import R from '../../../services/app/client-server/request.service.ts'

import { LabelFrame } from '../../pages/Cases/style'

const Methods = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const measurementId = useSelector(state => state.casesReducer.createData.measurementId)
  const methodId = useSelector(state => state.casesReducer.createData.methodId)

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: 'methodId',
          label: selectedOption.value,
        }),
      )
      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        }),
      )
      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        }),
      )
      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )
      dispatch(changeData({ value: 'deviceIds', label: [] }))
    } else {
      dispatch(
        changeData({
          value: 'methodId',
          label: '',
        }),
      )
      dispatch(
        changeData({
          value: 'methodPlaces',
          label: [],
        }),
      )
      dispatch(
        changeData({
          value: 'typeMethodId',
          label: [],
        }),
      )
      dispatch(
        changeData({
          value: 'methodPoints',
          label: [],
        }),
      )
      dispatch(changeData({ value: 'deviceIds', label: [] }))
    }
  }

  useEffect(() => {
    if (measurementId) {
      ;(async () => {
        const { data: listMethod } = await R.getMethodItem(`?measurement_id=${measurementId}`)
        const method = listMethod.data
        setData(method)
      })()
    }
  }, [measurementId])

  return (
    <LabelFrame>
      <Selector
        placeholder={'Выберите методику поверки из списка'}
        label={{
          text: 'Методика поверки',
          topBg: 'white',
          bottomBg: 'white',
        }}
        isClearable={true}
        isSearchable={true}
        options={options}
        onChange={getValue}
        noOptionsMessage={() => 'Выберите область измерения'}
        value={methodId ? options.find(option => option.value === methodId) : null}
        isRequired={true}
      />
    </LabelFrame>
  )
}

export default Methods
