// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import R from '../../services/app/client-server/request.service.ts'
import { useDispatch, useSelector } from 'react-redux'
import {
  setList,
  setMeta,
  setIsLoading as setIsLoadingCompanies,
} from '../../store/slices/app/views/companies'
import {
  setList as setListCases,
  setMeta as setMetaCases,
  setIsLoading as setIsLoadingCases,
} from '../../store/slices/app/views/cases'
import {
  setList as setListMasterArshin,
  setMeta as setMetaMasterArshin,
  setIsLoading as setIsLoadingMasterArshin,
} from '../../store/slices/app/views/masterArshin'
import {
  setList as setListMeters,
  setMeta as setMetaMeters,
  setIsLoading as setIsLoadingMeters,
  changeFilterParams,
} from '../../store/slices/app/views/meters'
import {
  setList as setListUsers,
  setMeta as setMetaUsers,
  setIsLoading as setIsLoadingUser,
} from '../../store/slices/app/views/users'
import {
  setList as setListProtocols,
  setMeta as setMetaProtocols,
  setIsLoading as setIsLoadingProtocol,
} from '../../store/slices/app/views/protocols'

import {
  setDataVerifications,
  setMetaVerifications,
  setIsLoadingVerifications,
  setDataTickets,
  setMetaTickets,
  setIsLoadingTickets,
  setDataKitVerifications,
  setDataKitPassports,
  setDataConditions,
  setMetaConditions,
  setIsLoadingConditions,
  setDataIssueCase,
  setIsLoadingIssueCase,
  setDataComplect,
  setMetaComplect,
  setIsLoadingComplect,
  setDataReceived,
  setMetaReceived,
  setIsLoadingReceived,
  setDataArchive,
  setMetaArchive,
  setIsLoadingArchive,
} from '../../store/slices/app/views/journals'

import {
  setDataEtalon,
  setMetaEtalon,
  setIsLoading as setIsLoadingEtalon,
} from '../../store/slices/app/views/etalon'

import {
  setLogo,
  setRole,
  setSurName,
  setName,
  setIsTrusted,
  setCompanyId,
} from '../../store/slices/app/controlers/headerData'
import { formControlClasses } from '@mui/material'

const useUpdate = () => {
  const dispatch = useDispatch()

  const entryId = useSelector(state => state.journalReducer.entryId)
  const userRole = useSelector(state => state.headerReducer.role)
  const surname = useSelector(state => state.headerReducer.surname)

  const updateCompanies = async searchQuery => {
    dispatch(setIsLoadingCompanies(true))
    const { status: statusCompanies, data: dataCompanies } = await R.getCompanies(
      `?search=${searchQuery}&limit=10000`,
    )
    dispatch(setIsLoadingCompanies(false))
    dispatch(setList(dataCompanies.data))
    dispatch(setMeta(dataCompanies.meta))

    false && false && console.log(dataCompanies)
  }
  const updateCases = async (searchQuery, companyId) => {
    dispatch(setIsLoadingCases(true))
    const { status: statusCases, data: dataCases } = await R.getCases(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`,
    )
    dispatch(setIsLoadingCases(false))
    dispatch(setListCases(dataCases.data))
    dispatch(setMetaCases(dataCases.meta))

    false && false && console.log(dataCases)
  }
  const updateMasterArhin = async companyId => {
    dispatch(setIsLoadingMasterArshin(true))
    const { status: statusCases, data: dataMasterArshin } = await R.getMasterArshin(
      `?company_id=${companyId}&limit=10000`,
    )
    dispatch(setIsLoadingMasterArshin(false))
    dispatch(setListMasterArshin(dataMasterArshin.data))
    dispatch(setMetaMasterArshin(dataMasterArshin.meta))

    false && false && console.log(dataMasterArshin)
  }
  const updateVerifications = async companyId => {
    dispatch(setIsLoadingVerifications(true))
    const { status: statusVerifications, data: dataVerifications } = await R.getVerifications(
      `?company_id=${companyId}&limit=10000`,
    )
    dispatch(setIsLoadingVerifications(false))
    dispatch(setDataVerifications(dataVerifications.data))
    dispatch(setMetaVerifications(dataVerifications.meta))

    false && false && console.log(dataVerifications)
  }
  const updateTickets = async (
    companyId,
    searchQueryJournal,
    measurementIdTickets,
    arshinNameTickets,
    isCheckedTickets,
    completenessStatusTickets,
    createdByRoleTickets,
    createdAtFromTickets,
    createdAtToTickets,
    verificationDateFrom,
    verificationDateTo,
  ) => {
    if (!!!entryId) {
      dispatch(setIsLoadingTickets(true))

      const { data: dataTickets } = await R.getTickets(
        companyId,
        `?&search=${searchQueryJournal}&measurement_id=${measurementIdTickets}&arshin_name=${arshinNameTickets}&is_checked=${isCheckedTickets}&completeness_status=${completenessStatusTickets}&created_by_role=${createdByRoleTickets}&created_at_from=${createdAtFromTickets}&created_at_to=${createdAtToTickets}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&limit=10000`,
      )
      dispatch(setIsLoadingTickets(false))
      dispatch(setDataTickets(dataTickets.data))
      dispatch(setMetaTickets(dataTickets.meta))
    }
  }

  const updateKitVerifications = async (
    companyId,
    searchQueryJournal,
    isReturnedKitVerifications,
    completenessStatusKitVerifications,
    createdByRoleKitVerifications,
    verificationDateFromKitVerifications,
    verificationDateToKitVerifications,
    createdAtFrom,
    createdAtTo,
    expirationDateFrom,
    expirationDateTo,
  ) => {
    if (!!!entryId) {
      const { data: kitVerifications } = await R.getKitVerifications(
        companyId,
        `?search=${searchQueryJournal}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFromKitVerifications}&verification_date_to=${verificationDateToKitVerifications}&expiration_date_from=${expirationDateFrom}&expiration_date_to=${expirationDateTo}&new_verification_status=${isReturnedKitVerifications}&created_by_role=${createdByRoleKitVerifications}&limit=10000`,
      )
      dispatch(setDataKitVerifications(kitVerifications.data))

      false && false && console.log(kitVerifications)
    }
  }

  const updateKitPassport = async (
    companyId,
    searchQueryJournal,
    systemExistingStatus,
    responsiblePassportName,
    formedDateFrom,
    formedDateTo,
  ) => {
    const { data: kitPassport } = await R.getKitPassports(
      companyId,
      `?search=${searchQueryJournal}&formed_date_from=${formedDateFrom}&formed_date_to=${formedDateTo}&system_existing_status=${systemExistingStatus}&responsible_passport_name=${responsiblePassportName}&limit=10000`,
    )
    dispatch(setDataKitPassports(kitPassport.data))
    false && false && console.log(kitPassport)
  }

  const updateConditions = async (
    companyId,
    searchQueryJournal,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
  ) => {
    if (!!!entryId) {
      dispatch(setIsLoadingConditions(true))

      const { data: dataConditions } = await R.getConditions(
        companyId,
        `?search=${searchQueryJournal}&completeness_status=${completeJournal}&condition_date_from=${dateFromJournal}&condition_date_to=${dateUntilJournal}&limit=10000`,
      )
      dispatch(setIsLoadingConditions(false))
      dispatch(setDataConditions(dataConditions.data))
      dispatch(setMetaConditions(dataConditions.meta))

      false && false && console.log(dataConditions)
    }
  }

  const updateIssueCase = async (
    companyId,
    searchQueryJournal,
    measurementId,
    innerName,
    issuedDateFrom,
    issuedDateTo,
    receivedByName,
    isReturned,
    completenessStatus,
    createdByRole,
    verificationDateFromIssue,
    verificationDateToIssue,
  ) => {
    if (!!!entryId) {
      dispatch(setIsLoadingIssueCase(true))

      const { data: dataIssueCase } = await R.getIssueCase(
        companyId,
        `?search=${searchQueryJournal}&measurement_id=${measurementId}&inner_name=${innerName}&issued_date_from=${issuedDateFrom}&issued_date_to=${issuedDateTo}&received_by_name=${receivedByName}&is_returned=${isReturned}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&limit=10000`,
      )
      dispatch(setIsLoadingIssueCase(false))
      dispatch(setDataIssueCase(dataIssueCase.data))

      false && false && console.log(dataIssueCase)
    }
  }

  const updateComplect = async (companyId, verifiedAtFormatted, expiredAtFormatted) => {
    if (!!!entryId) {
      dispatch(setIsLoadingComplect(true))
      const { data: dataComplect } = await R.getComplect(
        `?company_id=${companyId}&issue_at_start=${verifiedAtFormatted}&issue_at_end=${expiredAtFormatted}&limit=10000`,
      )
      dispatch(setIsLoadingComplect(false))
      dispatch(setDataComplect(dataComplect.data))
      dispatch(setMetaComplect(dataComplect.meta))

      false && false && console.log(dataComplect)
    }
  }

  const updateReceived = async (
    companyId,
    searchQueryJournal,
    measurementIdFilter,
    masterArshine,
    totalAcceptedJournal,
    typeMethodJournal,
    completeJournal,
    creatorJournal,
    dateFromJournal,
    dateUntilJournal,
    createDateFromJournal,
    createDateUntilJournal,
  ) => {
    if (!!!entryId) {
      dispatch(setIsLoadingReceived(true))

      const { data: dataReceived } = await R.getReceivedJournals(
        companyId,
        `?search=${searchQueryJournal}&measurement_id=${measurementIdFilter}&arshin_master_name=${masterArshine}&total_accepted_status=${totalAcceptedJournal}&verification_method=${typeMethodJournal}&completeness_status=${completeJournal}&created_by_role=${creatorJournal}&verification_date_from=${dateFromJournal}&verification_date_to=${dateUntilJournal}&created_at_from=${createDateFromJournal}&created_at_to=${createDateUntilJournal}&limit=10000`,
      )
      dispatch(setIsLoadingReceived(false))
      dispatch(setDataReceived(dataReceived.data))
      dispatch(setMetaReceived(dataReceived.meta))

      false && false && console.log(dataReceived)
    }
  }

  const updateArchive = async (
    companyId,
    searchQueryJournal = '',
    archiveFromDate = '',
    archiveToDate = '',
    journalId = '',
    archiveFromPerson = '',
    complete = '',
    archive = '',
  ) => {
    dispatch(setIsLoadingArchive(true))
    const { data: dataArchive } = await R.getArchiveJournals(
      companyId,
      `?search=${searchQueryJournal}&entry_date_from=${archiveFromDate}&entry_date_to=${archiveToDate}&journal_type_id=${journalId}&created_by_name=${archiveFromPerson}&completeness_status=${complete}&deletion_status=${archive}&limit=10000`,
    )
    dispatch(setIsLoadingArchive(false))
    dispatch(setDataArchive(dataArchive.data))
    dispatch(setMetaArchive(dataArchive.meta))

    false && false && console.log(dataArchive)
  }

  const updateEntriesArchive = async (
    companyId,
    journalId,
    entryId,

    searchQueryJournal = '',

    completeJournal = '',
    dateFromJournal = '',
    dateUntilJournal = '',

    measurementIdFilter = '',
    masterArshine = '',
    totalAcceptedJournal = '',
    typeMethodJournal = '',
    creatorJournal = '',
    createDateFromJournal = '',
    createDateUntilJournal = '',

    measurementIdTickets = '',
    arshinNameTickets = '',
    isCheckedTickets = '',
    completenessStatusTickets = '',
    createdByRoleTickets = '',
    createdAtFromTickets = '',
    createdAtToTickets = '',
    verificationDateFrom = '',
    verificationDateTo = '',

    measurementId = 1,
    innerName = '',
    issuedDateFrom = '',
    issuedDateTo = '',
    receivedByName = '',
    isReturned = '',
    completenessStatus = '',
    createdByRole = '',

    createdAtFrom = '',
    createdAtTo = '',
    verificationDateFromKitVerifications = '',
    verificationDateToKitVerifications = '',
    expirationDateFrom = '',
    expirationDateTo = '',
    isReturnedKitVerifications = '',
    createdByRoleKitVerifications = '',

    formedDateFrom = '',
    formedDateTo = '',
    systemExistingStatus = '',
    responsiblePassportName = '',
  ) => {
    if (journalId === 1) {
    }
    if (journalId === 2) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?search=${searchQueryJournal}&completeness_status=${completeJournal}&condition_date_from=${dateFromJournal}&condition_date_to=${dateUntilJournal}&limit=10000`,
        )

      dispatch(setDataConditions(dataEntriesArchive.data))
      dispatch(setMetaConditions(dataEntriesArchive.meta))
      dispatch(setIsLoadingConditions(false))

      false && false && console.log(dataEntriesArchive)
    }
    if (journalId === 3) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?search=${searchQueryJournal}&measurement_id=${measurementIdFilter}&arshin_master_name=${masterArshine}&total_accepted_status=${totalAcceptedJournal}&verification_method=${typeMethodJournal}&completeness_status=${completeJournal}&created_by_role=${creatorJournal}&verification_date_from=${dateFromJournal}&verification_date_to=${dateUntilJournal}&created_at_from=${createDateFromJournal}&created_at_to=${createDateUntilJournal}&limit=10000`,
        )

      false && console.log(dataEntriesArchive)

      dispatch(setDataReceived(dataEntriesArchive.data))
      dispatch(setMetaReceived(dataEntriesArchive.meta))
      dispatch(setIsLoadingReceived(false))

      false && false && console.log(dataEntriesArchive)
    }

    if (journalId === 4) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?&search=${searchQueryJournal}&measurement_id=${measurementIdTickets}&arshin_name=${arshinNameTickets}&is_checked=${isCheckedTickets}&completeness_status=${completenessStatusTickets}&created_by_role=${createdByRoleTickets}&created_at_from=${createdAtFromTickets}&created_at_to=${createdAtToTickets}&planned_date_from=${verificationDateFrom}&planned_date_to=${verificationDateTo}&limit=10000`,
        )

      dispatch(setDataTickets(dataEntriesArchive.data))
      dispatch(setMetaTickets(dataEntriesArchive.meta))
      dispatch(setIsLoadingTickets(false))

      false && false && console.log(dataEntriesArchive)
    }
    if (journalId === 5) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?search=${searchQueryJournal}&measurement_id=${measurementId}&inner_name=${innerName}&issued_date_from=${issuedDateFrom}&issued_date_to=${issuedDateTo}&received_by_name=${receivedByName}&is_returned=${isReturned}&completeness_status=${completenessStatus}&created_by_role=${createdByRole}&limit=10000`,
        )

      dispatch(setDataIssueCase(dataEntriesArchive.data))
      dispatch(setIsLoadingCases(false))

      false && false && console.log(dataEntriesArchive)
    }
    if (journalId === 7) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?search=${searchQueryJournal}&created_at_from=${createdAtFrom}&created_at_to=${createdAtTo}&verification_date_from=${verificationDateFromKitVerifications}&verification_date_to=${verificationDateToKitVerifications}&expiration_date_from=${expirationDateFrom}&expiration_date_to=${expirationDateTo}&new_verification_status=${isReturnedKitVerifications}&created_by_role=${createdByRoleKitVerifications}&limit=10000`,
        )

      dispatch(setDataKitVerifications(dataEntriesArchive.data))
      dispatch(setIsLoadingCases(false))

      false && false && console.log(dataEntriesArchive)
    }
    if (journalId === 8) {
      const { status: statusEntriesArchive, data: dataEntriesArchive } =
        await R.getArchiveEntriesJournals(
          companyId,
          entryId,
          `?search=${searchQueryJournal}&formed_date_from=${formedDateFrom}&formed_date_to=${formedDateTo}&system_existing_status=${systemExistingStatus}&responsible_passport_name=${responsiblePassportName}&limit=10000`,
        )

      dispatch(setDataKitPassports(dataEntriesArchive.data))
      dispatch(setIsLoadingCases(false))

      false && false && console.log(dataEntriesArchive)
    }
  }

  const updateEtalon = async (searchQuery, companyId) => {
    dispatch(setIsLoadingEtalon(true))
    const { status: statusEtalon, data: dataEtalon } = await R.getEtalon(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`,
    )
    dispatch(setIsLoadingEtalon(false))
    dispatch(setDataEtalon(dataEtalon.data))
    dispatch(setMetaEtalon(dataEtalon.meta))

    false && false && console.log(dataEtalon)
  }

  const updateMeters = async (measurement_id, search_query) => {
    dispatch(setIsLoadingMeters(true))
    const { status: statusMeters, data: dataMeters } = await R.getMetersListWithParams(
      measurement_id,
      search_query,
    )

    dispatch(setIsLoadingMeters(false))
    dispatch(setListMeters(dataMeters.data))
    dispatch(setMetaMeters(dataMeters.meta))

    dispatch(
      changeFilterParams({
        value: 'methods',
        label: [{ value: 1, label: 'МИ 1592-2015' }],
      }),
    )
    dispatch(
      changeFilterParams({
        value: 'titles',
        label: [
          ...new Set(
            dataMeters.data.map(meter => {
              return meter.name_si
            }),
          ),
        ].map(item => ({ value: item, label: item })),
      }),
    )
    dispatch(
      changeFilterParams({
        value: 'customers',
        label: [
          ...new Set(
            dataMeters.data.map(meter => {
              return meter.manufacturer_name
            }),
          ),
        ].map(item => ({ value: item, label: item })),
      }),
    )

    false && console.log(dataMeters)
  }

  const updateUsers = async (searchQuery, companyId) => {
    dispatch(setIsLoadingUser(true))
    const { status: statusUsers, data: dataUsers } = await R.getUsers(
      `?search=${searchQuery}&company_id=${companyId}&limit=10000`,
    )
    dispatch(setIsLoadingUser(false))
    dispatch(setListUsers(dataUsers.data))
    dispatch(setMetaUsers(dataUsers.meta))

    false && false && console.log(dataUsers)
  }

  const updateProtocols = async (
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ) => {
    dispatch(setIsLoadingProtocol(true))

    const formattedDateFrom = dateFrom === 'Invalid Date' || dateFrom === null ? '' : dateFrom
    const formattedDateTo = dateTo === 'Invalid Date' || dateTo === null ? '' : dateTo

    const { status: statusProtocols, data: dataProtocols } = await R.getProtocolsList(
      `?company_id=${companyId}&search=${protocolSearchQuery}&measurement_id=${measurementIdFilter}&created_by=${userId}&total_accepted=${statusId}&status=${approvedTransfer}&status_arshin=${approvedArshin}&status_fsa=${approvedFsa}&verification_date_from=${formattedDateFrom}&verification_date_to=${formattedDateTo}&limit=10000`,
    )

    dispatch(setIsLoadingProtocol(false))
    dispatch(setListProtocols(dataProtocols.data))
    dispatch(setMetaProtocols(dataProtocols.meta))
  }

  const updateHeaderData = async () => {
    const { status: statusHeaderData, data: headerData } = await R.getHeaderData()

    const logoPath = headerData.data.company.logo ? headerData.data.company.logo.path : null
    dispatch(setLogo(logoPath))
    dispatch(setRole(headerData.data.role))
    dispatch(setSurName(headerData.data.surname))
    dispatch(setName(headerData.data.name))
    dispatch(setIsTrusted(headerData.data.is_trusted))
    dispatch(setCompanyId(headerData.data.company_id))

    false && false && console.log(headerData)
  }

  return [
    updateCompanies,
    updateCases,
    updateMasterArhin,
    updateVerifications,
    updateTickets,
    updateKitVerifications,
    updateKitPassport,
    updateConditions,
    updateIssueCase,
    updateComplect,
    updateReceived,
    updateArchive,
    updateEntriesArchive,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ]
}

export default useUpdate
