// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useCallback, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import ImageUploading from 'react-images-uploading'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DownloadIcon from '../../../../../img/icon/DownloadIcon'
import DeleteIcon from '@mui/icons-material/Delete'
import Replace from '../../../../../img/icon/Replace'
import SearchIcon from '../../../../../img/icon/SearchIcon'
import Input from '../../../LocalElements/Input/InputMUI'
import R from '../../../../../services/app/client-server/request.service.ts'
import { changeProperty } from '../../water/Mi-1592-2015/state'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
})

const ImageUpload = props => {
  const { showTitle = true, showInput = true, count = 10 } = props
  const dispatch = useDispatch()
  const maxNumber = count

  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const images = useSelector(state => state.mi15922015Reducer.images)
  const selectedImages = useSelector(state => state.mi15922015Reducer.selectedImages || [])
  const comment = useSelector(state => state.mi15922015Reducer.comment)
  const file = useSelector(state => state.mi15922015Reducer.file)
  const imgBase64 = useSelector(state => state.mi15922015Reducer.imgBase64)
  const fileBase64 = useSelector(state => state.mi15922015Reducer.fileBase64)

  const [openViewer, setOpenViewer] = useState(false)
  const [viewerImage, setViewerImage] = useState(null)
  const [viewerIndex, setViewerIndex] = useState(null)

  const [loading, setLoading] = useState(false)

  const totalImages = imgBase64.length + images.length

  const onChangeImage = imageList => {
    const remainingSlots = maxNumber - imgBase64.length
    const addedImages = imageList.slice(0, remainingSlots)
    const ignoredImagesCount = imageList.length - remainingSlots

    dispatch(
      changeProperty({
        value: 'images',
        label: addedImages.map(image => image.file),
      }),
    )

    if (ignoredImagesCount > 0) {
      dispatch(
        setMessage(
          'Максимальное количество изображений — 10. Изображения, превышающие этот лимит, добавляться не будут.',
        ),
      )
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }

  const handleImageClick = (imageUrl, index) => {
    setViewerImage(imageUrl)
    setViewerIndex(index)
    setOpenViewer(true)
  }

  const handleCommentChange = param => {
    dispatch(changeProperty({ value: 'comment', label: param }))
  }

  const handleCheckboxChange = (index, isBase64 = false) => {
    const identifier = isBase64 ? `base64-${index}` : `image-${index}`
    dispatch(
      changeProperty({
        value: 'selectedImages',
        label: selectedImages.includes(identifier)
          ? selectedImages.filter(i => i !== identifier)
          : [...selectedImages, identifier],
      }),
    )
  }

  const handleSelectAll = () => {
    const allImageIds = [
      ...imgBase64.map((_, index) => `base64-${index}`),
      ...images.map((_, index) => `image-${index}`),
    ]
    dispatch(changeProperty({ value: 'selectedImages', label: allImageIds }))
  }

  const handleDeselectAll = () => {
    dispatch(changeProperty({ value: 'selectedImages', label: [] }))
  }

  const handleRemoveSelectedImages = () => {
    const selectedBase64Indexes = selectedImages
      .filter(id => id.startsWith('base64-'))
      .map(id => parseInt(id.replace('base64-', ''), 10))

    const selectedImageIndexes = selectedImages
      .filter(id => id.startsWith('image-'))
      .map(id => parseInt(id.replace('image-', ''), 10))

    dispatch(
      changeProperty({
        value: 'images',
        label: images.filter((_, index) => !selectedImageIndexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'file',
        label: file.filter((_, index) => !selectedImageIndexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'imgBase64',
        label: imgBase64.filter((_, index) => !selectedBase64Indexes.includes(index)),
      }),
    )

    dispatch(
      changeProperty({
        value: 'fileBase64',
        label: fileBase64.filter((_, index) => !selectedBase64Indexes.includes(index)),
      }),
    )

    dispatch(changeProperty({ value: 'selectedImages', label: [] }))
  }

  const handleSaveFile = useCallback(async () => {
    if (images.length === 0) return
    setLoading(true)

    const fileDetails = []
    const uploadFile = async (file, index) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'protocols')

      const { status, data } = await R.addFiles(formData)

      if (status === 201) {
        if (data.id) {
          fileDetails.push({ id: data.id, serial: index })
        }
      }
    }

    for (let i = 0; i < images.length; i++) {
      await uploadFile(images[i], i)
    }

    dispatch(changeProperty({ value: 'file', label: fileDetails }))

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [images, dispatch])

  useEffect(() => {
    handleSaveFile()
  }, [images, handleSaveFile])

  const handleDrop = event => {
    event.preventDefault()
    event.stopPropagation()

    const files = Array.from(event.dataTransfer.files)
    const remainingSlots = maxNumber - totalImages

    if (files.length > remainingSlots) {
      const addedFiles = files.slice(0, remainingSlots)
      dispatch(changeProperty({ value: 'images', label: [...images, ...addedFiles] }))

      dispatch(
        setMessage(
          'Максимальное количество изображений — 10. Изображения, превышающие этот лимит, добавляться не будут',
        ),
      )
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    } else {
      dispatch(changeProperty({ value: 'images', label: [...images, ...files] }))
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDeleteFromViewer = () => {
    if (viewerIndex == null) return

    const newImages = images.filter((_, i) => i !== viewerIndex)
    dispatch(changeProperty({ value: 'images', label: newImages }))
    setOpenViewer(false)
    setViewerIndex(null)
  }

  const handleReplaceFromViewer = onImageUpdate => {
    if (viewerIndex == null) return
    onImageUpdate(viewerIndex)

    setOpenViewer(false)
    setViewerIndex(null)
  }

  const handleReplaceOneImage = (id, onImageUpdate) => {
    let index = null

    if (id.startsWith('base64-')) {
      index = parseInt(id.replace('base64-', ''), 10)
    } else if (id.startsWith('image-')) {
      index = parseInt(id.replace('image-', ''), 10)
    }

    if (index !== null && !isNaN(index)) {
      onImageUpdate(index)
    }
  }

  return (
    <React.Fragment>
      {showTitle && <h2 style={{ marginBottom: '0px' }}>Дополнительная информация</h2>}
      <div
        style={{
          width: '100%',
          marginBottom: '0px',
          marginTop: '24px',
        }}
      >
        <Input
          type={'text'}
          notRequired={true}
          multiline={true}
          placeholder={'Введите текст сообщения'}
          label={'Сопроводительный комментарий'}
          value={comment}
          actions={{
            change: handleCommentChange,
          }}
          isDisabled={isTrusted}
        />
      </div>

      <ImageUploading
        multiple
        value={images.map(file => ({ file }))}
        onChange={onChangeImage}
        maxFileSize={5242880}
      >
        {({ onImageUpload, onImageUpdate }) => (
          <div style={{ width: '100%' }} onDrop={handleDrop} onDragOver={handleDragOver}>
            <span style={{ display: 'block', marginTop: '14px', fontSize: '16px' }}>
              Изображения
            </span>

            {loading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                  marginBottom: '28px',
                }}
              >
                <CircularProgress />
              </div>
            )}

            {(images.length > 0 || imgBase64.length > 0) && !loading && (
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                }}
              >
                <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
                  <Checkbox
                    checked={selectedImages.length === totalImages && totalImages > 0}
                    indeterminate={selectedImages.length > 0 && selectedImages.length < totalImages}
                    onChange={e => {
                      if (e.target.checked) {
                        handleSelectAll()
                      } else {
                        handleDeselectAll()
                      }
                    }}
                  />
                  <span>Все</span>
                </div>
                <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '6px' }}>
                  {selectedImages.length > 0 && (
                    <Button
                      variant='text'
                      onClick={handleRemoveSelectedImages}
                      startIcon={<DeleteIcon style={{ transform: 'scale(130%)' }} />}
                      style={{
                        textTransform: 'none',
                        fontSize: '15px',
                        fontFamily: '"Wix Madefor Display", sans-serif',
                        borderRadius: '12px',
                        color: '#4B5962',
                      }}
                    >
                      Удалить
                    </Button>
                  )}
                  {selectedImages.length === 1 && (
                    <Button
                      variant='text'
                      onClick={() => handleReplaceOneImage(selectedImages[0], onImageUpdate)}
                      startIcon={<Replace />}
                      style={{
                        textTransform: 'none',
                        fontSize: '15px',
                        fontFamily: '"Wix Madefor Display", sans-serif',
                        borderRadius: '12px',
                        color: '#4B5962',
                      }}
                    >
                      Заменить
                    </Button>
                  )}
                </div>
              </div>
            )}

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
                width: '100%',
                marginTop: '8px',
              }}
            >
              {totalImages < count && !loading && (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    aspectRatio: '1 / 1',
                    border: '1px dashed #D7DDEA',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    gap: '6px',
                  }}
                  onClick={onImageUpload}
                >
                  <svg
                    width='41'
                    height='40'
                    viewBox='0 0 41 40'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M37.1663 21.1637C37.1638 23.613 37.1452 25.6948 37.006 27.403C36.8445 29.3852 36.5135 31.0416 35.7727 32.4173C35.446 33.0241 35.0445 33.5674 34.5556 34.0563C33.1683 35.4436 31.4008 36.072 29.1609 36.3731C26.9726 36.6674 24.1679 36.6673 20.589 36.6673H20.4111C16.8322 36.6673 14.0274 36.6674 11.8391 36.3731C9.59919 36.072 7.83171 35.4436 6.44441 34.0563C5.21452 32.8264 4.57939 31.2956 4.24164 29.3967C3.90985 27.5313 3.84916 25.2104 3.83655 22.3285C3.83334 21.5955 3.83334 20.8202 3.83334 20.0022V19.9117C3.83332 16.3328 3.83331 13.5281 4.12752 11.3397C4.42867 9.09983 5.05711 7.33235 6.44441 5.94505C7.83171 4.55775 9.59919 3.92931 11.8391 3.62817C13.7852 3.36652 16.2891 3.33757 19.3367 3.33438C19.9789 3.33371 20.5 3.85458 20.5 4.49677C20.5 5.13897 19.9787 5.65931 19.3365 5.65999C16.2458 5.66326 13.9459 5.69142 12.149 5.93301C10.1669 6.19949 8.97203 6.7063 8.08884 7.58948C7.20565 8.47267 6.69884 9.66759 6.43237 11.6496C6.1614 13.6651 6.15893 16.3135 6.15893 20.0007C6.15893 20.4509 6.15893 20.8864 6.15945 21.308L7.7116 19.9499C9.12443 18.7137 11.2538 18.7846 12.5812 20.1121L19.232 26.7628C20.2974 27.8283 21.9746 27.9735 23.2074 27.1071L23.6698 26.7822C25.4438 25.5355 27.8439 25.6799 29.4556 27.1304L33.844 31.08C34.2857 30.1524 34.548 28.9335 34.6881 27.2142C34.8201 25.5941 34.8382 23.6265 34.8407 21.1638C34.8414 20.5216 35.3617 20.0007 36.0039 20.0007C36.6461 20.0007 37.1669 20.5215 37.1663 21.1637Z'
                      fill='#0084E2'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M29.6667 18.334C26.1311 18.334 24.3634 18.334 23.265 17.2356C22.1667 16.1373 22.1667 14.3695 22.1667 10.834C22.1667 7.29845 22.1667 5.53068 23.265 4.43233C24.3634 3.33398 26.1311 3.33398 29.6667 3.33398C33.2022 3.33398 34.97 3.33398 36.0683 4.43233C37.1667 5.53068 37.1667 7.29845 37.1667 10.834C37.1667 14.3695 37.1667 16.1373 36.0683 17.2356C34.97 18.334 33.2022 18.334 29.6667 18.334ZM30.9167 7.50065C30.9167 6.81029 30.357 6.25065 29.6667 6.25065C28.9763 6.25065 28.4167 6.81029 28.4167 7.50065V9.58398H26.3333C25.643 9.58398 25.0833 10.1436 25.0833 10.834C25.0833 11.5243 25.643 12.084 26.3333 12.084H28.4167V14.1673C28.4167 14.8577 28.9763 15.4173 29.6667 15.4173C30.357 15.4173 30.9167 14.8577 30.9167 14.1673V12.084H33C33.6904 12.084 34.25 11.5243 34.25 10.834C34.25 10.1436 33.6904 9.58398 33 9.58398H30.9167V7.50065Z'
                      fill='#0084E2'
                    />
                  </svg>
                  <span
                    style={{
                      color: '#0084E2',
                      marginLeft: '5px',
                      cursor: 'pointer',
                      fontSize: '13px',
                    }}
                  >
                    Максимум {count}
                  </span>
                </div>
              )}

              {images.length > 0 && !loading && (
                <React.Fragment>
                  {images.map((image, index) => (
                    <div
                      key={`image-${index}`}
                      style={{
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        aspectRatio: '1 / 1',
                        borderRadius: '10px',
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Checkbox
                        checked={selectedImages.includes(`image-${index}`)}
                        onChange={() => handleCheckboxChange(index, false)}
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        style={{
                          position: 'absolute',
                          background: 'transparent',
                          borderRadius: '8px',
                          top: 4,
                          left: '100%',
                          marginLeft: '-40px',
                        }}
                        {...props}
                      />
                      <img
                        src={URL.createObjectURL(image)}
                        alt='Фото протокола'
                        onClick={() => handleImageClick(URL.createObjectURL(image), index)}
                        style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}
                      />
                      <div style={{ display: 'none', flexDirection: 'column' }}>
                        <Tooltip title='Заменить изображение' placement='top-start'>
                          <IconButton aria-label='replace' onClick={() => onImageUpdate(index)}>
                            <Replace />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Увеличить изображение' placement='top-start'>
                          <IconButton
                            aria-label='zoom'
                            onClick={() => handleImageClick(URL.createObjectURL(image), index)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>

            <Dialog
              open={openViewer}
              onClose={() => setOpenViewer(false)}
              maxWidth='xl'
              fullWidth={true}
            >
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  padding: '10px 10px',
                  gap: '10px',
                }}
              >
                {viewerImage && (
                  <img
                    src={viewerImage}
                    alt='Увеличенное'
                    style={{ maxWidth: '100%', maxHeight: '80%', borderRadius: '8px' }}
                  />
                )}

                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexFlow: 'column',
                    marginRight: '6px',
                  }}
                >
                  <Tooltip title='Заменить изображение'>
                    <div
                      style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '4px' }}
                    >
                      <IconButton
                        aria-label='replace in viewer'
                        onClick={() => handleReplaceFromViewer(onImageUpdate)}
                      >
                        <Replace />
                      </IconButton>
                      <span>Заменить</span>
                    </div>
                  </Tooltip>

                  <Tooltip title='Удалить изображение'>
                    <div
                      style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '4px' }}
                    >
                      <IconButton
                        aria-label='delete in viewer'
                        onClick={handleDeleteFromViewer}
                        style={{ transform: 'scale(110%)' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <span>Удалить</span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Dialog>
          </div>
        )}
      </ImageUploading>
    </React.Fragment>
  )
}

export default ImageUpload
