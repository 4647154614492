// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Typography,
  Skeleton,
  Chip,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import BasicTabsComponent from '../../../components/LocalElements/TabsMenu/TabsMenuVertical.jsx'
import R from '../../../../services/app/client-server/request.service.ts'
import PdfDownloadButton from '../../../../services/app/files/pdf-protocols.service.js'
import { setState } from '../../../../store/slices/app/views/protocolShow.js'
import ExportIcon from '../../../../img/icon/Export.tsx'
import PencilIcon from '../../../../img/icon/PencilIcon.tsx'
import CopyIcon from '../../../../img/icon/Copy.tsx'
import BackButton from '../../../../ui/back-button/index.tsx'

import VerificationResult from '../../../components/Protocol/VerificationResult.tsx'
import ProtocolTransfer from '../../../components/Protocol/ProtocolTransfer.tsx'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar.js'

import { IModelProtocolMi1592MobileShow } from '../../../types.models.views.ts'

interface Tab {
  label: string | React.ReactNode
}

interface BasicTabsProps {
  viewVariant: number
  callback: (index: number) => void
  tabs: Tab[]
}

const BasicTabs = BasicTabsComponent as React.FC<BasicTabsProps>

const Show = () => {
  const { protocol_id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState<IModelProtocolMi1592MobileShow | null>(null)
  const [status, setStatus] = useState<string | undefined>('')
  const [isLoading, seIstLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [viewVariant, setViewVariant] = useState<number>(0)

  const handleCheckProtocol = async () => {
    const requestData = {
      ids: data?.id ? [data.id] : [],
    }

    const { status } = await R.protocolCheck(requestData)

    if (status === 200) {
      dispatch(setMessage('Протокол успешно одобрен для передачи в Аршин и ФСА'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
    }
  }

  const actions = [
    {
      icon: <PencilIcon color='#4B5962' width={24} height={24} />,
      name: 'Редактировать протокол',
      callback: () => navigate(`/metriva/protocols/${protocol_id}/edit`),
      disabled: status === 'sent',
    },
    {
      icon: <CopyIcon color='#4B5962' width={24} height={24} />,
      name: 'Копировать протокол',
      callback: () => navigate(`/metriva/protocols/${protocol_id}/copy`),
    },
    {
      icon: <PdfDownloadButton protocolId={protocol_id} viewType='viewProtocol' />,
      name: 'Скачать PDF',
      callback: () => {},
    },
    {
      icon: <ExportIcon color='#4B5962' width={24} height={24} />,
      name: 'Передать в Аршин и ФСА',
      callback: handleCheckProtocol,
    },
  ]

  useEffect(() => {
    ;(async () => {
      const { data }: any = await R.getProtocol(protocol_id)

      setData(data.data)
      dispatch(setState(data.data))
      setStatus(data.data?.status)
      seIstLoading(false)
    })()
  }, [])

  const getChipProps = () => {
    let label = 'Ждёт отправки'
    let sx = {
      backgroundColor: '#FFF0F2',
      color: '#D7DDEA',
      fontWeight: 500,
      border: '1px solid #D7DDEA',
      '& .MuiChip-label': {
        fontSize: '13px',
      },
    }

    if (data?.status_fsa === 'publish-error') {
      label = 'Ошибка при отправке в Аршин и ФСА'
      sx = {
        backgroundColor: '#FFF0F2',
        color: '#FF5963',
        fontWeight: 500,
        border: '1px solid #FF5963',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (data?.status_fsa === 'published') {
      label = 'Опубликован в Аршин и ФСА'
      sx = {
        backgroundColor: '#C7EDE4',
        color: '#6CC2AE',
        fontWeight: 500,
        border: '1px solid #6CC2AE',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (data?.status_fsa === 'sent') {
      label = 'Передаётся в ФСА'
      sx = {
        backgroundColor: '#FFF0E2',
        color: '#FF9432',
        fontWeight: 500,
        border: '1px solid #FF9432',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (data?.status_arshin === 'publish-error') {
      label = 'Ошибка при отправке в Аршин'
      sx = {
        backgroundColor: '#FFF0F2',
        color: '#FF5963',
        fontWeight: 500,
        border: '1px solid #FF5963',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (data?.status_arshin === 'published') {
      label = 'Опубликован в Аршин'
      sx = {
        backgroundColor: '#C7EDE4',
        color: '#6CC2AE',
        fontWeight: 500,
        border: '1px solid #6CC2AE',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (data?.status_arshin === 'sent') {
      label = 'Передаётся в Аршин'
      sx = {
        backgroundColor: '#FFF0E2',
        color: '#FF9432',
        fontWeight: 500,
        border: '1px solid #FF9432',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (status === 'sent') {
      label = 'Отправка одобрена'
      sx = {
        backgroundColor: '#C7EDE4',
        color: '#6CC2AE',
        fontWeight: 500,
        border: '1px solid #6CC2AE',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    } else if (status !== 'sent') {
      label = 'Ждёт отправки'
      sx = {
        backgroundColor: '#EAEDF3',
        color: '#D7DDEA',
        fontWeight: 500,
        border: '1px solid #D7DDEA',
        '& .MuiChip-label': {
          fontSize: '13px',
        },
      }
    }

    return { label, sx }
  }

  const { label, sx } = getChipProps()

  return (
    <main className='main' style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <BackButton to='/metriva/protocols' margin='0px' label='Назад к списку протоколов' />

      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Skeleton variant='rectangular' height={25} width={740} sx={{ borderRadius: '16px' }} />
          <div style={{ display: 'flex', gap: '40px' }}>
            <Skeleton variant='rectangular' height={25} width={350} sx={{ borderRadius: '16px' }} />
            <Skeleton variant='rectangular' height={25} width={350} sx={{ borderRadius: '16px' }} />
          </div>
          <Skeleton variant='rectangular' height={400} sx={{ borderRadius: '16px' }} />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant='h2'>
              Протокол периодической поверки{data?.arshin_id ? ` №${data.arshin_id}` : ''}
            </Typography>
            <Chip label={label} sx={sx} />
          </div>
          <div style={{ display: 'flex', gap: '40px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Typography variant='subtitle2'>Дата поверки:</Typography>
              <Typography variant='subtitle1'>
                {moment(data?.verification_date).format('DD-MM-YYYY')}
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Typography variant='subtitle2'>Адрес:</Typography>
              <Typography variant='subtitle1'>{data?.customer_address}</Typography>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '36px',
              borderRadius: '12px',
              paddingRight: '16px',
              background: '#fff',
              border: '1px solid #d7ddea',
            }}
          >
            <div
              style={{
                width: '350px',
                display: 'flex',
                flexFlow: 'column',
                gap: '16px',
              }}
            >
              <BasicTabs
                viewVariant={viewVariant}
                callback={setViewVariant}
                tabs={[
                  { label: 'Результаты поверки' },
                  { label: 'Передача протокола' },
                  { label: 'Средства  поверки' },
                  { label: 'Информация о заказчике' },
                  { label: 'Данные о поверяемом приборе' },
                  { label: 'Метрологические и технические характеристики' },
                  { label: 'Внешний осмотр и опробование' },
                  { label: 'Соблюдение условий поверки' },
                  { label: 'Определение относительной погрешности' },
                  { label: 'Дополнительная информация' },
                ]}
              />

              <SpeedDial
                ariaLabel='SpeedDial controlled open example'
                sx={{ position: 'absolute', top: 80, right: 23 }}
                icon={<SpeedDialIcon />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                direction='left'
              >
                {actions.map(action => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.callback}
                  />
                ))}
              </SpeedDial>
            </div>

            <div style={{ width: 'calc(100% - 350px)', paddingTop: '16px' }}>
              {viewVariant === 0 && data && <VerificationResult data={data} />}
              {viewVariant === 1 && data && <ProtocolTransfer data={data} />}
            </div>
          </div>
        </>
      )}
    </main>
  )
}

export default Show
