import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import CardTariff from './CardTariff.tsx'
import Trial from './Trial.tsx'
import FullAccess from './FullAccess.tsx'
import Individual from './Individual.tsx'

import PresentIcon from '../../img/icon/PresentIcon'
import LockIcon from '../../img/icon/LockIcon'
import CoolIcon from '../../img/icon/CoolIcon'

import R from '../../services/app/client-server/request.service.ts'

interface TariffsProps {
  companyData: any
}

const Tariffs: React.FC<TariffsProps> = ({ companyData }) => {
  const tariffSelector = useSelector((state: any) => state.companiesReducer.tariffSelector)
  const statusSelector = useSelector((state: any) => state.companiesReducer.statusSelector)

  const [activeTariff, setActiveTariff] = useState<string | null>(null)

  const [subscription, setSubscription] = useState<any[]>([])
  const [tariffIds, setTariffIds] = useState<number[]>([])
  const [tariffs, setTariffs] = useState<any[]>([])
  const [rates, setRates] = useState<any[]>([])

  const handleDetailsClick = (tariffName: string) => {
    setActiveTariff(activeTariff === tariffName ? null : tariffName)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data: subscriptionService }: any = await R.subscription()
        setSubscription(subscriptionService?.data || [])
      } catch (error) {
        console.error('Ошибка при получении subscription:', error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const { data: tariff }: any = await R.tariffIds()
        const ids = tariff?.data?.map((item: any) => item.id) || []
        setTariffIds(ids)
        setTariffs(tariff.data || [])
      } catch (error) {
        console.error('Ошибка при получении tariffIds:', error)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (tariffIds.length > 0) {
        const tariffsData: any[] = []
        for (let i = 0; i < tariffIds.length; i++) {
          const id = tariffIds[i]
          try {
            const { data: oneTariff }: any = await R.tariffs(id)
            const formattedTariff = oneTariff.data.map((t: any) => ({
              description: t.description,
              title: t.title,
              units: t.units,
              price: t.price,
              tariff_id: t.tariff_id,
              threshold: t.threshold,
              order: t.order,
            }))
            tariffsData.push(...formattedTariff)
          } catch (error) {
            console.error(`Ошибка при запросе тарифа с id ${id}:`, error)
          }
        }

        const ratesWithRequired = tariffsData.map((rate: any) => {
          const matchingTariff = tariffs.find((t: any) => t.id === rate.tariff_id)
          return {
            ...rate,
            is_required: matchingTariff ? matchingTariff.is_required : false,
            post_payment: matchingTariff ? matchingTariff.post_payment : false,
          }
        })

        const sortedRates = ratesWithRequired.sort((a: any, b: any) => a.order - b.order)
        setRates(sortedRates)
      }
    })()
  }, [tariffIds, tariffs])

  useEffect(() => {
    switch (tariffSelector) {
      case 1:
        setActiveTariff('trial')
        break
      case 2:
        setActiveTariff('fullAccess')
        break
      case 3:
        setActiveTariff('individual')
        break
      default:
        setActiveTariff(null)
    }
  }, [tariffSelector])

  return (
    <>
      <div
        className='flexContainerWithGap'
        style={{ marginTop: '26px', gap: '16px', justifyContent: 'center' }}
      >
        <CardTariff
          title='«Пробный период»'
          chip={statusSelector === 'trial' ? 'Активирован' : 'Завершен'}
          icon={<PresentIcon />}
          price='1000,00'
          iconFirstTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          iconSecondTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconThirdTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName='trial'
          isOpen={activeTariff === 'trial'}
          isTariff={companyData?.subscription_id === 1}
        />

        <CardTariff
          title='«Полный доступ»'
          chip={
            statusSelector === 'active'
              ? 'Активирован'
              : statusSelector === 'frozen'
                ? 'Заморожен'
                : 'Не активирован'
          }
          icon={<LockIcon />}
          iconFirstTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconSecondTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconThirdTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName='fullAccess'
          isOpen={activeTariff === 'fullAccess'}
          isTariff={companyData?.subscription_id === 2}
        />

        <CardTariff
          title='«Индивидуальный»'
          chip='Не активирован'
          icon={<CoolIcon />}
          iconFirstTariff={<CancelIcon sx={{ color: '#ff5963', fontSize: '24px' }} />}
          iconSecondTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          iconThirdTariff={<CheckCircleIcon sx={{ color: '#22c58b', fontSize: '24px' }} />}
          changeTariff={handleDetailsClick}
          tariffName='individual'
          isOpen={activeTariff === 'individual'}
          isTariff={companyData?.subscription_id === 3}
        />
      </div>

      <Collapse in={activeTariff === 'trial'} timeout={500} unmountOnExit>
        <Trial companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>

      <Collapse in={activeTariff === 'fullAccess'} timeout={500} unmountOnExit>
        <FullAccess companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>

      <Collapse in={activeTariff === 'individual'} timeout={500} unmountOnExit>
        <Individual companyData={companyData} rates={rates} subscription={subscription} />
      </Collapse>
    </>
  )
}

export default Tariffs
