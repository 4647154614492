import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  vertical: 'center',
  horizontal: 'bottom',
  message: 'Сообщение по умолчанию когда нет сообщений',
  type: 'success',
}

export const snackbarReducer = createSlice({
  name: 'snackbarReducer',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setV: (state, action) => {
      state.vertical = action.payload
    },
    setH: (state, action) => {
      state.horizontal = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
  },
})

export const { setOpen, setV, setH, setMessage, setType } = snackbarReducer.actions
export default snackbarReducer.reducer
