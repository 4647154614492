// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'

import Fade from '@mui/material/Fade'

import protocols from '../../img/menu/protocols.svg'
import magazines from '../../img/menu/magazines.svg'
import users from '../../img/menu/users.svg'
import meters from '../../img/menu/meters.svg'
import etalons from '../../img/menu/etalons.svg'
import cases from '../../img/menu/cases.svg'
import companies from '../../img/menu/companies.svg'

import { setIsToggle } from '../../store/slices/app/controlers/toggle'
import { refreshHeader } from '../../store/slices/app/controlers/updater'

import { resetState } from '../../store/slices/app/comps/modal'
import { resetJournalId } from '../../store/slices/app/views/journals'

const NavBarMobile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [value, setValue] = useState(0)

  const role = useSelector(state => state.headerReducer.role)

  const isToggle = useSelector(state => state.toggleReducer.isToggle)

  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const openMenu = Boolean(anchorElMenu)

  const handleCloseMenu = () => setAnchorElMenu(null)

  const handleSidebarToggle = event => {
    dispatch(setIsToggle(!isToggle))
    setAnchorElMenu(event.currentTarget)
  }

  useEffect(() => {
    dispatch(refreshHeader())
  }, [])

  useEffect(() => {
    const { pathname } = location

    if (pathname.startsWith('/metriva/protocol') && !pathname.includes('protocols')) {
      setValue(0)
    } else if (pathname.includes('/metriva/protocols')) {
      setValue(1)
    } else if (pathname.includes('/metriva/meters')) {
      setValue(2)
    } else if (pathname.includes('/metriva/cases')) {
      setValue(3)
    } else if (pathname.includes('/metriva/journals')) {
      setValue(4)
    } else {
      setValue(-1)
    }
  }, [location])

  const IconContainer = props => {
    const { children } = props

    return (
      <span
        style={{
          height: '24px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          position: 'relative',
          marginBottom: '4px',
        }}
      >
        {children}
      </span>
    )
  }

  return (
    <React.Fragment>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        sx={{
          transition: 'none',
          flexFlow: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          borderTop: '1px solid var(--GreyBlue, #EAEDF3)',
          boxShadow: 'rgba(62, 62, 62, 0.12) 0px 1px 2px, rgba(62, 62, 62, 0.12) 0px 1px 2px',
          padding: '6px 12px 0px',
          height: '64px',
          position: 'relative',
          boxSizing: 'border-box',
          width: '100%',

          '& .MuiBottomNavigationAction-root': {
            transition: 'none',
            padding: 0,
            minWidth: 0,
            flexGrow: 1,

            '& .MuiBottomNavigationAction-label': {
              fontSize: '9px',
              color: '#4f5960',
            },
          },
        }}
      >
        <BottomNavigationAction
          label={`Новый протокол`}
          value={0}
          onClick={() => {
            dispatch(refreshHeader())
            navigate(`/metriva/protocol`)
            handleCloseMenu()
            dispatch(resetState())
            dispatch(resetJournalId())
          }}
          sx={{
            '& .MuiBottomNavigationAction-label': {
              color:
                location.pathname.startsWith('/metriva/protocol') &&
                !location.pathname.includes('protocols')
                  ? 'rgba(0, 132, 226, 1) !important'
                  : '#4f5960',
            },
          }}
          style={{
            padding: '0px 4px',
          }}
          icon={
            <IconContainer>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor:
                    location.pathname.startsWith('/metriva/protocol') &&
                    !location.pathname.includes('protocols') &&
                    'rgba(229, 243, 252, 1)',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M6.5625 1.50952V16.5L11.25 16.5C13.3713 16.5 14.432 16.5 15.091 15.841C15.75 15.182 15.75 14.1213 15.75 12V6C15.75 3.87868 15.75 2.81802 15.091 2.15901C14.432 1.5 13.3713 1.5 11.25 1.5H6.75L6.5625 1.50952ZM8.0625 4.875C8.0625 4.56434 8.31434 4.3125 8.625 4.3125H12.375C12.6857 4.3125 12.9375 4.56434 12.9375 4.875C12.9375 5.18566 12.6857 5.4375 12.375 5.4375H8.625C8.31434 5.4375 8.0625 5.18566 8.0625 4.875ZM8.0625 7.5C8.0625 7.18934 8.31434 6.9375 8.625 6.9375H12.375C12.6857 6.9375 12.9375 7.18934 12.9375 7.5C12.9375 7.81066 12.6857 8.0625 12.375 8.0625H8.625C8.31434 8.0625 8.0625 7.81066 8.0625 7.5ZM2.25052 5.4375C2.25565 3.6857 2.31102 2.757 2.90901 2.15901C3.41525 1.65277 4.1585 1.53542 5.4375 1.50821V16.4918C4.1585 16.4646 3.41525 16.3472 2.90901 15.841C2.31102 15.243 2.25565 14.3143 2.25052 12.5625H3C3.31066 12.5625 3.5625 12.3107 3.5625 12C3.5625 11.6893 3.31066 11.4375 3 11.4375H2.25V9.5625H3C3.31066 9.5625 3.5625 9.31066 3.5625 9C3.5625 8.68934 3.31066 8.4375 3 8.4375H2.25V6.5625H3C3.31066 6.5625 3.5625 6.31066 3.5625 6C3.5625 5.68934 3.31066 5.4375 3 5.4375H2.25052ZM2.25052 5.4375H1.5C1.18934 5.4375 0.9375 5.68934 0.9375 6C0.9375 6.31066 1.18934 6.5625 1.5 6.5625H2.25V6C2.25 5.80358 2.25 5.61626 2.25052 5.4375ZM2.25 9.5625H1.5C1.18934 9.5625 0.9375 9.31066 0.9375 9C0.9375 8.68934 1.18934 8.4375 1.5 8.4375H2.25V9.5625ZM2.25 11.4375H1.5C1.18934 11.4375 0.9375 11.6893 0.9375 12C0.9375 12.3107 1.18934 12.5625 1.5 12.5625H2.25052C2.25 12.3837 2.25 12.1964 2.25 12V11.4375Z'
                    fill={
                      location.pathname.startsWith('/metriva/protocol') &&
                      !location.pathname.includes('protocols')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                </svg>
              </span>
            </IconContainer>
          }
        />
        <BottomNavigationAction
          label={`Протоколы`}
          value={1}
          onClick={() => {
            dispatch(refreshHeader())
            navigate(`/metriva/protocols`)
            handleCloseMenu()
            dispatch(resetState())
            dispatch(resetJournalId())
          }}
          sx={{
            '& .MuiBottomNavigationAction-label': {
              color: location.pathname.includes('/metriva/protocols')
                ? 'rgba(0, 132, 226, 1) !important'
                : '#4f5960',
            },
          }}
          style={{
            padding: '0px 4px',
          }}
          icon={
            <IconContainer>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor:
                    location.pathname.includes('/metriva/protocols') && 'rgba(229, 243, 252, 1)',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.3125 7.50026V10.5003C1.3125 12.6216 1.3125 13.6822 1.97151 14.3413C2.13451 14.5043 2.32209 14.6269 2.54421 14.7193C2.53917 14.6859 2.53441 14.6525 2.52991 14.619C2.43739 13.9309 2.43744 13.0716 2.4375 12.0743L2.4375 6.00022L2.4375 5.92616V5.92616C2.43744 4.92886 2.43739 4.06957 2.52991 3.38146C2.53441 3.34799 2.53916 3.31458 2.5442 3.28125C2.32208 3.37359 2.13451 3.49627 1.97151 3.65927C1.3125 4.31828 1.3125 5.37894 1.3125 7.50026Z'
                    fill={
                      location.pathname.includes('/metriva/protocols')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    d='M16.3125 7.50026V10.5003C16.3125 12.6216 16.3125 13.6822 15.6535 14.3413C15.4905 14.5043 15.3029 14.6269 15.0808 14.7193C15.0858 14.6859 15.0906 14.6525 15.0951 14.619C15.1876 13.9309 15.1876 13.0716 15.1875 12.0743V5.92617C15.1876 4.92887 15.1876 4.06958 15.0951 3.38146C15.0906 3.34799 15.0858 3.31458 15.0808 3.28125C15.3029 3.37359 15.4905 3.49627 15.6535 3.65927C16.3125 4.31828 16.3125 5.37894 16.3125 7.50026Z'
                    fill={
                      location.pathname.includes('/metriva/protocols')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M4.22151 2.15901C3.5625 2.81802 3.5625 3.87868 3.5625 6V12C3.5625 14.1213 3.5625 15.182 4.22151 15.841C4.88052 16.5 5.94118 16.5 8.0625 16.5H9.5625C11.6838 16.5 12.7445 16.5 13.4035 15.841C14.0625 15.182 14.0625 14.1213 14.0625 12V6C14.0625 3.87868 14.0625 2.81802 13.4035 2.15901C12.7445 1.5 11.6838 1.5 9.5625 1.5H8.0625C5.94118 1.5 4.88052 1.5 4.22151 2.15901ZM6 12.75C6 12.4393 6.25184 12.1875 6.5625 12.1875H8.8125C9.12316 12.1875 9.375 12.4393 9.375 12.75C9.375 13.0607 9.12316 13.3125 8.8125 13.3125H6.5625C6.25184 13.3125 6 13.0607 6 12.75ZM6.5625 9.1875C6.25184 9.1875 6 9.43934 6 9.75C6 10.0607 6.25184 10.3125 6.5625 10.3125H11.0625C11.3732 10.3125 11.625 10.0607 11.625 9.75C11.625 9.43934 11.3732 9.1875 11.0625 9.1875H6.5625ZM6 6.75C6 6.43934 6.25184 6.1875 6.5625 6.1875H11.0625C11.3732 6.1875 11.625 6.43934 11.625 6.75C11.625 7.06066 11.3732 7.3125 11.0625 7.3125H6.5625C6.25184 7.3125 6 7.06066 6 6.75Z'
                    fill={
                      location.pathname.includes('/metriva/protocols')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                </svg>
              </span>
            </IconContainer>
          }
        />
        <BottomNavigationAction
          label={`База приборов`}
          value={2}
          onClick={() => {
            dispatch(refreshHeader())
            dispatch(resetState())
            dispatch(resetJournalId())
            navigate(`/metriva/meters`)
            handleCloseMenu()
          }}
          sx={{
            '& .MuiBottomNavigationAction-label': {
              color: location.pathname.includes('/metriva/meters')
                ? 'rgba(0, 132, 226, 1) !important'
                : '#4f5960',
            },
          }}
          style={{
            padding: '0px 4px',
          }}
          icon={
            <IconContainer>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor:
                    location.pathname.includes('/metriva/meters') && 'rgba(229, 243, 252, 1)',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
              >
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 12 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 12.5C12 14.1569 9.31371 15.5 6 15.5C2.68629 15.5 0 14.1569 0 12.5V9.48047C0.37666 9.94294 0.911212 10.3271 1.5058 10.6244C2.68503 11.214 4.27476 11.5625 6 11.5625C7.72524 11.5625 9.31497 11.214 10.4942 10.6244C11.0888 10.3271 11.6233 9.94295 12 9.48047V12.5Z'
                    fill={
                      location.pathname.includes('/metriva/meters')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    d='M6 7.0625C7.72524 7.0625 9.31497 6.71405 10.4942 6.12444C11.0888 5.82714 11.6233 5.44295 12 4.98047V8C12 8.375 10.6607 9.19333 9.99108 9.61821C8.99874 10.1144 7.58846 10.4375 6 10.4375C4.41154 10.4375 3.00126 10.1144 2.00892 9.61821C1.125 9.17625 0 8.375 0 8V4.98047C0.37666 5.44295 0.911212 5.82714 1.5058 6.12444C2.68503 6.71405 4.27476 7.0625 6 7.0625Z'
                    fill={
                      location.pathname.includes('/metriva/meters')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    d='M9.99108 5.11821C8.99874 5.61438 7.58846 5.9375 6 5.9375C4.41154 5.9375 3.00126 5.61438 2.00892 5.11821C1.63417 4.97139 0.805136 4.51403 0.204744 3.77689C0.0637998 3.60385 0.00315001 3.37792 0.0443883 3.15858C0.0597814 3.07671 0.0789316 2.99961 0.101838 2.9467C0.621116 1.55415 3.06435 0.5 6 0.5C8.93565 0.5 11.3789 1.55415 11.8982 2.9467C11.9211 2.99961 11.9402 3.07671 11.9556 3.15858C11.9969 3.37792 11.9362 3.60385 11.7953 3.77689C11.1949 4.51403 10.3658 4.97139 9.99108 5.11821Z'
                    fill={
                      location.pathname.includes('/metriva/meters')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                </svg>
              </span>
            </IconContainer>
          }
        />
        <BottomNavigationAction
          label={`Комплекты СП`}
          value={3}
          onClick={() => {
            dispatch(refreshHeader())
            navigate(`/metriva/cases`)
            handleCloseMenu()
            dispatch(resetState())
            dispatch(resetJournalId())
          }}
          sx={{
            '& .MuiBottomNavigationAction-label': {
              color: location.pathname.includes('/metriva/cases')
                ? 'rgba(0, 132, 226, 1) !important'
                : '#4f5960',
            },
          }}
          style={{
            padding: '0px 4px',
          }}
          icon={
            <IconContainer>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor:
                    location.pathname.includes('/metriva/cases') && 'rgba(229, 243, 252, 1)',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M7.40831 3.18745C7.64023 2.53127 8.26606 2.0625 8.99993 2.0625C9.7338 2.0625 10.3596 2.53127 10.5916 3.18745C10.6951 3.48035 11.0165 3.63387 11.3094 3.53035C11.6023 3.42682 11.7558 3.10545 11.6523 2.81255C11.2664 1.72094 10.2254 0.9375 8.99993 0.9375C7.77447 0.9375 6.73344 1.72094 6.34761 2.81255C6.24409 3.10545 6.39761 3.42682 6.69051 3.53035C6.98341 3.63387 7.30478 3.48035 7.40831 3.18745Z'
                    fill={
                      location.pathname.includes('/metriva/cases')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    d='M2.06041 6.06824C2.0204 6.04224 1.97833 6.02197 1.93515 6.00724C2.05001 5.76903 2.19518 5.56218 2.37868 5.37868C3.25736 4.5 4.67157 4.5 7.5 4.5H10.5C13.3284 4.5 14.7426 4.5 15.6213 5.37868C15.8048 5.5622 15.95 5.76907 16.0649 6.00731C16.0218 6.02204 15.9798 6.04228 15.9398 6.06824C14.3645 7.09219 13.3748 7.73264 12.5524 8.14347C12.5026 7.88375 12.2742 7.6875 12 7.6875C11.6893 7.6875 11.4375 7.93934 11.4375 8.25V8.59355C9.84791 9.0732 8.15207 9.07317 6.5625 8.59347V8.25C6.5625 7.93934 6.31066 7.6875 6 7.6875C5.7258 7.6875 5.49743 7.88369 5.4476 8.14336C4.62526 7.73252 3.63558 7.0921 2.06041 6.06824Z'
                    fill={
                      location.pathname.includes('/metriva/cases')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                  <path
                    d='M1.5 10.5C1.5 9.03715 1.5 7.95259 1.62156 7.12476C3.32907 8.23465 4.42348 8.94249 5.4375 9.37841V9.75C5.4375 10.0607 5.68934 10.3125 6 10.3125C6.30654 10.3125 6.55581 10.0673 6.56237 9.76232C8.16008 10.1836 9.83992 10.1836 11.4376 9.76239C11.4442 10.0673 11.6935 10.3125 12 10.3125C12.3107 10.3125 12.5625 10.0607 12.5625 9.75V9.37852C13.5765 8.94263 14.6709 8.23481 16.3785 7.12491C16.5 7.95272 16.5 9.03724 16.5 10.5C16.5 13.3284 16.5 14.7426 15.6213 15.6213C14.7426 16.5 13.3284 16.5 10.5 16.5H7.5C4.67157 16.5 3.25736 16.5 2.37868 15.6213C1.5 14.7426 1.5 13.3284 1.5 10.5Z'
                    fill={
                      location.pathname.includes('/metriva/cases')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                </svg>
              </span>
            </IconContainer>
          }
        />
        <BottomNavigationAction
          label={`Журналы`}
          value={4}
          onClick={() => {
            dispatch(refreshHeader())
            navigate(`/metriva/journals`)
            handleCloseMenu()
            dispatch(resetState())
          }}
          sx={{
            '& .MuiBottomNavigationAction-label': {
              color: location.pathname.includes('/metriva/journals')
                ? 'rgba(0, 132, 226, 1) !important'
                : '#4f5960',
            },
          }}
          style={{
            padding: '0px 4px',
          }}
          icon={
            <IconContainer>
              <span
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  backgroundColor:
                    location.pathname.includes('/metriva/journals') && 'rgba(229, 243, 252, 1)',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M6.5625 1.50952V16.5L11.25 16.5C13.3713 16.5 14.432 16.5 15.091 15.841C15.75 15.182 15.75 14.1213 15.75 12V6C15.75 3.87868 15.75 2.81802 15.091 2.15901C14.432 1.5 13.3713 1.5 11.25 1.5H6.75L6.5625 1.50952ZM8.0625 4.875C8.0625 4.56434 8.31434 4.3125 8.625 4.3125H12.375C12.6857 4.3125 12.9375 4.56434 12.9375 4.875C12.9375 5.18566 12.6857 5.4375 12.375 5.4375H8.625C8.31434 5.4375 8.0625 5.18566 8.0625 4.875ZM8.0625 7.5C8.0625 7.18934 8.31434 6.9375 8.625 6.9375H12.375C12.6857 6.9375 12.9375 7.18934 12.9375 7.5C12.9375 7.81066 12.6857 8.0625 12.375 8.0625H8.625C8.31434 8.0625 8.0625 7.81066 8.0625 7.5ZM2.25052 5.4375C2.25565 3.6857 2.31102 2.757 2.90901 2.15901C3.41525 1.65277 4.1585 1.53542 5.4375 1.50821V16.4918C4.1585 16.4646 3.41525 16.3472 2.90901 15.841C2.31102 15.243 2.25565 14.3143 2.25052 12.5625H3C3.31066 12.5625 3.5625 12.3107 3.5625 12C3.5625 11.6893 3.31066 11.4375 3 11.4375H2.25V9.5625H3C3.31066 9.5625 3.5625 9.31066 3.5625 9C3.5625 8.68934 3.31066 8.4375 3 8.4375H2.25V6.5625H3C3.31066 6.5625 3.5625 6.31066 3.5625 6C3.5625 5.68934 3.31066 5.4375 3 5.4375H2.25052ZM2.25052 5.4375H1.5C1.18934 5.4375 0.9375 5.68934 0.9375 6C0.9375 6.31066 1.18934 6.5625 1.5 6.5625H2.25V6C2.25 5.80358 2.25 5.61626 2.25052 5.4375ZM2.25 9.5625H1.5C1.18934 9.5625 0.9375 9.31066 0.9375 9C0.9375 8.68934 1.18934 8.4375 1.5 8.4375H2.25V9.5625ZM2.25 11.4375H1.5C1.18934 11.4375 0.9375 11.6893 0.9375 12C0.9375 12.3107 1.18934 12.5625 1.5 12.5625H2.25052C2.25 12.3837 2.25 12.1964 2.25 12V11.4375Z'
                    fill={
                      location.pathname.includes('/metriva/journals')
                        ? 'rgba(0, 132, 226, 1)'
                        : '#4B5962'
                    }
                  />
                </svg>
              </span>
            </IconContainer>
          }
        />
      </BottomNavigation>
      {/* <nav
        className='header'
        style={{
          height: '68px',
          paddingLeft: '24px',
          paddingRight: '24px',
          backgroundColor: '#f8f9fc',
          borderBottom: 'none',
          position: 'relative',
        }}
      >
        <Menu
          id='fade-menu'
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          TransitionComponent={Fade}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
              mt: '-42px',
              ml: '-6px',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/protocol`)
              handleCloseMenu()
              dispatch(resetState())
              dispatch(resetJournalId())
            }}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '15px',
              height: '50px',
              paddingRight: '60px',
              display: 'flex',
              flexFlow: 'row',
              marginBottom: '8px',
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgb(248, 249, 252)',
                borderRadius: '50%',
              }}
            >
              <img alt={''} src={magazines} style={{ height: '20px' }} />
            </div>
            <h5>Новый протокол</h5>
          </MenuItem>
        </Menu>

        <div className='toggleLogoContainer'>
          <ToggleButtonGroup
            size='small'
            style={{ zIndex: '1000', marginLeft: '-9px' }}
            aria-label='Small sizes'
            onClick={handleSidebarToggle}
          >
            <ToggleButton style={{ color: '#0084e2', border: 'none' }}>
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </nav> */}
    </React.Fragment>
  )
}

export default NavBarMobile
