// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement } from 'react'

import VerificationResult from '../components/mobile/VerificationResults.tsx'
import ProtocolTransfer from '../components/mobile/ProtocolTransfer.tsx'
import CustomerInfo from '../components/mobile/CustomerInfo.tsx'
import DataOnTheDevice from '../components/mobile/DataOnTheDevice.tsx'
import MetrologicalChars from '../components/mobile/MetrologicalChars.tsx'
import ExternalInspection from '../components/mobile/ExternalInspection.tsx'
import ComplianceWithVerification from '../components/mobile/ComplianceWithVerification.tsx'
import RelativeError from '../components/mobile/RelativeError.tsx'
import VerificationConclusion from '../components/mobile/VerificationConclusion.tsx'
import Images from '../components/mobile/Images.tsx'

const Show: React.FC<{ view: number }> = (props): ReactElement => {
  return (
    <React.Fragment>
      {props.view === 0 && <VerificationResult success={true} />}
      {props.view === 1 && <ProtocolTransfer />}
      {props.view === 2 && <CustomerInfo />}
      {props.view === 3 && <DataOnTheDevice />}
      {props.view === 4 && <MetrologicalChars />}
      {props.view === 5 && <ExternalInspection />}
      {props.view === 6 && <ComplianceWithVerification />}
      {props.view === 7 && <RelativeError />}
      {props.view === 8 && <Images />}
    </React.Fragment>
  )
}

export default Show
