// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse, Typography } from '@mui/material'

import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import Input from '../../../../../../ui/input/index.tsx'
import Tooltip from '../../../../../../ui/tooltip/index.tsx'

import R from '../../../../../../services/app/client-server/request.service.ts'

const MeterData = props => {
  const {
    meterFifNum,
    meterViewStatus,
    modification,
    modifications,
    selectedAdditionals,
    valueAdditional,
    valueAnotherMod,
    is_custom_modification,
    changeMeter,
    meterFactoryNumber,
    meterYear,
    edit = false,
  } = props
  const dispatch = useDispatch()
  const { protocol_id } = useParams()
  const [metersList, setMetersList] = useState([])
  const measurementId = useSelector(state => state.protocolReducer.measurementId)
  const yearlist = [...Array(25)].map((_, index) => (2000 + index).toString()).concat('Не выбрано')

  const translateStatus = status => {
    switch (status) {
      case 'default':
        return ''
      case 'outdated':
        return 'старая версия'
      case 'deleted':
        return 'удален'
      case 'updated':
        return 'изменённая версия'
      default:
        return ''
    }
  }

  const optionsMeter = metersList
    ? metersList.map(item => ({
        value:
          edit && item?.meter_view_status
            ? `${item.fif_number} ${item.meter_view_status}`
            : item.fif_number,
        label:
          edit && item?.meter_view_status && item.meter_view_status !== 'default'
            ? `${item.fif_number} (${translateStatus(item.meter_view_status)}), ${item.name_si}, ${item.manufacturer_name}`
            : `${item.fif_number}, ${item.name_si}, ${item.manufacturer_name}`,
      }))
    : []

  const getValueMeter = selectedOption => {
    if (selectedOption) {
      const [selectedValue, ...statusParts] = selectedOption.value.split(' ')
      const selectedStatus = statusParts.join(' ') || 'default'

      dispatch(changeMeter({ value: 'meterFifNum', label: selectedValue }))
      dispatch(changeMeter({ value: 'meterViewStatus', label: selectedStatus }))

      const resetFields = {
        modification: '',
        selectedModification: null,
        standartSize: '',
        q_min: '',
        q_t: '',
        q_max: '',
        q_nom: '',
        selectedAdditionals: [],
      }

      Object.entries(resetFields).forEach(([key, value]) =>
        dispatch(changeMeter({ value: key, label: value })),
      )
    }
  }

  const optionsMod = modifications
    ? modifications
        .map(item => ({
          value: item.name,
          label: item.name,
        }))
        .concat([
          {
            value: 'Нет модификации',
            label: 'Нет модификации',
          },
          {
            value: 'another',
            label: 'Другая модификация',
          },
        ])
    : []

  const getModification = selectedOption => {
    if (!selectedOption) return

    dispatch(changeMeter({ value: 'modification', label: selectedOption.value }))

    const selectedModification = modifications.find(item => item.name === selectedOption.value)

    if (selectedModification) {
      const { standart_size, q_min, q_max, q_nom, additionals = [] } = selectedModification

      dispatch(
        changeMeter({
          value: 'selectedModification',
          label: selectedModification,
        }),
      )

      dispatch(
        changeMeter({
          value: 'standartSize',
          label: standart_size || '',
        }),
      )

      dispatch(
        changeMeter({
          value: 'q_min',
          label: q_min || '',
        }),
      )

      dispatch(
        changeMeter({
          value: 'q_max',
          label: q_max || '',
        }),
      )

      dispatch(
        changeMeter({
          value: 'q_nom',
          label: q_nom || null,
        }),
      )

      dispatch(
        changeMeter({
          value: 'selectedAdditionals',
          label: additionals.length > 0 ? additionals : [],
        }),
      )
    } else {
      const resetFields = {
        selectedModification: null,
        standartSize: '',
        q_min: '',
        q_t: '',
        q_max: '',
        q_nom: '',
        selectedAdditionals: [],
      }

      Object.entries(resetFields).forEach(([key, value]) =>
        dispatch(changeMeter({ value: key, label: value })),
      )
    }
  }

  const optionsAdditionals = selectedAdditionals
    ? selectedAdditionals.map(item => ({
        value: item.text,
        label: item.text,
      }))
    : []

  const getValueAdditionals = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueAdditional',
          label: selectedOption.value,
        }),
      )
    }
  }

  const handlemodificationChange = param => {
    dispatch(
      changeMeter({
        value: 'valueAnotherMod',
        label: param,
      }),
    )
  }

  useEffect(() => {
    if (modifications.length > 0 && modification) {
      const selectedModification = modifications.find(item => item.name === modification)

      if (selectedModification) {
        const { standart_size, q_min, q_max, q_nom, additionals = [] } = selectedModification

        dispatch(
          changeMeter({
            value: 'selectedModification',
            label: selectedModification,
          }),
        )

        dispatch(
          changeMeter({
            value: 'standartSize',
            label: standart_size || '',
          }),
        )

        dispatch(
          changeMeter({
            value: 'q_min',
            label: q_min || '',
          }),
        )

        dispatch(
          changeMeter({
            value: 'q_max',
            label: q_max || '',
          }),
        )

        dispatch(
          changeMeter({
            value: 'q_nom',
            label: q_nom || null,
          }),
        )

        dispatch(
          changeMeter({
            value: 'selectedAdditionals',
            label: additionals.length > 0 ? additionals : [],
          }),
        )
      }
    }
  }, [modifications, modification, dispatch])

  useEffect(() => {
    if (modification === 'another') {
      dispatch(changeMeter({ value: 'is_custom_modification', label: true }))
    } else {
      dispatch(changeMeter({ value: 'is_custom_modification', label: false }))
    }
  }, [modification, dispatch])

  useEffect(() => {
    if (is_custom_modification) {
      dispatch(changeMeter({ value: 'modification', label: 'another' }))
    }
  }, [is_custom_modification])

  const handleMeterFactoryNumberChange = param => {
    dispatch(
      changeMeter({
        value: 'meterFactoryNumber',
        label: param,
      }),
    )
  }

  const optionsYear = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValueYear = selectedOption => {
    dispatch(
      changeMeter({
        value: 'meterYear',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const formatNumber = value => {
    if (!value) return value
    const num = parseFloat(value)
    return Number.isInteger(num) ? num.toString() : num.toFixed(1)
  }

  useEffect(() => {
    ;(async () => {
      let metersData

      if (edit) {
        const { data: metersListByProtocol } = await R.getMetersListByProtocol(`${protocol_id}`)
        metersData = metersListByProtocol.data
      } else {
        const { data: metersListResponse } = await R.getMetersList(
          `?measurement_id=${measurementId}`,
        )
        metersData = metersListResponse.data
      }

      setMetersList(metersData)

      if (meterFifNum) {
        let selectedData = metersData.find(item => item.fif_number === meterFifNum)

        if (selectedData && meterViewStatus && meterViewStatus !== 'default') {
          selectedData =
            metersData.find(
              item => item.fif_number === meterFifNum && item.meter_view_status === meterViewStatus,
            ) || selectedData
        }

        if (selectedData) {
          dispatch(changeMeter({ value: 'modifications', label: selectedData.modifications || [] }))

          dispatch(
            changeMeter({
              value: 'mpi',
              label: selectedData.mpi,
            }),
          )

          dispatch(
            changeMeter({
              value: 'q_t_formula',
              label: selectedData?.q_t_formula,
            }),
          )

          dispatch(
            changeMeter({
              value: 'q_max_limit_negative',
              label: formatNumber(selectedData?.q_max_limit_negative),
            }),
          )
          dispatch(
            changeMeter({
              value: 'q_max_limit_positive',
              label: formatNumber(selectedData?.q_max_limit_positive),
            }),
          )
          dispatch(
            changeMeter({
              value: 'q_min_limit_negative',
              label: formatNumber(selectedData?.q_min_limit_negative),
            }),
          )
          dispatch(
            changeMeter({
              value: 'q_min_limit_positive',
              label: formatNumber(selectedData?.q_min_limit_positive),
            }),
          )
        }
      }
    })()
  }, [measurementId, protocol_id, edit, meterFifNum, meterViewStatus])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Typography variant='h2'>Данные о поверяемом приборе</Typography>

        {edit && meterViewStatus !== 'default' && (
          <Typography variant='h6' sx={{ color: '#E57004' }}>
            {meterViewStatus === 'outdated' &&
              'Некоторые данные указанного СИ были изменены, новая версия прибора доступна в выпадающем списке'}
            {meterViewStatus === 'deleted' && 'Указанный СИ был удалён из системы'}
            {meterViewStatus === 'updated' &&
              'Данные указанного СИ были изменены пользователем при создании протокола, оригинальная версия СИ доступна в выпадающем списке'}
          </Typography>
        )}
      </div>
      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Selector
            placeholder={'Выберите прибор'}
            options={optionsMeter}
            onChange={getValueMeter}
            value={
              meterFifNum
                ? optionsMeter.find(
                    option => option.value === `${meterFifNum} ${meterViewStatus}`,
                  ) ||
                  optionsMeter.find(option => option.value === meterFifNum) ||
                  null
                : null
            }
            isClearable={false}
            isRequired={true}
            isSearchable={true}
            noOptionsMessage={() => 'Нет доступных приборов'}
            label={{
              text: 'Поверяемый прибор ( № Госреестра, Завод производитель )',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
        <div className={'width50Percent'}>
          {edit && meterViewStatus !== 'default' ? (
            <Tooltip title={'Недоступно для выбранного СИ'} placement={'top-start'}>
              <Typography
                sx={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  padding: '14px 16px',
                  borderRadius: '12px',
                  fontSize: '15px',
                }}
              >
                {modification}
              </Typography>
            </Tooltip>
          ) : (
            <React.Fragment>
              <Selector
                placeholder={'Выберите модификацию'}
                options={optionsMod}
                onChange={getModification}
                isClearable={false}
                isSearchable={false}
                isRequired={true}
                noOptionsMessage={() => 'Выберите поверяемый прибор'}
                value={
                  modification ? optionsMod.find(option => option.value === modification) : null
                }
                label={{
                  text: 'Модификация прибора',
                  topBg: 'white',
                  bottomBg: 'white',
                }}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <Collapse
        in={selectedAdditionals && selectedAdditionals.length > 0}
        timeout={500}
        unmountOnExit
      >
        <div className='width24Point5Percent' style={{ width: '100%' }}>
          <Selector
            placeholder={'Выберите доп. условие'}
            options={optionsAdditionals}
            onChange={getValueAdditionals}
            isClearable={false}
            isSearchable={false}
            isRequired={true}
            value={
              selectedAdditionals
                ? !!optionsAdditionals.find(option => option.value === valueAdditional)
                  ? optionsAdditionals.find(option => option.value === valueAdditional)
                  : null
                : null
            }
            label={{
              text: 'Дополнительное условие',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
      </Collapse>

      <Collapse in={modification === 'another'} timeout={500} unmountOnExit>
        <div className='width50Percent'>
          <Input
            type={'text'}
            placeholder={'Введите модификацию прибора'}
            value={valueAnotherMod}
            actions={{
              change: handlemodificationChange,
            }}
            label={'Другая модификация прибора'}
          />
        </div>
      </Collapse>

      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Input
            placeholder={'Введите заводской номер'}
            value={meterFactoryNumber}
            type={'text'}
            actions={{
              change: handleMeterFactoryNumberChange,
            }}
            label={'Заводской номер прибора'}
          />
        </div>
        <div className='width50Percent'>
          <Selector
            placeholder={'Выберите год'}
            options={optionsYear}
            onChange={getValueYear}
            value={optionsYear.find(option => option.value === meterYear)}
            isRequired={true}
            isClearable={false}
            isSearchable={true}
            label={{
              text: 'Год выпуска прибора',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(MeterData)
