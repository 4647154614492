// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import CheckCircleIcon from '../../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../../img/icon/VisibilityToggleIcon'
import RadioButton from '../../../../../../ui/radio-button/index.tsx'
import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'
import Input from '../../../../../../ui/input/index.tsx'
import { changeProperty } from '../state'
import { setName } from '../../../../../../store/slices/app/controlers/pageName'
import { toggleEnableStep } from '../../../../../../store/slices/app/controlers/stepper'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import css from '../style.css'
import Tooltip from '../../../../../../ui/tooltip/index.tsx'
import { sanitizeInput } from '../../../../../../utils/sanitizeInput.js'

const {
  DescriptionTypeBox,
  DescriptionTypeBoxLastElement,
  QLimitDescriptionBox,
  QLimitDescriptionBoxLastElement,
} = css

const DescriptionType = props => {
  const { meterViewStatus, edit = false } = props
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const selectedMod = useSelector(state => state.mi15922015Reducer.selectedMod)
  const defaultData = useSelector(state => state.mi15922015Reducer.defaultData)

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const meterType = useSelector(state => state.mi15922015Reducer.meterType)
  const meterLocation = useSelector(state => state.mi15922015Reducer.meterLocation)

  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_max = useSelector(state => state.mi15922015Reducer.q_max)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)

  const enableNextStep = useMemo(() => {
    if (
      diameter &&
      meterType &&
      meterLocation &&
      q_min &&
      q_t &&
      q_max &&
      q_min_limit &&
      q_max_limit
    )
      return true
    else return false
  }, [diameter, meterType, meterLocation, q_min, q_t, q_max, q_min_limit, q_max_limit])

  const meterTypeData = [10, 15, 20]
  const options = meterTypeData
    ? meterTypeData.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = selectedOption => {
    dispatch(
      changeProperty({
        value: 'diameter',
        label: selectedOption ? selectedOption.value : null,
      }),
    )
  }

  const handleMeterTypeChange = e => {
    dispatch(changeProperty({ value: 'meterType', label: e.target.value }))
  }

  const handleMeterLocationChange = e => {
    dispatch(changeProperty({ value: 'meterLocation', label: e.target.value }))
  }

  useEffect(() => {
    if (selectedMod !== null) {
      if (selectedMod && selectedMod.diameter) {
        dispatch(changeProperty({ value: 'diameter', label: selectedMod.diameter }))
      }

      if (selectedMod && selectedMod.type === 'hot') {
        dispatch(changeProperty({ value: 'meterType', label: 'hot' }))
      } else if (selectedMod && selectedMod.type === 'cold') {
        dispatch(changeProperty({ value: 'meterType', label: 'cold' }))
      }

      if (selectedMod && selectedMod.q_min_vertical && meterLocation === 'vertical') {
        const q_min = parseFloat(selectedMod.q_min_vertical).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_vertical).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_vertical).toFixed(2)
        dispatch(changeProperty({ value: 'q_min', label: q_min }))
        dispatch(changeProperty({ value: 'q_t', label: q_t }))
        dispatch(changeProperty({ value: 'q_max', label: q_max }))
      }
      if (selectedMod && selectedMod.q_min_horizontal && meterLocation === 'horizontal') {
        const q_min = parseFloat(selectedMod.q_min_horizontal).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_horizontal).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_horizontal).toFixed(2)
        dispatch(changeProperty({ value: 'q_min', label: q_min }))
        dispatch(changeProperty({ value: 'q_t', label: q_t }))
        dispatch(changeProperty({ value: 'q_max', label: q_max }))
      }

      if (selectedMod && defaultData && meterType === 'cold') {
        const q_min_limit = defaultData.q_min_limit_cold
          ? parseFloat(defaultData.q_min_limit_cold)
          : selectedMod.q_min_limit_cold
        const q_max_limit = defaultData.q_max_limit_cold
          ? parseFloat(defaultData.q_max_limit_cold)
          : selectedMod.q_max_limit_cold
        dispatch(changeProperty({ value: 'q_min_limit', label: q_min_limit }))
        dispatch(changeProperty({ value: 'q_max_limit', label: q_max_limit }))
      }

      if (selectedMod && defaultData && meterType === 'hot') {
        const q_min_limit = defaultData.q_min_limit_hot
          ? parseFloat(defaultData.q_min_limit_hot)
          : selectedMod.q_min_limit_hot
        const q_max_limit = defaultData.q_max_limit_hot
          ? parseFloat(defaultData.q_max_limit_hot)
          : selectedMod.q_max_limit_hot
        dispatch(changeProperty({ value: 'q_min_limit', label: q_min_limit }))
        dispatch(changeProperty({ value: 'q_max_limit', label: q_max_limit }))
      }
    }
  }, [selectedMod, defaultData, meterLocation, meterType, dispatch])

  useEffect(() => {
    dispatch(setName('Метрологические характеристики'))
  }, [dispatch])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  return (
    <React.Fragment>
      {width > 1050 && (
        <Typography variant='h2' mb={1.5}>
          {width > 1050
            ? 'Метрологические и технические характеристики по описанию типа'
            : 'Метрологические и технические характеристики'}
        </Typography>
      )}

      <div
        className='flexContainerWithGap'
        style={width > 1050 ? { marginBottom: '25px' } : { margin: 0, gap: '0px' }}
      >
        <DescriptionTypeBox margin={'0px'} style={width > 1050 ? {} : { margin: 0 }}>
          <Typography sx={{ fontSize: '15px' }} mb={width > 1050 ? 1 : '9px'}>
            Диаметр условного прохода, мм
          </Typography>

          {(edit && meterViewStatus !== 'default') || selectedMod ? (
            <Tooltip title='Недоступно для выбранного СИ' placement={'top-start'}>
              <Typography
                sx={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  backgroundColor: 'white',
                  padding: '14px 16px',
                  borderRadius: '12px',
                  fontSize: '15px',
                }}
              >
                {diameter}
              </Typography>
            </Tooltip>
          ) : selectedMod ? (
            <Typography
              sx={{
                height: '54px',
                border: '1px solid #d7ddea',
                backgroundColor: 'white',
                padding: '14px 16px',
                borderRadius: '12px',
                fontSize: '15px',
              }}
            >
              {diameter}
            </Typography>
          ) : (
            <Selector
              placeholder={
                width > 1050 ? 'Выберите диаметр условного прохода' : 'Диаметр условного прохода'
              }
              options={options}
              onChange={getValue}
              value={diameter ? options.find(option => option.value === diameter) : null}
              isClearable={false}
              isSearchable={true}
            />
          )}
        </DescriptionTypeBox>

        <div className='width32Percent' style={width > 1050 ? {} : { marginTop: '16px' }}>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Typography sx={{ fontSize: '15px' }} mb={width > 1050 ? 1 : 0}>
              Расположение прибора
            </Typography>
            <>
              {!!meterLocation ? (
                <CheckCircleIcon style={{ marginBottom: '-2px' }} />
              ) : (
                <VisibilityToggleIcon style={{ marginBottom: '-0px' }} />
              )}
            </>
          </div>

          {edit && meterViewStatus !== 'default' ? (
            <Tooltip title={'Недоступно для выбранного СИ'} placement={'top-start'}>
              <Typography
                sx={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  padding: '14px 16px',
                  borderRadius: '12px',
                  fontSize: '15px',
                }}
              >
                {meterLocation === 'vertical' ? 'Вертикальное' : 'Горизонтальное'}
              </Typography>
            </Tooltip>
          ) : (
            <div
              style={{
                padding: width > 1050 ? '0 0 0 14px' : '0px',
                marginTop: width > 1050 ? '' : '6px',
              }}
            >
              <RadioButton
                value={meterLocation}
                onChange={handleMeterLocationChange}
                trueLabel={'Вертикальное'}
                falseLabel={'Горизонтальное'}
                trueValue={'vertical'}
                falseValue={'horizontal'}
              />
            </div>
          )}
        </div>

        <div
          className='width32Percent'
          style={width > 1050 ? {} : { marginTop: '16px', marginBottom: '16px' }}
        >
          <div style={{ display: 'flex', gap: '4px' }}>
            <Typography sx={{ fontSize: '15px' }} mb={width > 1050 ? 1 : 0}>
              Измеряемая вода
            </Typography>
            <>
              {!!meterType ? (
                <CheckCircleIcon style={{ marginBottom: '-2px' }} />
              ) : (
                <VisibilityToggleIcon style={{ marginBottom: '-0px' }} />
              )}
            </>
          </div>

          {edit && meterViewStatus !== 'default' ? (
            <Tooltip title={'Недоступно для выбранного СИ'} placement={'top-start'}>
              <Typography
                sx={{
                  height: '54px',
                  border: '1px solid #d7ddea',
                  padding: '14px 16px',
                  borderRadius: '12px',
                  fontSize: '15px',
                }}
              >
                {meterType === 'cold' ? 'Холодная' : 'Горячая'}
              </Typography>
            </Tooltip>
          ) : (
            <div
              style={{
                padding: width > 1050 ? '0 0 0 14px' : '0px',
                marginTop: width > 1050 ? '' : '6px',
              }}
            >
              <RadioButton
                value={meterType}
                onChange={handleMeterTypeChange}
                trueLabel={'Холодная'}
                falseLabel={'Горячая'}
                trueValue={'cold'}
                falseValue={'hot'}
                trueDisabled={selectedMod && selectedMod.type === 'hot'}
                falseDisabled={selectedMod && selectedMod.type === 'cold'}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className='flexContainerWithGap'
        style={
          width > 1050
            ? { marginBottom: '25px' }
            : {
                margin: 0,
                gap: '20px',
                backgroundColor: 'white',
                padding: '12px 8px',
                borderRadius: '8px',
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '0px',
              }
        }
      >
        <DescriptionTypeBox margin={'0px'} style={width > 1050 ? {} : { margin: 0 }}>
          <Input
            label={'Qmin (описание типа), м³/ч'}
            placeholder={'0.000'}
            value={q_min}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_min', label: sanitizeInput(value) })),
            }}
          />
        </DescriptionTypeBox>

        <DescriptionTypeBox margin={'0px'} style={width > 1050 ? {} : { margin: 0 }}>
          <Input
            label={'Qt (описание типа), м³/ч'}
            placeholder={'0.000'}
            value={q_t}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_t', label: sanitizeInput(value) })),
            }}
          />
        </DescriptionTypeBox>

        <DescriptionTypeBoxLastElement margin={'0px'} style={width > 1050 ? {} : { margin: 0 }}>
          <Input
            label={'Qmax (описание типа), м³/ч'}
            placeholder={'0.000'}
            value={q_max}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_max', label: sanitizeInput(value) })),
            }}
          />
        </DescriptionTypeBoxLastElement>
      </div>

      <div
        className='flexContainerWithGap'
        style={
          width > 1050
            ? {}
            : {
                margin: 0,
                gap: '20px',
                backgroundColor: 'white',
                padding: '9px 8px 12px',
                borderRadius: '8px',
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '0px',
              }
        }
      >
        <QLimitDescriptionBox style={width > 1050 ? {} : { margin: 0 }}>
          <Input
            label={
              width > 1050
                ? 'Предел допуск. погрешности от Qmin до Qt, %'
                : 'Погрешность от Qmin до Qt, %'
            }
            placeholder={'0'}
            value={q_min_limit}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_min_limit', label: sanitizeInput(value) })),
            }}
          />
        </QLimitDescriptionBox>

        <QLimitDescriptionBoxLastElement>
          <Input
            label={
              width > 1050
                ? 'Предел допуск. погрешности от Qt до Qmax, %'
                : 'Погрешность от Qt до Qmax, %'
            }
            placeholder={'0'}
            value={q_max_limit}
            actions={{
              change: value =>
                dispatch(changeProperty({ value: 'q_max_limit', label: sanitizeInput(value) })),
            }}
          />
        </QLimitDescriptionBoxLastElement>
      </div>
    </React.Fragment>
  )
}

export default DescriptionType
