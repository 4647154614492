import React, { useState, useEffect } from 'react'
import Selector from '../Selector/reactSelect/Selector'
import R from '../../../../services/app/client-server/request.service.ts'

const KitAccuracyClassSelect = ({ kitAccuracyClassId, setKitAccuracyClassId }) => {
  const [data, setData] = useState([])

  const options = data.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const getValue = selectedOption => {
    setKitAccuracyClassId && setKitAccuracyClassId(selectedOption ? selectedOption.value : '')
  }

  useEffect(() => {
    ;(async () => {
      const { data: listKitsAccuracyClasses } = await R.getKitsAccuracyClasses()
      const KitsAccuracyClasses = listKitsAccuracyClasses.data
      setData(KitsAccuracyClasses)
    })()
  }, [])

  return (
    <Selector
      placeholder={'Выберите класс точности'}
      options={options}
      onChange={getValue}
      value={options.find(option => option.value === kitAccuracyClassId)}
      isClearable={true}
      isSearchable={false}
      noOptionsMessage={() => 'Нет доступных классов точности'}
      label={{
        text: 'Класс точности (разряд)',
        topBg: 'white',
        bottomBg: 'white',
      }}
      isRequired={true}
    />
  )
}

export default KitAccuracyClassSelect
