import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Checkbox, CircularProgress, Radio, RadioGroup, FormControlLabel } from '@mui/material'

import AccordionComponent from '../../app/components/LocalElements/Accordion/Accordion'
import Table from '../../app/components/LocalElements/Table/Table'
import DatePicker from '../../app/components/LocalElements/DatePicker/DatePicker'
import Button from '../../ui/button/index.tsx'

import { refreshCompanies } from '../../store/slices/app/controlers/updater'
import R from '../../services/app/client-server/request.service.ts'
import { setOpen as setOpenAlert, setMessage, setType } from '../../store/slices/app/comps/snackbar'

interface TrialProps {
  companyData: any
  rates: any[]
  subscription: any[]
}

const Trial: React.FC<TrialProps> = props => {
  const { companyData, rates, subscription } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyId = useSelector((state: any) => state.companiesReducer.companyId)
  const statusSelector = useSelector((state: any) => state.companiesReducer.statusSelector)

  const [tariffInclusion, seTariffInclusion] = useState<any[]>([])
  const [tariffIds, setTariffIds] = useState<any[]>([])
  const [subscriptionFee, setSubscriptionFee] = useState<string>('')

  useEffect(() => {
    if (companyData?.fix_rate_price) {
      setSubscriptionFee(companyData.fix_rate_price.toString())
    }
  }, [companyData?.fix_rate_price])

  useEffect(() => {
    if (!companyData?.tariffs || !Array.isArray(companyData.tariffs)) {
      return
    }
    const companyTariffIds = companyData.tariffs.map((tariff: any) => tariff.id)
    const initialInclusion = rates.map((rate: any) => ({
      tariff_id: rate.tariff_id,
      is_required: companyTariffIds.includes(rate.tariff_id) ? true : rate.is_required || false,
    }))
    seTariffInclusion(initialInclusion)
  }, [rates, companyData?.tariffs])

  useEffect(() => {
    const updatedRates = rates.reduce((acc: any[], current: any) => {
      const existingRate = acc.find(item => item.tariff_id === current.tariff_id)
      if (!existingRate) {
        const matchingState = tariffInclusion.find(state => state.tariff_id === current.tariff_id)
        acc.push({
          tariff_id: current.tariff_id,
          is_required: matchingState ? matchingState.is_required : current.is_required,
        })
      }
      return acc
    }, [])
    setTariffIds(updatedRates)
  }, [rates, tariffInclusion])

  const handleCheckboxChange = (tariffId: number, isRequired: boolean, checked: boolean) => {
    seTariffInclusion((prevState: any[]) => {
      const existingIndex = prevState.findIndex(item => item.tariff_id === tariffId)
      if (existingIndex > -1) {
        const updatedState = [...prevState]
        updatedState[existingIndex] = { tariff_id: tariffId, is_required: checked }
        return updatedState
      } else {
        return [...prevState, { tariff_id: tariffId, is_required: checked }]
      }
    })
  }

  let colDataWithoutActions: any[] = [
    {
      field: 'service',
      headerName: 'Услуга',
    },
    {
      field: 'description',
      headerName: 'Описание',
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ ( за ед. )',
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽ ( за р.п. )',
      renderCell: (params: any) => {
        const { row } = params

        if (row.id === 1) {
          return (
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              row
              value={subscriptionFee}
              onChange={e => setSubscriptionFee(e.target.value)}
              sx={{ width: '100%', padding: '0 20px' }}
            >
              <FormControlLabel
                value='0.00'
                control={<Radio />}
                label='0.00'
                sx={{
                  width: '50%',
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                  },
                }}
              />
              <FormControlLabel
                value='2500.00'
                control={<Radio />}
                label='2500.00'
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                  },
                }}
              />
            </RadioGroup>
          )
        }
        return <div>{row.priseForService}</div>
      },
    },
  ]

  let colDataWithActions: any[] = [
    ...colDataWithoutActions,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      renderCell: (params: any) => {
        const tariffId = params.row.tariff_id
        const isRequired = params.row.is_required
        const currentState = tariffInclusion.find(item => item.tariff_id === tariffId)
        return (
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <Checkbox
              disabled={isRequired}
              checked={isRequired || currentState?.is_required || false}
              onChange={e => handleCheckboxChange(tariffId, isRequired, e.target.checked)}
            />
          </div>
        )
      },
    },
  ]

  const handleSave = async () => {
    const tariffs = tariffIds.filter(rate => rate.is_required).map(rate => rate.tariff_id)

    const data = {
      status: 'trial',
      subscription_id: 1,
      tariffs,
      ids: [companyId],
      fix_rate_price: parseFloat(subscriptionFee),
    }

    const response: any = await R.tariffChangeActivate(data)
    const { status, data: tariffsResponse } = response

    if (status === 200) {
      dispatch(setMessage(tariffsResponse.message || 'Изменения успешно сохранены.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCompanies(''))
      navigate('/metriva/companies')
    } else {
      dispatch(setMessage(tariffsResponse.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '16px', fontWeight: 'bold', marginTop: '26px' }}>
        Тариф «{subscription[0]?.title}»
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <AccordionComponent
          title='Краткое описание'
          dataType='custom'
          backgroundColor='white'
          data={<span style={{ lineHeight: '24px' }}>{subscription[0]?.description}</span>}
          isExpanded={false}
        />

        <AccordionComponent
          title='Расчетный период'
          dataType='custom'
          backgroundColor='white'
          data={
            <div>
              <h4 style={{ marginBottom: '20px' }}>Текущий период</h4>
              <div style={{ display: 'flex', gap: '16px' }}>
                <DatePicker
                  label='Начало периода'
                  isDisabled={true}
                  selectedDate={
                    companyData?.created_at?.full ? dayjs(companyData.created_at.full) : null
                  }
                />
                <DatePicker
                  label='Окончание периода (включительно)'
                  isDisabled={true}
                  selectedDate={
                    companyData?.activation_date
                      ? dayjs(companyData.activation_date)
                      : companyData?.created_at?.full
                        ? dayjs(companyData.created_at.full).add(1, 'month').subtract(1, 'day')
                        : null
                  }
                />
              </div>
            </div>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (предоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <>
              <h4>Расчет предоплаты по лицензии</h4>
              <Table
                colData={colDataWithoutActions}
                rowData={rates
                  .filter((item: any) => !item.post_payment && ![2, 3, 4].includes(item.order))
                  .map((item: any) => ({
                    id: item.order,
                    service: item.title,
                    description: item.description,
                    billing: item.units,
                    count: '1',
                    prise: item.price,
                    priseForService: item.price,
                    threshold: item.threshold,
                    is_required: item.is_required,
                    tariff_id: item.tariff_id,
                  }))}
                hideFooter={true}
                hasCheckboxSelection={false}
                noRowsText={<CircularProgress />}
                getRowId={(row: any) => row.id}
                getRowHeight={() => 'auto'}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (постоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <>
              <h4>Выбор основных опций</h4>
              <Table
                rowData={rates
                  .filter((item: any) => item.post_payment)
                  .filter((item: any) => ![41, 42, 43, 44, 45, 46, 47].includes(item.order))
                  .map((item: any) => ({
                    id: item.order,
                    service: item.title,
                    description: item.description,
                    billing: item.units,
                    count: 'Неограниченно',
                    prise: '0.00',
                    priseForService: '0.00',
                    threshold: item.threshold,
                    is_required: item.is_required,
                    tariff_id: item.tariff_id,
                  }))}
                colData={colDataWithActions}
                hideFooter={true}
                hasCheckboxSelection={false}
                getRowHeight={() => 'auto'}
                noRowsText={<CircularProgress />}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </>
          }
          isExpanded={true}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '25%',
              paddingLeft: '10px',
            }}
          >
            <h4 style={{ color: '#132532' }}>Действие тарифа :</h4>
            <h4 style={{ color: '#0084e2' }}>
              {statusSelector === 'trial'
                ? `Действует до ${
                    companyData?.activation_date
                      ? dayjs(companyData.activation_date).format('DD.MM.YYYY')
                      : companyData?.created_at?.full
                        ? dayjs(companyData.created_at.full)
                            .add(1, 'month')
                            .subtract(1, 'day')
                            .format('DD.MM.YYYY')
                        : ''
                  }`
                : 'Завершен'}
            </h4>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '25%',
              paddingLeft: '10px',
              marginBottom: '10px',
            }}
          >
            <h4 style={{ color: '#132532' }}>Общая стоимость, ₽ :</h4>
            <h4 style={{ color: '#0084e2' }}>{parseFloat(subscriptionFee).toFixed(2) || '0.00'}</h4>
          </div>

          <Button
            onClick={handleSave}
            color='primary'
            label='Сохранить изменения'
            fullWidth
            disabled={statusSelector !== 'trial'}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Trial
