// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'
import DatePicker from '../../../LocalElements/DatePicker/DatePicker'
import Feedback from '../../../LocalElements/Feedback/Feedback'
import R from '../../../../../services/app/client-server/request.service.ts'
import {
  changeKitVerificationsAdd,
  resetKitVerificationsAdd,
} from '../../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../../store/slices/app/comps/snackbar'
import { refreshKitVerifications } from '../../../../../store/slices/app/controlers/updater'
import { setShow } from '../../../../../store/slices/app/comps/feedback'
import { Wrapper, Content, ContentFrame, LabelFrame } from '../../style'
import Input from '../../../../../ui/input/index.tsx'
import RadioButton from '../../../../../ui/radio-button/index.tsx'
import Button from '../../../../../ui/button/index.tsx'
import BackButton from '../../../../../ui/back-button/index.tsx'
import usePrompt from '../../../../hooks/usePrompt.ts'

const Edit = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { journal_id } = useParams()
  const [errors, setErrors] = useState({})
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [getPrompt, setGetPrompt] = useState(true)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const name = useSelector(state => state.journalReducer.kitVerificationsAdd.name)
  const place = useSelector(state => state.journalReducer.kitVerificationsAdd.place)
  const verificationDate = useSelector(
    state => state.journalReducer.kitVerificationsAdd.verificationDate,
  )
  const isHasNewVerification = useSelector(
    state => state.journalReducer.kitVerificationsAdd.isHasNewVerification,
  )
  const interval = useSelector(state => state.journalReducer.kitVerificationsAdd.interval)

  usePrompt(
    'Вы уверены, что хотите продолжить действие? Все введенные или измененные данные не будут сохранены',
    getPrompt,
  )

  const handleNameChange = param => {
    dispatch(changeKitVerificationsAdd({ value: 'name', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, name: '' }))
  }

  const handlePlaceChange = param => {
    dispatch(changeKitVerificationsAdd({ value: 'place', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, place: '' }))
  }

  const handleIntervalChange = param => {
    dispatch(changeKitVerificationsAdd({ value: 'interval', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, interval: '' }))
  }

  const changeIsHasNewVerificationChecked = event => {
    const value = event.target.value === 'true'
    dispatch(changeKitVerificationsAdd({ value: 'isHasNewVerification', label: value }))
    setErrors(prevErrors => ({ ...prevErrors, is_has_new_verification: '' }))
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changeKitVerificationsAdd({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeKitVerificationsAdd({ value: 'verificationDate', label: '' }))
    }
  }

  useEffect(() => {
    ;(async () => {
      dispatch(setShow(true))
      try {
        const { data: kitVerificationsData } = await R.getKitVerificationsItem(
          journal_id,
          companyId,
        )
        const data = kitVerificationsData.data

        dispatch(
          changeKitVerificationsAdd({
            value: 'name',
            label: data?.name,
          }),
        )

        dispatch(
          changeKitVerificationsAdd({
            value: 'place',
            label: data?.place,
          }),
        )

        dispatch(
          changeKitVerificationsAdd({
            value: 'interval',
            label: data?.interval,
          }),
        )

        dispatch(
          changeKitVerificationsAdd({
            value: 'verificationDate',
            label: data?.date,
          }),
        )

        dispatch(
          changeKitVerificationsAdd({
            value: 'isHasNewVerification',
            label: data?.is_has_new_verification,
          }),
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [journal_id, companyId, dispatch])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    setGetPrompt(false)

    const data = {
      name: name,
      date: verificationDate,
      interval: interval,
      place: place,
      is_has_new_verification: isHasNewVerification,
    }

    const { status, data: responseData } = await R.editKitVerifications(journal_id, companyId, data)

    if (status === 200) {
      dispatch(setMessage('Запись успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshKitVerifications())
      dispatch(resetKitVerificationsAdd())
      navigate('/metriva/journals')
    } else if (status === 422) {
      setErrors(responseData.errors || {})
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(responseData.message))
      setIsDisabledBtn(false)
      setGetPrompt(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetKitVerificationsAdd())
    }
  }, [])

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <BackButton to='/metriva/journals' label='Все записи документа' />

          <Typography variant='h2'>График поверки средств измерений и контроля</Typography>

          <Content>
            <Typography variant='h2' style={{ marginBottom: '4px' }}>
              Информация о журнале
            </Typography>

            <ContentFrame style={{ marginBottom: '4px' }}>
              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Наименование, тип, заводской номер'}
                  placeholder={'Введите информацию о средстве поверки'}
                  value={name}
                  actions={{
                    change: handleNameChange,
                  }}
                  error={!!errors.name}
                />
                {errors.name && <div className='error'>{errors.name[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '50%' }}>
                <Input
                  label={'Поверяющая организация'}
                  placeholder={'Введите название организации'}
                  value={place}
                  error={!!errors.place}
                  actions={{
                    change: handlePlaceChange,
                  }}
                />
                {errors.place && <div className='error'>{errors.place[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                  label={'Дата последней поверки'}
                  isRequired={true}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Периодичность поверки, месяцы'}
                  placeholder={'Введите периодичность поверки'}
                  value={interval}
                  error={!!errors.interval}
                  actions={{
                    change: handleIntervalChange,
                  }}
                />
                {errors.interval && <div className='error'>{errors.interval[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                  Отметка о новой поверке
                  {isHasNewVerification !== '' ? (
                    <CheckCircleIcon
                      sx={{
                        color: '#63E6BE',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  ) : (
                    <VisibilityToggleIcon
                      sx={{
                        color: '#FFD43B',
                        marginLeft: '8px',
                        marginTop: '-0px',
                      }}
                    />
                  )}
                </Typography>
                <RadioButton
                  value={isHasNewVerification}
                  onChange={changeIsHasNewVerificationChecked}
                  trueLabel={'Есть'}
                  falseLabel={'Нет'}
                  trueValue={true}
                  falseValue={false}
                />
                {errors.is_has_new_verification && (
                  <div className='error'>{errors.is_has_new_verification[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Редактировать запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Edit
