import React, { ReactElement } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'

import IconButton from '@mui/material/IconButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SettingsIcon from '@mui/icons-material/Settings'

const SystemNotificationsService: React.FC<{ data: { text: string }[] }> = (
  props,
): ReactElement => {
  const { data } = props

  return (
    <React.Fragment>
      <span
        style={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 21px',
          position: 'relative',
          width: '100%',
          textAlign: 'left',
          height: '48px',
          lineHeight: '46px',
          boxShadow: '0px 0px 12px -5px rgba(34, 60, 80, 0.44)',
          borderBottomLeftRadius: '10px',
          zIndex: 2,
        }}
      >
        Системные уведомления
        <SettingsIcon />
      </span>
      <div
        style={{
          display: 'block',
          position: 'relative',
          width: '100%',
          height: 'calc(100% - 100px)',
          overflow: 'hidden',
        }}
      >
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            height: '100%',
            overflowY: 'scroll',

            '& .MuiListItemText-primary': { fontWeight: 400, fontSize: '14px', lineHeight: '22px' },
          }}
        >
          {data.map((value, index) => {
            const labelId = `checkbox-list-label-${index}`

            return (
              <React.Fragment>
                <ListItem
                  secondaryAction={
                    <IconButton edge='end' aria-label='comments'>
                      <ErrorOutlineIcon sx={{ color: '#E57004' }} />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => {}} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.text}`} />
                  </ListItemButton>
                </ListItem>
                <Divider
                  variant='middle'
                  component='li'
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                />
              </React.Fragment>
            )
          })}
        </List>
      </div>
      <span
        style={{
          display: 'block',
          position: 'relative',
          width: '100%',
          color: 'rgba(0, 132, 226, 1)',
          textAlign: 'center',
          marginTop: '10px',
          cursor: 'pointer',
        }}
      >
        Показать все оповещения
      </span>
    </React.Fragment>
  )
}

export default SystemNotificationsService
