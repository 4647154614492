import React, { FC } from 'react'

interface IPencilIconProps {
  color?: string
  width?: number
  height?: number
  onClick?: () => void
}

const PencilIcon: FC<IPencilIconProps> = ({
  color = '#899298',
  width = 20,
  height = 20,
  onClick,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 20 20'
      onClick={onClick}
    >
      <path
        d='M9.50136 15.1342L9.50139 15.1342L15.6646 8.97093C14.8258 8.62181 13.8323 8.04834 12.8927 7.10874C11.9529 6.16899 11.3794 5.17532 11.0303 4.33642L4.86699 10.4998L4.86698 10.4998C4.38603 10.9807 4.14553 11.2212 3.93872 11.4864C3.69475 11.7992 3.48558 12.1376 3.31492 12.4957C3.17025 12.7993 3.0627 13.1219 2.8476 13.7672L1.71334 17.17C1.60749 17.4875 1.69014 17.8376 1.92683 18.0743C2.16352 18.311 2.51362 18.3936 2.83117 18.2878L6.23395 17.1535C6.87923 16.9384 7.20188 16.8309 7.50545 16.6862C7.86355 16.5156 8.20198 16.3064 8.51477 16.0624C8.77992 15.8556 9.02043 15.6151 9.50136 15.1342Z'
        fill={color}
      />
      <path
        d='M17.3748 7.26072C18.6546 5.98096 18.6546 3.90607 17.3748 2.62632C16.0951 1.34657 14.0202 1.34657 12.7404 2.62632L12.0012 3.36552C12.0113 3.39608 12.0218 3.42707 12.0327 3.45847C12.3037 4.23942 12.8149 5.26318 13.7766 6.22485C14.7382 7.18653 15.762 7.69774 16.543 7.96868C16.5742 7.97953 16.6051 7.98998 16.6355 8.00006L17.3748 7.26072Z'
        fill={color}
      />
    </svg>
  )
}

export default PencilIcon
