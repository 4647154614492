// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Collapse, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'

import PdfIcon from '../../../../img/icon/PdfIcon.tsx'

import ExportIcon from '../../../../img/icon/Export.tsx'

import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index.tsx'
import theme from '../../../../utils/theme.ts'

import RightSideContainer from '../../../components/LocalElements/RightSideContainer/RightSideContainer'
import FilterMeasurement from '../../../components/LocalElements/Filter/FilterMeasurement'
import FilterUsers from '../../../components/LocalElements/Filter/FilterUsers'
import DatePicker from '../../../components/LocalElements/DatePicker/DatePicker'
import FilterCompany from '../../../components/LocalElements/Filter/FilterCompany'
import TableMobile from '../../../components/LocalElements/Table/TableMobile.tsx'

import R from '../../../../services/app/client-server/request.service.ts'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setMeasurementIdFilter,
  setUserId,
  setStatusId,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setDateFrom,
  setDateTo,
  setSelectedProtocols,
  setViewVariantMain,
} from '../../../../store/slices/app/views/protocols'
import { setName } from '../../../../store/slices/app/controlers/pageName.js'

import {
  refreshReceiveds,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshTickets,
} from '../../../../store/slices/app/controlers/updater'

import { refreshProtocols } from '../../../../store/slices/app/controlers/updater'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'

const AllProtocolsMobile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))

  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const protocolsData = useSelector(state => state.protocolReducer.protocolsList)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const viewVariant = useSelector(state => state.protocolReducer.viewVariantMain)
  const selectedProtocols = useSelector(state => state.protocolReducer.selectedProtocols)
  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const userId = useSelector(state => state.protocolReducer.userId)
  const statusProtocol = useSelector(state => state.protocolReducer.statusId)
  const approvedTransfer = useSelector(state => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector(state => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector(state => state.protocolReducer.approvedFsa)
  const dateFrom = useSelector(state => state.protocolReducer.dateFrom)
  const dateTo = useSelector(state => state.protocolReducer.dateTo)

  const resetActions = [
    setMeasurementIdFilter,
    setUserId,
    setStatusId,
    setApprovedTransfer,
    setApprovedArshin,
    setApprovedFsa,
    setDateFrom,
    setDateTo,
  ]

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (statusProtocol) filtersCount++
    if (approvedTransfer) filtersCount++
    if (approvedArshin) filtersCount++
    if (approvedFsa) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (
      !measurementIdFilter &&
      !userId &&
      !statusProtocol &&
      !approvedTransfer &&
      !approvedArshin &&
      !approvedFsa &&
      !dateFrom &&
      !dateTo
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementIdFilter,
    userId,
    statusProtocol,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  const handleVerifiedAtChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(null))
    }
  }

  const handleExpiredAtChange = date => {
    if (date) {
      dispatch(setDateTo(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateTo(null))
    }
  }

  const changeMeasurementId = param => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeUserId = param => {
    dispatch(setUserId(param))
  }
  const changeApprovedStatus = event => {
    dispatch(setStatusId(event.target.value))
  }

  const changeApprovedTransfer = event => {
    dispatch(setApprovedTransfer(event.target.value))
  }

  const changeApprovedArshin = event => {
    dispatch(setApprovedArshin(event.target.value))
  }
  const changeApprovedFsa = event => {
    dispatch(setApprovedFsa(event.target.value))
  }

  useEffect(() => {
    if (approvedTransfer === 'no') {
      dispatch(setApprovedArshin(''))
    }

    if (approvedTransfer === 'no' || approvedArshin === 'not-published') {
      dispatch(setApprovedFsa(''))
    }
  }, [approvedTransfer, approvedArshin, dispatch])

  useEffect(() => {
    dispatch(setName('Список протоколов'))
  }, [])

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const filteredProtocolsData = protocolsData.filter(item => {
    if (viewVariant === 0) {
      return !item.archived_at
    } else if (viewVariant === 1) {
      return item.archived_at
    }
    return true
  })

  const handleShowClick = param => {
    isMobile && navigate(`/metriva/protocols/${param.row.id}`)
  }

  const handleEditClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/edit`)
  }

  const handleCopyClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/copy`)
  }

  const handleDelete = async protocolId => {
    const { status } = await R.deleteProtocol(protocolId)
    if (status === 200) {
      dispatch(refreshProtocols())

      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Протокол удален'))
      dispatch(setOpen(false))
      dispatch(resetState())

      dispatch(refreshConditions())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      dispatch(refreshIssueCase())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  const handleCheckProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status } = await R.protocolCheck(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно одобрены для передачи в Аршин и ФСА'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
    }
  }

  const handleClickOnArchiveBtn = () => {
    const filteredBySelected = filteredProtocolsData.filter(item =>
      selectedProtocols.includes(item.id),
    )

    const allThreeConditionsMet = filteredBySelected.every(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const someConditionsNotMet = filteredBySelected.some(
      item =>
        item.status !== 'sent' ||
        item.status_arshin !== 'published' ||
        item.status_fsa !== 'published',
    )

    const validProtocols = filteredBySelected.filter(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const updatedProtocols = validProtocols.map(item => item.id)

    let title
    let message
    let confirmAction

    if (allThreeConditionsMet) {
      title = 'Архивация протокола'
      message =
        'Вы действительно хотите перенести выбранные протоколы в архив?\nАрхивная версия будет доступна только в режиме просмотра.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else if (someConditionsNotMet && validProtocols.length > 0) {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС Аршин и ФСА.\nВы можете продолжить, исключив данные протоколы из списка архивируемых, либо возобновить процесс позднее.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС Аршин и ФСА.'
      confirmAction = () => dispatch(setOpen(false))
    }

    dispatch(setOpen(true))
    dispatch(setTitle(title))
    dispatch(setChildren(message))
    dispatch(setBA('Архивировать'))
    dispatch(setBD('Закрыть'))
    dispatch(setConfirmAction(confirmAction))
    dispatch(refreshProtocols())
    if (validProtocols.length > 0) {
      dispatch(showBA(true))
    } else {
      dispatch(showBA(false))
    }
  }

  const handleArchiveProtocol = async updatedProtocols => {
    const data = {
      ids: updatedProtocols,
    }

    const { status } = await R.protocolArchive(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно архивированы.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
      dispatch(refreshProtocols())
    }
  }

  const handlePdf = async () => {
    const data = {
      ids: selectedProtocols,
    }

    try {
      const { status, data: fileData } = await R.protocolPdf(data)
      const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

      if (status === 200) {
        let blob
        let fileName

        if (viewVariant === 0) {
          if (selectedProtocols.length === 1) {
            const currentProtocol = filteredProtocolsData.find(
              item => item.id === selectedProtocols[0],
            )
            const arshinId = currentProtocol?.arshin_id ?? 'Unknown'
            const verificationDate = moment(currentProtocol?.verification_date).format('DD-MM-YYYY')
            fileName = `Protocol_${arshinId}(${verificationDate}).pdf`
            blob = new Blob([fileData], { type: 'application/pdf' })
          } else {
            blob = new Blob([fileData], { type: 'application/zip' })
            fileName = `Protocoly_${currentDate}.zip`
          }
        } else {
          if (selectedProtocols.length === 1) {
            const currentProtocol = filteredProtocolsData.find(
              item => item.id === selectedProtocols[0],
            )
            const arshinId = currentProtocol?.arshin_id ?? 'Unknown'
            const verificationDate = moment(currentProtocol?.verification_date).format('DD-MM-YYYY')
            fileName = `Protocol_(arhive)_${arshinId}(${verificationDate}).pdf`
            blob = new Blob([fileData], { type: 'application/pdf' })
          } else {
            blob = new Blob([fileData], { type: 'application/zip' })
            fileName = `Protocoly_(arhive)_${currentDate}.zip`
          }
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('Протокол(ы) успешно скачан(ы) в PDF.'))
        dispatch(setType('success'))
        dispatch(setOpenAlert(true))
        dispatch(refreshProtocols())
        dispatch(resetState())
      } else if (status === 404) {
        dispatch(setOpen(true))
        dispatch(setTitle('Cкачивание PDF недоступно'))
        dispatch(
          setChildren(
            selectedProtocols.length === 1 ? (
              <span>
                Внимание! Скачивание PDF недоступно, т.к. выбранный протокол не был опубликован во
                ФГИС Аршин
              </span>
            ) : (
              <span>
                Внимание! Скачивание PDF недоступно, т.к. выбранные протоколы не были опубликованы
                во ФГИС Аршин
              </span>
            ),
          ),
        )
        dispatch(showBA(false))
        dispatch(setBD('Закрыть'))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  const ActiveFilters = () => (
    <RightSideContainer blockTitle={'Фильтры'}>
      <div className='right-side-container'>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Область измерения
          </span>
          <div style={{ width: '100%' }}>
            <FilterMeasurement
              measurementId={measurementIdFilter}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </div>
        </div>

        {userRole !== 'metrologist' && (
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Поверитель
            </span>
            <div style={{ width: '100%' }}>
              <FilterUsers userId={userId} setUserId={changeUserId} />
            </div>
          </div>
        )}

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Заключение поверки
          </span>
          <div style={{ width: '100%' }}>
            <RadioButton
              value={statusProtocol}
              onChange={changeApprovedStatus}
              trueLabel={'Пригоден'}
              falseLabel={'Непригоден'}
              trueValue={true}
              falseValue={false}
            />
          </div>
        </div>

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Одобрен к передаче
          </span>
          <div style={{ width: '100%' }}>
            <RadioButton
              value={approvedTransfer}
              onChange={changeApprovedTransfer}
              trueLabel={'Да'}
              falseLabel={'Нет'}
              trueValue={'yes'}
              falseValue={'no'}
            />
          </div>
        </div>

        <Collapse in={approvedTransfer === 'yes'} timeout={500} unmountOnExit>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Передан в Аршин
            </span>
            <div style={{ width: '100%' }}>
              <RadioButton
                value={approvedArshin}
                onChange={changeApprovedArshin}
                trueLabel={'Да'}
                falseLabel={'Нет'}
                trueValue={'published'}
                falseValue={'not-published'}
              />
            </div>
          </div>
        </Collapse>

        <Collapse
          in={approvedTransfer === 'yes' && approvedArshin === 'published'}
          timeout={500}
          unmountOnExit
        >
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Передан в ФСА
            </span>
            <div style={{ width: '100%' }}>
              <RadioButton
                value={approvedFsa}
                onChange={changeApprovedFsa}
                trueLabel={'Да'}
                falseLabel={'Нет'}
                trueValue={'published'}
                falseValue={'not-published'}
              />
            </div>
          </div>
        </Collapse>

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Дата поверки
          </span>
          <DatePicker selectedDate={dateFrom} dateChange={handleVerifiedAtChange} />
          <DatePicker selectedDate={dateTo} dateChange={handleExpiredAtChange} />
        </div>

        <Button
          onClick={handleResetFilter}
          label='Сбросить все значения'
          color='secondary'
          fullWidth
        />
      </div>
    </RightSideContainer>
  )

  const hasProtocolsWithoutArshinId = selectedProtocols.some(protocolId =>
    filteredProtocolsData.some(
      item =>
        item.id === protocolId && (item.arshin_id === 'Еще не присвоен' || item.arshin_id === null),
    ),
  )

  const hasProtocolsWithoutArshinIdAll = selectedProtocols.every(protocolId =>
    filteredProtocolsData
      .filter(item => item.id === protocolId)
      .every(item => item.arshin_id === 'Еще не присвоен'),
  )

  const DinamicActions = (
    <React.Fragment>
      {selectedProtocols.length > 1 && hasProtocolsWithoutArshinIdAll ? (
        <Button
          onClick={() => {
            dispatch(setOpen(true))
            dispatch(setTitle('Cкачивание PDF недоступно'))
            dispatch(
              setChildren(
                'Внимание! Скачивание PDF недоступно, т.к. выбранные протоколы не были опубликованы во ФГИС Аршин',
              ),
            )
            dispatch(showBA(false))
            dispatch(setBD('Закрыть'))
          }}
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            fontSize: '.88rem',
            minWidth: 0,
            width: '20%',
          }}
        />
      ) : selectedProtocols.length > 1 && hasProtocolsWithoutArshinId ? (
        <Button
          onClick={() => {
            dispatch(setOpen(true))
            dispatch(setTitle('Частичное скачивание PDF'))
            dispatch(setBA('Скачать'))
            dispatch(
              setChildren(
                'Внимание! Протоколы у которых отсутствуют уникальные номера недоступны для скачивания в нередактируемом формате PDF.  Вы можете продолжить скачивание без них или возобновить процесс позднее.',
              ),
            )
            dispatch(setConfirmAction(handlePdf))
          }}
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            fontSize: '.88rem',
            minWidth: 0,
            width: '20%',
          }}
        />
      ) : (
        <Button
          onClick={handlePdf}
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            fontSize: '.88rem',
            minWidth: 0,
            width: '20%',
          }}
        />
      )}

      {isTrusted && (
        <React.Fragment>
          <Button
            onClick={handleCheckProtocol}
            color='text'
            startIcon={<ExportIcon color={theme.palette.text.dark} />}
            // ----------------------------------------------------------------
            // startIcon={<ArchiveIcon color={theme.palette.text.dark} />}
            // ----------------------------------------------------------------
            styles={{
              padding: 0,
              color: 'inherit',
              fontSize: '.88rem',
              minWidth: 0,
              width: '20%',
            }}
          />
          <span style={{ fontSize: '13px' }}>
            Передать
            <br /> в Аршин и ФСА
          </span>
        </React.Fragment>
      )}
    </React.Fragment>
  )

  return (
    <main className='main'>
      <ActiveFilters />
      {userRole === 'super' && (
        <div
          style={{ width: isMobile ? '605px' : '100%', marginBottom: !companyId ? '0px' : '10px' }}
        >
          <FilterCompany />
        </div>
      )}

      {!companyId ? (
        <div className='page_null'>
          <Typography
            variant='body1'
            style={{ display: 'block', width: '100%', textAlign: 'center' }}
          >
            Для просмотра данных выберите компанию
          </Typography>
        </div>
      ) : (
        <React.Fragment>
          <React.Fragment>
            <TableMobile
              css={{}}
              rowCallback={param => {
                navigate(`/metriva/protocols/${param}`)
              }}
              rowCallbackParams={''}
              actionsConroller={DinamicActions}
              data={filteredProtocolsData.map(item => {
                return [
                  ['ID', item.id],
                  ['args', []],
                  ['Уникальный номер', item.arshin_id ?? '-'],
                  ['Дата поверки', item.verification_date],
                  ['Заключение', item.total_accepted ? 'Пригоден' : 'Непригоден'],
                  ['Заводской номер поверяемого СИ', item.meter_factory_number ?? '-'],
                  ['Область измерения', item.measurement?.name],
                  [
                    'Одобрен к передаче',
                    (() => {
                      if (item.status === 'sent') {
                        return (
                          <span
                            style={{
                              color: '#00A37C',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Одобрен
                          </span>
                        )
                      } else {
                        return (
                          <span
                            style={{
                              color: '#E74953',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Не одобрен
                          </span>
                        )
                      }
                    })(),
                  ],
                  [
                    'Передача в Аршин',
                    (() => {
                      if (item.status_arshin === 'not-sent') {
                        return (
                          <span
                            style={{
                              color: '#E74953',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Не начата
                          </span>
                        )
                      } else if (item.status_arshin === 'sent') {
                        return (
                          <span
                            style={{
                              color: '#E57004',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            В процессе
                          </span>
                        )
                      } else if (item.status_arshin === 'published') {
                        return (
                          <span
                            style={{
                              color: '#00A37C',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Завершена
                          </span>
                        )
                      }
                    })(),
                  ],
                  [
                    'Передача в ФСА',
                    (() => {
                      if (item.status_fsa === 'not-sent') {
                        return (
                          <span
                            style={{
                              color: '#E74953',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Не начата
                          </span>
                        )
                      } else if (item.status_fsa === 'sent') {
                        return (
                          <span
                            style={{
                              color: '#E57004',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            В процессе
                          </span>
                        )
                      } else if (item.status_fsa === 'published') {
                        return (
                          <span
                            style={{
                              color: '#00A37C',
                              lineHeight: '24px',
                              textAlign: 'right',
                              flexGrow: 2,
                            }}
                          >
                            Завершена
                          </span>
                        )
                      }
                    })(),
                  ],
                  ['№ свидетельства или извещения', item.certificate ?? '-'],
                  [
                    'Публикация данных о поверке',
                    (() => {
                      if (item.arshin_id !== 'Еще не присвоен') {
                        return (
                          <a
                            href={`https://fgis.gost.ru/fundmetrology/cm/results/1-${item.arshin_id}`}
                            target='_blank'
                            onClick={event => event.stopPropagation()}
                            rel='noreferrer'
                            style={{
                              textDecoration: 'underline',
                              flexGrow: 2,
                              lineHeight: '24px',
                              textAlign: 'right',
                              color: 'rgb(0, 132, 226)',
                            }}
                          >
                            <span style={{ lineHeight: '24px', textAlign: 'right' }}>
                              Ссылка на ФГИС Аршин
                            </span>
                          </a>
                        )
                      } else {
                        return (
                          <span style={{ lineHeight: '24px', textAlign: 'right', flexGrow: 2 }}>
                            Ссылка на ФГИС Аршин
                          </span>
                        )
                      }
                    })(),
                  ],
                ]
              })}
            />
          </React.Fragment>
        </React.Fragment>
      )}
    </main>
  )
}

export default AllProtocolsMobile
