// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import BackButton from '../../../ui/back-button/index.tsx'

import D from '../../../store/localStorage/dispatcher'
import R from '../../../services/app/client-server/request.service.ts'

import NameCompany from '../../components/Companies/NameCompany'
import AddressCompany from '../../components/Companies/AddressCompany'
import LogoCompany from '../../components/Companies/EditLogoCompany'
import CompanyBank from '../../components/Companies/CompanyBank'
import RegionCompany from '../../components/Companies/RegionCompany'
import {
  changeMainData,
  changeOverData,
  resetCreateData,
} from '../../../store/slices/app/views/companies'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

import { refreshHeader } from '../../../store/slices/app/controlers/updater'

const CompanyAdmin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const companyId = D.get('companyId')

  const name = useSelector(state => state.companiesReducer.createData.main.name)
  const ceo = useSelector(state => state.companiesReducer.createData.main.ceo)
  const number = useSelector(state => state.companiesReducer.createData.main.number)
  const email = useSelector(state => state.companiesReducer.createData.main.email)

  const regionId = useSelector(state => state.companiesReducer.createData.main.regionId)

  const address = useSelector(state => state.companiesReducer.createData.main.address)
  const logo = useSelector(state => state.companiesReducer.createData.main.logo)

  const [imageId, setImageId] = useState('')
  const [imageLogoPath, setImageLogoPath] = useState('')

  const rs = useSelector(state => state.companiesReducer.createData.over.rs)
  const ks = useSelector(state => state.companiesReducer.createData.over.ks)
  const bank = useSelector(state => state.companiesReducer.createData.over.bank)
  const inn = useSelector(state => state.companiesReducer.createData.over.inn)
  const kpp = useSelector(state => state.companiesReducer.createData.over.kpp)
  const ogrn = useSelector(state => state.companiesReducer.createData.over.ogrn)
  const bik = useSelector(state => state.companiesReducer.createData.over.bik)
  const bankAddress = useSelector(state => state.companiesReducer.createData.over.bankAddress)
  const code = useSelector(state => state.companiesReducer.createData.over.code)
  const num = useSelector(state => state.companiesReducer.createData.over.num)

  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(companyId)
      const data = companiesItemData.data

      dispatch(
        changeMainData({
          value: 'name',
          label: data.name,
        }),
      )
      dispatch(
        changeMainData({
          value: 'ceo',
          label: data.director_full_name,
        }),
      )
      dispatch(
        changeMainData({
          value: 'number',
          label: data.phone,
        }),
      )
      dispatch(
        changeMainData({
          value: 'email',
          label: data?.email,
        }),
      )
      dispatch(
        changeMainData({
          value: 'regionId',
          label: data.region.id,
        }),
      )

      dispatch(
        changeMainData({
          value: 'address',
          label: data.address,
        }),
      )

      dispatch(
        changeOverData({
          value: 'rs',
          label: data.checking_account,
        }),
      )

      dispatch(
        changeOverData({
          value: 'ks',
          label: data.correspondent_account,
        }),
      )

      dispatch(
        changeOverData({
          value: 'bank',
          label: data.bank_name,
        }),
      )

      dispatch(
        changeOverData({
          value: 'inn',
          label: data.inn,
        }),
      )

      dispatch(
        changeOverData({
          value: 'kpp',
          label: data.kpp,
        }),
      )

      dispatch(
        changeOverData({
          value: 'ogrn',
          label: data.bin,
        }),
      )

      dispatch(
        changeOverData({
          value: 'bik',
          label: data.bic,
        }),
      )

      dispatch(
        changeOverData({
          value: 'bankAddress',
          label: data.bank_address,
        }),
      )

      dispatch(
        changeOverData({
          value: 'code',
          label: data.sign_cipher,
        }),
      )

      dispatch(
        changeOverData({
          value: 'num',
          label: data.accreditation_unique_id,
        }),
      )

      if (data.logo && data.logo.id) {
        setImageId(data.logo.id)
      } else {
        setImageId('')
      }

      if (data.logo && data.logo.path) {
        setImageLogoPath(data.logo.path)
      } else {
        setImageLogoPath('')
      }
    }

    fetchData()
  }, [companyId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      address: address,
      director_full_name: ceo,
      phone: number,
      email: email,
      region_id: regionId,
      checking_account: rs,
      correspondent_account: ks,
      bank_name: bank,
      inn: inn,
      kpp: kpp,
      bin: ogrn,
      bic: bik,
      bank_address: bankAddress,
      sign_cipher: code,
      accreditation_unique_id: num,
    }

    if (logo) {
      data.image = logo
    } else {
      data.image_id = imageId
    }

    const { status, data: companiesAddData } = await R.editCompanies(companyId, data)

    if (status === 200) {
      dispatch(setMessage('Компания успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshHeader())
      navigate('/metriva/company')
    } else if (status === 422) {
      if (companiesAddData.errors) {
        const errors = companiesAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Компания не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к api'))
      setIsDisabledBtn(false)
    }
  }

  return (
    <main className={'main'}>
      <React.Fragment>
        <BackButton to='/metriva/company' label='Назад к странице компании' />

        <div className='box'>
          <h2 style={{ marginBottom: '25px' }}>Основные данные компании</h2>

          <NameCompany validationErrors={validationErrors} />

          <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
            <RegionCompany validationErrors={validationErrors} />

            <AddressCompany validationErrors={validationErrors} />
          </div>

          <LogoCompany
            imageLogoPath={imageLogoPath}
            setImageLogoPath={setImageLogoPath}
            setImageId={setImageId}
          />
        </div>

        <CompanyBank validationErrors={validationErrors} />

        <div style={{ marginTop: '14px' }}>
          <Button
            variant='contained'
            onClick={handleSave}
            disabled={isDisabledBtn}
            sx={{ mt: 1, mr: 1 }}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 20px 11px',
              margin: 0,
              borderRadius: '12px',
              width: '100%',
            }}
          >
            Редактировать компанию
          </Button>
        </div>
      </React.Fragment>
    </main>
  )
}

export default CompanyAdmin
