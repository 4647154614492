// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import PushNotificationsService from '../services/admin/push-notifications.service.tsx'
import SystemNotificationsService from '../services/admin/system-notifications.service.tsx'
import MessagesSupportService from '../services/admin/messages-support.service.tsx'

import R from '../services/app/client-server/request.service.ts'

import { IModelSystemNotifications } from './types.ts'
import { IModelPushNotifications } from './types.ts'

abstract class System {
  protected systemNotifications: IModelSystemNotifications[] = []
  protected pushNotifications: IModelPushNotifications[] = []

  get getSystemNotifications(): IModelSystemNotifications[] {
    return this.systemNotifications
  }
  get getPushNotifications(): IModelPushNotifications[] {
    return this.pushNotifications
  }

  GET_SYSTEM_NOTIFICATIONS(): { text: string }[] {
    return Array(10).fill({ text: 'systemNotificationsLine' })
  }
  GET_PUSH_NOTIFICATIONS(): [] {
    return []
  }

  RenderSystemNotifications() {
    const data: { text: string }[] = this.GET_SYSTEM_NOTIFICATIONS()

    return SystemNotificationsService({ data })
  }

  RenderPushNotifications() {
    return PushNotificationsService
  }
}

class system extends System {
  private static instance: system

  private constructor() {
    super()
  }

  static getInstance(): system {
    if (!system.instance) {
      system.instance = new system()
    }
    return system.instance
  }
}

const os = system.getInstance()
export default os
