import React, { ReactNode } from 'react'
import { Tooltip as MuiTooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTooltip = styled(({ className, ...props }: any) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    borderRadius: '12px 12px 0px 12px',
    backgroundColor: theme.palette.text.primary,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.20)',
    margin: '10px 16px !important',
    padding: '10px 16px',
    fontWeight: 400,
    fontSize: '13px',
  },
}))

interface TooltipProps {
  title: ReactNode
  children: ReactNode
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  disableHoverListener?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  placement = 'top-end',
  children,
  disableHoverListener,
}) => {
  return (
    <StyledTooltip title={title} placement={placement} disableHoverListener={disableHoverListener}>
      <span>{children}</span>
    </StyledTooltip>
  )
}

export default Tooltip
