import React, { FC } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'
import { Container, InfoRow } from './styles.ts'
import { IModelProtocolMi1592MobileShow } from '../../types.models.views.ts'

import SuccessCircleIcon from '../../../img/icon/SuccessCircleIcon.tsx'

interface DataProtocolProps {
  data?: IModelProtocolMi1592MobileShow
}

const ProtocolTransfer: FC<DataProtocolProps> = ({ data }) => {
  const createdBy = `${data?.created_by?.surname ?? ''} ${data?.created_by?.name ?? ''} ${data?.created_by?.patr ?? ''}`
  const createdAt = moment(data?.created_at).format('DD-MM-YYYY')
  const createdTime = moment(data?.created_at).format('HH:mm')

  const sentBy = `${data?.sent_by?.surname ?? ''} ${data?.sent_by?.name ?? ''} ${data?.sent_by?.patr ?? ''}`
  const sentAt = moment(data?.sent_at).format('DD-MM-YYYY')
  const sentTime = moment(data?.sent_at).format('HH:mm')

  const arshinAt = data?.arshin_publication_at
    ? `${moment(data.arshin_publication_at).format('DD-MM-YYYY')} в ${moment(data.arshin_publication_at).format('HH:mm')}`
    : 'нет данных'

  const fsaAt = data?.fsa_publication_at
    ? `${moment(data.fsa_publication_at).format('DD-MM-YYYY')} в ${moment(data.fsa_publication_at).format('HH:mm')}`
    : 'нет данных'

  const status = [
    {
      id: 1,
      icon: <SuccessCircleIcon />,
      label: 'Создан поверетилем',
      value: `${createdBy}   ${createdAt} в ${createdTime}`,
    },
    {
      id: 2,
      icon: <SuccessCircleIcon />,
      label: 'Одобрен к передаче',
      value: `${sentBy}   ${sentAt} в ${sentTime}`,
    },
    {
      id: 3,
      icon: <SuccessCircleIcon active={data?.arshin_publication_at !== null} />,
      label: 'Опубликован в Аршин',
      value: arshinAt,
    },
    {
      id: 4,
      icon: <SuccessCircleIcon />,
      label: '№ свидетельства/извещения',
      value: data?.certificate,
    },
    {
      id: 5,
      icon: <SuccessCircleIcon />,
      label: 'Публикация данных о поверке СИ',
      value:
        data?.arshin_id && data.arshin_id !== null ? (
          <a
            href={`https://fgis.gost.ru/fundmetrology/cm/results/1-${data.arshin_id}`}
            target='_blank'
            style={{ textDecoration: 'none' }}
            onClick={event => event.stopPropagation()}
            rel='noreferrer'
          >
            <Typography
              variant='subtitle2'
              color='#0084E2'
              sx={{
                cursor: 'pointer',
                borderBottom: '1px solid #0084E2',
                fontWeight: '500 !important',
              }}
            >
              Ссылка на ФГИС Аршин
            </Typography>
          </a>
        ) : (
          <Typography variant='subtitle2' color={'#899298'} sx={{ fontWeight: '500 !important' }}>
            Ссылка на ФГИС Аршин
          </Typography>
        ),
    },
    {
      id: 6,
      icon: <SuccessCircleIcon active={data?.fsa_publication_at !== null} />,
      label: 'Опубликован в ФСА',
      value: fsaAt,
    },
  ]

  return (
    <Container style={{ padding: '16px' }}>
      {status.map(({ id, label, value, icon }) => (
        <div key={id} style={{ display: 'flex', alignItems: 'flex-start', gap: '4px' }}>
          {icon}
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography variant='subtitle1'>{label}</Typography>
            <Typography variant='subtitle2'>{value}</Typography>
          </div>
        </div>
      ))}
    </Container>
  )
}

export default ProtocolTransfer
