import { useState, useEffect } from 'react'

function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    let timeoutId = null
    const threshold = 400

    function handleResize() {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setWindowDimensions(prev => {
          const newDims = getWindowDimensions()

          if (
            Math.abs(prev.width - newDims.width) > threshold ||
            Math.abs(prev.height - newDims.height) > threshold
          ) {
            return newDims
          }
          return prev
        })
      }, 100)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowDimensions
}
