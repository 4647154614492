import React, { FC } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'

import { Container, ConclusionBlock, CardBlock, TwoColumns, HalfCard, InfoRow } from './styles.ts'
import { IModelProtocolMi1592MobileShow } from '../../types.models.views.ts'

interface DataProtocolProps {
  data?: IModelProtocolMi1592MobileShow
}

const VerificationResult: FC<DataProtocolProps> = ({ data }) => {
  const methodData = [
    { id: 'method', label: 'Методика', value: data?.method?.name },
    {
      id: 'section',
      label: 'Применяемые пункты или разделы',
      value: `${data?.point?.name ?? ''}${data?.point?.additional ? ` (${data.point.additional})` : ''}`,
    },
    {
      id: 'type',
      label: 'Тип поверки',
      value: data?.point?.verification_type === 'periodic' ? 'Периодическая' : 'Первичная',
    },
    {
      id: 'way',
      label: 'Способ проведения',
      value: data?.point?.verification_method === 'field' ? 'На выезде' : 'В лаборатории',
    },
  ]

  const verificationDates = [
    {
      id: 'performed',
      label: 'Поверка проведена',
      value: moment(data?.verification_date).format('DD-MM-YYYY'),
    },
    {
      id: 'next',
      label: 'Дата следующей поверки',
      value: moment(data?.next_verification_date).format('DD-MM-YYYY'),
    },
  ]

  const verificationMarks = [
    {
      id: 'passport',
      label: 'Знак поверки в паспорте',
      value: data?.mark_passport ? 'Есть' : 'Нет',
    },
    { id: 'device', label: 'Знак поверки на СИ', value: data?.mark_si ? 'Есть' : 'Нет' },
  ]

  return (
    <Container>
      <ConclusionBlock accepted={!!data?.total_accepted}>
        <Typography variant='h2' color={data?.total_accepted ? '#0084E2' : '#FF5963'}>
          Заключение
        </Typography>
        <Typography variant='body1' style={{ fontSize: '1rem' }}>
          На основании результатов поверки средство измерения признано{' '}
          <strong>{data?.total_accepted ? 'пригодным' : 'непригодным'}</strong> к использованию
        </Typography>
      </ConclusionBlock>

      <CardBlock>
        <Typography variant='h2'>Рамки применения методики</Typography>
        {methodData.map(({ id, label, value }) => (
          <InfoRow key={id}>
            <Typography variant='subtitle2' sx={{ width: '150px' }}>
              {label}
            </Typography>
            <Typography variant='subtitle1'>{value}</Typography>
          </InfoRow>
        ))}
      </CardBlock>

      <TwoColumns>
        <HalfCard>
          <Typography variant='h2'>Даты поверки</Typography>
          {verificationDates.map(({ id, label, value }) => (
            <InfoRow key={id}>
              <Typography variant='subtitle2'>{label}</Typography>
              <Typography variant='subtitle1'>{value}</Typography>
            </InfoRow>
          ))}
        </HalfCard>

        <HalfCard>
          <Typography variant='h2'>Знаки поверки</Typography>
          {verificationMarks.map(({ id, label, value }) => (
            <InfoRow key={id}>
              <Typography variant='subtitle2'>{label}</Typography>
              <Typography variant='subtitle1'>{value}</Typography>
            </InfoRow>
          ))}
        </HalfCard>
      </TwoColumns>
    </Container>
  )
}

export default VerificationResult
