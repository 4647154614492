// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useDidMountEffect from '../../admin/hooks/useDidMountEffect.ts'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Tooltip } from '@mui/material'
import css from '../styles.css.ts'
import Accordion from '../../app/components/LocalElements/Accordion/Accordion.jsx'
import InputElement from '../../app/components/LocalElements/Input/InputMUI.jsx'
import { Button } from '@mui/material'

import MainJournals from '../comps/MainJ.tsx'
import PersonJournals from '../comps/PersonJ.tsx'

import R from '../../services/app/client-server/request.service.ts'
import {
  setJournalSettings,
  changeSpecificData,
  setShowMainData,
  setShowPersonalData,
  setShowDopRoleData,
  setShowWeekendData,
} from '../../store/slices/admin/admin.js'
import { setOpen, setMessage, setType } from '../../store/slices/app/comps/snackbar.js'
import {
  refreshCustomUpdate,
  refreshConditions,
  refreshReceiveds,
  refreshIssueCase,
} from '../../store/slices/app/controlers/updater.js'

const { Container, ContentRow } = css

const JournalsView = (): ReactElement => {
  const userRole = useSelector((state: any) => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector((state: any) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state: any) => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const journalsDataMain = useSelector((state: any) => state.adminReducer.journalSettings.mainData)
  const masterArshine = useSelector((state: any) => state.journalReducer.masterArshine)

  const journalsDataPersonal = useSelector(
    (state: any) => state.adminReducer.journalSettings.personalData,
  )
  const journalsDataSpecific = useSelector(
    (state: any) => state.adminReducer.journalSettings.specificData,
  )

  const showMainData = useSelector((state: any) => state.adminReducer.journalSettings.showMainData)
  const showPersonalData = useSelector(
    (state: any) => state.adminReducer.journalSettings.showPersonalData,
  )
  const showDopRoleData = useSelector(
    (state: any) => state.adminReducer.journalSettings.showDopRoleData,
  )
  const showWeekendData = useSelector(
    (state: any) => state.adminReducer.journalSettings.showWeekendData,
  )

  const dispatch = useDispatch()

  const [skipChanges, setSkipChanges] = useState<boolean>(false)
  const [conditions_of_cases_is_including_weekends, set_conditions_of_cases_is_including_weekends] =
    useState<boolean | null>(null)

  const setMainSettingsShow = (param: any): void => {
    dispatch(setShowMainData(param))
  }
  const setPersonalSettingsShow = (param: any): void => {
    dispatch(setShowPersonalData(param))
  }
  const setDopRoleSettingsShow = (param: any): void => {
    dispatch(setShowDopRoleData(param))
  }
  const setWeekendSettingsShow = (param: any): void => {
    dispatch(setShowWeekendData(param))
  }

  // ----------------------------------------------------------------
  // проверочные функции для тестирования - начало
  // ----------------------------------------------------------------

  const changeDataMain = async (): Promise<void> => {
    false && console.log(journalsDataMain)

    const { status, data } = await R.changeJournalSettings(companyId, {
      main: journalsDataMain,
    })

    if (status === 200) {
      dispatch(setOpen(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))

      const { data: updateData } = await R.getJournalSettings(companyId)

      dispatch(setJournalSettings({ value: 'mainData', label: updateData.main }))
    } else {
      dispatch(setOpen(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
    }
  }

  const changeDataPersonal = async (): Promise<void> => {
    false && console.log(journalsDataMain)

    const { status, data } = await R.changeJournalSettings(companyId, {
      personal: journalsDataPersonal,
    })

    if (status === 200) {
      dispatch(setOpen(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))

      const { data: updateData } = await R.getJournalSettings(companyId)

      dispatch(setJournalSettings({ value: 'personalData', label: updateData.personal }))
    } else {
      dispatch(setOpen(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
    }
  }

  // ----------------------------------------------------------------
  // проверочные функции для тестирования - конец
  // ----------------------------------------------------------------

  const changeDataSpecific = async (): Promise<void> => {
    false && console.log(journalsDataSpecific)

    const modJournalsDataSpecific = journalsDataSpecific.map((item: any, index: number) => {
      if (index === 0) return Object.assign({}, item, { journal_type_id: 5 })
      if (index === 1) return Object.assign({}, item, { journal_type_id: 3 })
      if (index === 2)
        return Object.assign({}, item, {
          journal_type_id: 2,
          conditions_of_cases_is_including_weekends: conditions_of_cases_is_including_weekends,
        })
    })

    const { status, data } = await R.changeJournalSettings(companyId, {
      specific: modJournalsDataSpecific,
    })

    if (status === 200) {
      dispatch(setOpen(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))

      const { data: updateData } = await R.getJournalSettings(companyId)

      dispatch(setJournalSettings({ value: 'specificData', label: updateData.specific }))
      dispatch(refreshCustomUpdate(null))

      setTimeout(() => {
        dispatch(refreshConditions(null))
        dispatch(refreshReceiveds(null))
        dispatch(refreshIssueCase(null))
      }, 4000)
    } else {
      dispatch(setOpen(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
    }
  }

  const saveAllSettings = async (): Promise<void> => {
    const { status, data } = await R.changeJournalSettings(companyId, {
      main: journalsDataMain,
      personal: journalsDataPersonal,
    })

    if (status === 200) {
      dispatch(setOpen(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))

      const { data: updateData } = await R.getJournalSettings(companyId)

      dispatch(setJournalSettings({ value: 'personalData', label: updateData.personal }))
      dispatch(refreshCustomUpdate(null))
    } else {
      dispatch(setOpen(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
    }
  }

  const MJ = (
    <React.Fragment>
      <MainJournals />
      <ContentRow
        axios='end'
        gap={10}
        style={{ marginTop: '28px', paddingRight: '4px', display: 'none' }}
      >
        <Button
          onClick={changeDataMain}
          variant='contained'
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 60px 11px',
            margin: 0,
            marginTop: '0px',
            borderRadius: '12px',
          }}
        >
          {'Сохранить изменения'}
        </Button>
        <Button
          onClick={() => setSkipChanges(prev => !!!prev)}
          variant='outlined'
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 60px 11px',
            margin: 0,
            marginTop: '0px',
            borderRadius: '12px',
          }}
        >
          {'Отменить изменения'}
        </Button>
      </ContentRow>
    </React.Fragment>
  )
  const PJ = (
    <React.Fragment>
      <PersonJournals />
      <ContentRow
        axios='end'
        gap={10}
        style={{ marginTop: '28px', paddingRight: '4px', display: 'none' }}
      >
        <Button
          onClick={changeDataPersonal}
          variant='contained'
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 60px 11px',
            margin: 0,
            marginTop: '0px',
            borderRadius: '12px',
          }}
        >
          {'Сохранить изменения'}
        </Button>
        <Button
          onClick={() => setSkipChanges(prev => !!!prev)}
          variant='outlined'
          style={{
            textTransform: 'none',
            fontSize: '15px',
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: '11px 60px 11px',
            margin: 0,
            marginTop: '0px',
            borderRadius: '12px',
          }}
        >
          {'Отменить изменения'}
        </Button>
      </ContentRow>
    </React.Fragment>
  )

  useDidMountEffect((): void => {
    ;(async (): Promise<void> => {
      const { data } = await R.getJournalSettings(companyId)

      false && console.log(data)

      if (userRole === 'super' || userRole === 'admin' || true) {
        dispatch(setJournalSettings({ value: 'mainData', label: data.main }))
        dispatch(setJournalSettings({ value: 'personalData', label: data.personal }))
        dispatch(setJournalSettings({ value: 'specificData', label: data.specific }))
      }
    })()
  }, [companyId, skipChanges])

  useEffect(() => {
    set_conditions_of_cases_is_including_weekends(
      journalsDataSpecific[2].conditions_of_cases_is_including_weekends,
    )
  }, [])

  return (
    <React.Fragment>
      <Container>
        <ContentRow>
          <h2 style={{ marginBottom: '6px' }}>Основные настройки</h2>
        </ContentRow>
        {userRole !== 'metrologist' && userRole !== 'brigadier' && (
          <ContentRow>
            <Accordion
              isExpanded={showMainData}
              expandedSwitcher={setMainSettingsShow}
              title={`Общие журналы`}
              titleSize={'17px'}
              backgroundColor={'white'}
              padding={'14px 8px'}
              dataType={'custom'}
              data={MJ}
            />
          </ContentRow>
        )}
        <ContentRow>
          <Accordion
            isExpanded={showPersonalData}
            expandedSwitcher={setPersonalSettingsShow}
            title={`Персональные журналы`}
            titleSize={'17px'}
            backgroundColor={'white'}
            padding={'14px 8px'}
            dataType={'custom'}
            data={PJ}
          />
        </ContentRow>
        <ContentRow axios='end' gap={10} style={{ marginTop: '8px' }}>
          <Button
            onClick={saveAllSettings}
            variant='contained'
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 60px 11px',
              margin: 0,
              marginTop: '0px',
              borderRadius: '12px',
            }}
          >
            {'Сохранить изменения'}
          </Button>
          <Button
            onClick={() => setSkipChanges(prev => !!!prev)}
            variant='outlined'
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '11px 60px 11px',
              margin: 0,
              marginTop: '0px',
              borderRadius: '12px',
            }}
          >
            {'Отменить изменения'}
          </Button>
        </ContentRow>
      </Container>
      {userRole !== 'metrologist' && userRole !== 'brigadier' && (
        <Container>
          <ContentRow>
            <h2 style={{ marginBottom: '6px' }}>Специальные настройки</h2>
          </ContentRow>
          <ContentRow>
            <Accordion
              isExpanded={showDopRoleData}
              expandedSwitcher={setDopRoleSettingsShow}
              title={`Дополнительные роли`}
              titleSize={'17px'}
              backgroundColor={'white'}
              padding={'14px 8px'}
              dataType={'custom'}
              data={
                <React.Fragment>
                  <div>
                    <span style={{ fontWeight: 600 }}>Журнал выдачи средств поверки</span>
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        alignItems: 'center',
                        width: '100%',
                        gap: '12px',
                        marginTop: '20px',
                      }}
                    >
                      <InputElement
                        type={'text'}
                        placeholder={'Заполните имя и должность'}
                        value={
                          journalsDataSpecific[0] ? journalsDataSpecific[0].issue_cases_issuer : ''
                        }
                        notRequired={true}
                        actions={{
                          change: (param: string) => {
                            dispatch(
                              changeSpecificData({
                                index: 0,
                                value: 'issue_cases_issuer',
                                label: param,
                              }),
                            )
                          },
                        }}
                        pattern={undefined}
                        className={undefined}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            ФИО и должность выдающего комплекты СП
                            <Tooltip
                              title='Данные о сотруднике, который отвечает за выдачу комплектов СП, необходимы для создания записей в журнале выдачи средств поверки'
                              placement='top'
                            >
                              <i
                                className='fas fa-question-circle'
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </div>
                        }
                        length={undefined}
                        style={{ width: '50%' }}
                        multiline={undefined}
                        minRows={undefined}
                        maxRows={undefined}
                        sx={undefined}
                        rows={undefined}
                      />
                      <span style={{ width: '50%', opacity: 0.6 }} />
                    </div>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <span style={{ fontWeight: 600 }}>
                      Журнал учета поступивших на поверку СИ и проведения поверочных работ
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        alignItems: 'center',
                        width: '100%',
                        gap: '12px',
                        marginTop: '20px',
                      }}
                    >
                      <InputElement
                        type={'text'}
                        placeholder={'Заполните имя'}
                        value={
                          journalsDataSpecific[1]
                            ? journalsDataSpecific[1].accounting_of_verifications_receiver
                            : ''
                        }
                        notRequired={true}
                        actions={{
                          change: (param: string) => {
                            dispatch(
                              changeSpecificData({
                                index: 1,
                                value: 'accounting_of_verifications_receiver',
                                label: param,
                              }),
                            )
                          },
                        }}
                        pattern={undefined}
                        className={undefined}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            ФИО принимающего СИ
                            <Tooltip
                              title='Данные о сотруднике, который отвечает за приём СИ, необходимы для создания корректных записей о проведении поверочных работ в лаборатории'
                              placement='top'
                            >
                              <i
                                className='fas fa-question-circle'
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </div>
                        }
                        length={undefined}
                        style={{ width: '50%' }}
                        multiline={undefined}
                        minRows={undefined}
                        maxRows={undefined}
                        sx={undefined}
                        rows={undefined}
                      />
                      <InputElement
                        type={'text'}
                        placeholder={'Заполните имя'}
                        value={
                          journalsDataSpecific[1]
                            ? journalsDataSpecific[1].accounting_of_verifications_issuer
                            : ''
                        }
                        notRequired={true}
                        actions={{
                          change: (param: string) => {
                            dispatch(
                              changeSpecificData({
                                index: 1,
                                value: 'accounting_of_verifications_issuer',
                                label: param,
                              }),
                            )
                          },
                        }}
                        pattern={undefined}
                        className={undefined}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            ФИО выдающего СИ
                            <Tooltip
                              title='Данные о сотруднике, который отвечает за выдачу СИ, необходимы для создания корректных записей о проведении поверочных работ в лаборатории'
                              placement='top'
                            >
                              <i
                                className='fas fa-question-circle'
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </div>
                        }
                        length={undefined}
                        style={{ width: '50%' }}
                        multiline={undefined}
                        minRows={undefined}
                        maxRows={undefined}
                        sx={undefined}
                        rows={undefined}
                      />
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </ContentRow>
          <ContentRow>
            <Accordion
              isExpanded={showWeekendData}
              expandedSwitcher={setWeekendSettingsShow}
              title={`Регистрация данных в выходные и праздничные дни`}
              titleSize={'17px'}
              backgroundColor={'white'}
              padding={'14px 8px'}
              dataType={'custom'}
              data={
                <React.Fragment>
                  <span>Журнал регистрации условий хранения средств поверки</span>
                  <RadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name='controlled-radio-buttons-group'
                    value={conditions_of_cases_is_including_weekends}
                    onChange={event =>
                      set_conditions_of_cases_is_including_weekends(event.target.value === 'true')
                    }
                    style={{
                      flexDirection: 'row',
                      fontFamily: "'Wix Madefor Display', sans-serif",
                      color: '#123532',
                      fontSize: '16px',
                      fontWeight: '400',
                      marginTop: '0px',
                      paddingLeft: '5px',
                    }}
                  >
                    <FormControlLabel
                      value={'true'}
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: '22px !important',
                            },
                          }}
                        />
                      }
                      label='Создавать записи ежедневно'
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: "'Wix Madefor Display', sans-serif !important",
                          fontSize: '15px !important',
                          fontWeight: 400,
                          color: '#132532',
                        },
                      }}
                    />
                    <FormControlLabel
                      value={'false'}
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: '22px !important',
                            },
                          }}
                        />
                      }
                      label='Исключая официальные выходные'
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: "'Wix Madefor Display', sans-serif !important",
                          fontSize: '15px !important',
                          fontWeight: 400,
                          color: '#132532',
                        },
                      }}
                    />
                  </RadioGroup>
                </React.Fragment>
              }
            />
          </ContentRow>
          <ContentRow axios='end' gap={10} style={{ marginTop: '8px' }}>
            <Button
              onClick={changeDataSpecific}
              variant='contained'
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 60px 11px',
                margin: 0,
                marginTop: '0px',
                borderRadius: '12px',
              }}
            >
              {'Сохранить изменения'}
            </Button>
            <Button
              onClick={() => setSkipChanges(prev => !!!prev)}
              variant='outlined'
              style={{
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 60px 11px',
                margin: 0,
                marginTop: '0px',
                borderRadius: '12px',
              }}
            >
              {'Отменить изменения'}
            </Button>
          </ContentRow>
        </Container>
      )}
      {masterArshine === 'super' && masterArshine === 'admin' && (
        <Container>
          <ContentRow>
            <h2>Уведомления</h2>
          </ContentRow>
          <ContentRow>
            <span style={{ color: 'gray', margin: '24px auto 30px' }}>
              Отправка уведомлений не настроена
            </span>
          </ContentRow>
          <ContentRow></ContentRow>
        </Container>
      )}
    </React.Fragment>
  )
}

export default JournalsView
