// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Checkbox } from '@mui/material'
import R from '../../../../services/app/client-server/request.service.ts'
import Table from '../../LocalElements/Table/Table'
import RightSideContainer from '../../LocalElements/RightSideContainer/RightSideContainer'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import InnerName from '../../LocalElements/Filter/IssueInnerName'
import Users from '../../LocalElements/Filter/IssueCases'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Csv from '../../../../img/icon/CsvIcon'
import Excel from '../../../../img/icon/Excel'
import Pdf from '../../../../img/icon/PdfIcon.tsx'
import ArchiveIcon from '../../../../img/icon/ArchiveIcon'
import FilterIcon from '../../../../img/icon/FilterIcon'
import PencilIcon from '../../../../img/icon/PencilIcon.tsx'
import DeleteIcon from '../../../../img/icon/DeleteIcon'
import { setOpen as setOpenRightContainer } from '../../../../store/slices/app/comps/rightContainer'
import CloseIcon from '@mui/icons-material/Close'
import { Typography, Fab, useMediaQuery } from '@mui/material'
import { refreshIssueCase, refreshArchives } from '../../../../store/slices/app/controlers/updater'
import SearchQuery from '../../LocalElements/Search/SearchQuery'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBA,
  showBD,
  setBD,
  resetState,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenSnackbar,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import {
  setSearchQuery,
  changeIssueCaseFilter,
  resetIssueCaseFilter,
  setArchiveTo,
  resetArchiveFrom,
  resetArchiveTo,
} from '../../../../store/slices/app/views/journals'
import useExcelCsvPdf from '../../../hooks/useExcelCsv'
import Tooltip from '../../../../ui/tooltip/index.tsx'
import Button from '../../../../ui/button/index.tsx'
import RadioButton from '../../../../ui/radio-button/index.tsx'
import theme from '../../../../utils/theme.ts'
import { Container, CountLabel, Filter, IconFrame, Wrapper, FrameFilter } from '../style'
import { archiveTitle, archiveMessage, ModalContent } from '../archiveModal/archiveModal'

const TabIssueCase = () => {
  const userRole = useSelector(state => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { handleDownload } = useExcelCsvPdf()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const data = useSelector(state => state.journalReducer.dataIssueCase)
  const searchQuery = useSelector(state => state.journalReducer.searchQuery)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const journalId = useSelector(state => state.journalReducer.journalId)
  const entryId = useSelector(state => state.journalReducer.entryId)
  const measurementId = useSelector(state => state.journalReducer.issueCaseFilter.measurementId)
  const innerName = useSelector(state => state.journalReducer.issueCaseFilter.innerName)
  const issuedDateFrom = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateFrom)
  const issuedDateTo = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateTo)
  const receivedByName = useSelector(state => state.journalReducer.issueCaseFilter.receivedByName)
  const isReturned = useSelector(state => state.journalReducer.issueCaseFilter.isReturned)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateFrom,
  )
  const verificationDateTo = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateTo,
  )
  const completenessStatus = useSelector(
    state => state.journalReducer.issueCaseFilter.completenessStatus,
  )
  const createdByRole = useSelector(state => state.journalReducer.issueCaseFilter.createdByRole)
  const archiveDateFromBySettings = useSelector(
    state => state.adminReducer.journalSettings.mainData,
  ).filter(row => row.journal_type_id === journalId)[0]?.create_date
  const archiveDateTo = useSelector(state => state.journalReducer.archiveTo)

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementId) filtersCount++
    if (innerName) filtersCount++
    if (issuedDateFrom) filtersCount++
    if (issuedDateTo) filtersCount++
    if (receivedByName) filtersCount++
    if (isReturned) filtersCount++
    if (verificationDateFrom) filtersCount++
    if (verificationDateTo) filtersCount++
    if (completenessStatus) filtersCount++
    if (createdByRole) filtersCount++

    if (
      !measurementId &&
      !innerName &&
      !issuedDateFrom &&
      !issuedDateTo &&
      !receivedByName &&
      !isReturned &&
      !verificationDateFrom &&
      !verificationDateTo &&
      !completenessStatus &&
      !createdByRole
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementId,
    innerName,
    issuedDateFrom,
    issuedDateTo,
    receivedByName,
    isReturned,
    verificationDateFrom,
    verificationDateTo,
    completenessStatus,
    createdByRole,
  ])

  const changeSearchQuery = param => {
    dispatch(setSearchQuery(param))
  }

  const handleResetFilter = () => {
    dispatch(resetIssueCaseFilter())
  }

  const changeMeasurementId = param => {
    dispatch(changeIssueCaseFilter({ value: 'measurementId', label: param }))
  }

  const changeInnerName = param => {
    dispatch(changeIssueCaseFilter({ value: 'innerName', label: param }))
  }

  const changeUserId = param => {
    dispatch(changeIssueCaseFilter({ value: 'receivedByName', label: param }))
  }

  const changeIsReturned = event => {
    dispatch(changeIssueCaseFilter({ value: 'isReturned', label: event.target.value }))
  }

  const changeCompletenessStatus = event => {
    dispatch(changeIssueCaseFilter({ value: 'completenessStatus', label: event.target.value }))
  }

  const changeCreatedByRole = event => {
    dispatch(changeIssueCaseFilter({ value: 'createdByRole', label: event.target.value }))
  }

  const handleIssuedDateFromChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'issuedDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'issuedDateFrom', label: '' }))
    }
  }
  const handleIssuedDateToChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'issuedDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'issuedDateTo', label: '' }))
    }
  }
  const handleVerificationDateFromChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'verificationDateFrom',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'verificationDateFrom', label: '' }))
    }
  }
  const handleVerificationDateToChange = date => {
    if (date) {
      dispatch(
        changeIssueCaseFilter({
          value: 'verificationDateTo',
          label: date.format('YYYY-MM-DD'),
        }),
      )
    } else {
      dispatch(changeIssueCaseFilter({ value: 'verificationDateTo', label: '' }))
    }
  }

  const handleDelete = async journalId => {
    const { status } = await R.deleteIssueCase(journalId, companyId)
    if (status === 200) {
      dispatch(refreshIssueCase())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Запись удалена'))
      dispatch(setOpen(false))
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  const handleReturnedChange = async journalId => {
    const { status } = await R.editIssueCaseReturn(journalId, companyId)

    if (status === 200) {
      dispatch(refreshIssueCase())
      dispatch(setType('success'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Статус пользователя изменён.'))
      dispatch(setOpen(false))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  let colData = [
    {
      field: 'number',
      headerName: '№ п/п',
    },
    {
      field: 'innerName',
      headerName: '№ комплекта СП',
    },
    {
      field: 'issueDate',
      headerName: 'Дата выдачи',
    },
    {
      field: 'issuedByName',
      headerName: 'Выдавший (ФИО, должность)',
    },
    {
      field: 'receivedByName',
      headerName: 'Принявший (ФИО и должность)',
    },
    {
      field: 'receivedDate',
      headerName: 'Дата возврата',
    },
    {
      field: 'isReturned',
      headerName: 'Отметка о возврате',
      renderCell: params => {
        const returnedDate = moment(params.row.returnedAt).format('DD-MM-YYYY')
        const currentDate = moment().format('DD-MM-YYYY')
        const isDisabled = params.row.isAutogenerated && returnedDate !== currentDate

        if (isDisabled) {
          return (
            <Tooltip title='Для записей созданных системой, удаление отметки о возврате задним числом недоступно'>
              <span>
                <Checkbox
                  checked={params.row.isReturned}
                  disabled
                  onClick={event => event.stopPropagation()}
                />
              </span>
            </Tooltip>
          )
        } else {
          return (
            <Checkbox
              checked={params.row.isReturned}
              onChange={event => {
                event.stopPropagation()
                handleReturnedChange(params.row.id, event.target.checked)
              }}
              onClick={event => event.stopPropagation()}
            />
          )
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: params => {
        const returnedDate = moment(params.row.returnedAt).format('DD-MM-YYYY')
        const currentDate = moment().format('DD-MM-YYYY')
        const isDisabled = params.row.isAutogenerated && returnedDate !== currentDate

        return [
          <div
            key={params.id}
            style={{
              display: entryId ? 'none' : 'flex',
              flexFlow: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {isDisabled ? (
              <Tooltip title='Редактирование системных записей с отметкой о возврате возможно только в рамках текущего дня'>
                <div>
                  <PencilIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактировать запись'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(params.row.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            )}

            <Tooltip title='Удалить запись'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(showBA(true))
                  dispatch(setBA('Подтвердить'))
                  dispatch(setTitle('Вы действительно хотите удалить запись?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(params.row.id)))
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>,
        ]
      },
    },
  ].filter(row => {
    if (!!!entryId) {
      return row
    } else {
      if (row.field !== 'actions') {
        return row
      }
    }
  })

  const handleAddClick = () => {
    navigate('/metriva/journals/create_line_issue_case')
  }

  const handleEditClick = id => {
    navigate(`/metriva/journals/${id}/edit_issue_case`)
  }

  const handleArchiveClick = async () => {
    const { status, data } = await R.archiveIssueCase(companyId, {
      date_from: archiveDateFromBySettings,
      date_to: archiveDateTo,
    })

    if (status === 200) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('success'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(false))
      dispatch(refreshIssueCase())
      dispatch(refreshArchives())
      dispatch(resetArchiveFrom())
      dispatch(resetArchiveTo())
      dispatch(resetState())
    } else if (status === 409) {
      dispatch(
        setTitle(
          <Typography variant='subtitle1' sx={{ color: theme.palette.secondary.dark }}>
            Архивация приостановлена
          </Typography>,
        ),
      )
      dispatch(
        setChildren(
          <div style={{ width: '620px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant='subtitle1'>
              Внимание! Процесс архивации не может быть завершен из-за отсутствия важных данных в
              некоторых записях документа
            </Typography>
            <Typography variant='subtitle1'>
              Пожалуйста, найдите такие записи с помощью фильтра "Заполнение записи", дождитесь
              появления / внесите недостающие данные и повторите процесс архивации.
            </Typography>
          </div>,
        ),
      )
      dispatch(setBD('Принять'))
      dispatch(showBA(false))
      dispatch(setOpen(true))
    } else if (status === 422) {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
    } else {
      dispatch(setOpenSnackbar(true))
      dispatch(setType('error'))
      dispatch(setMessage(data.message))
      dispatch(setOpen(true))
    }
  }

  const handleDownloadSelectionPdf = async () => {
    const { status, data: responseData } = await R.getArchiveEntriesPdf(
      companyId,
      `?ids[]=${entryId}`,
    )

    const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Zhurnal_vydachi_sredstv_poverki_(arhiv)_${currentDate}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenSnackbar(true))
      dispatch(setMessage('Ошибка при загрузке документов.'))
    }
  }

  useEffect(() => {
    if (archiveDateFromBySettings && archiveDateTo) {
      dispatch(showBA(true))
      dispatch(setConfirmAction(handleArchiveClick))
    } else {
      dispatch(showBA(false))
    }
  }, [archiveDateFromBySettings, archiveDateTo])

  useEffect(() => {
    return () => {
      handleResetFilter()
    }
  }, [])

  const modalContent = (
    <ModalContent
      archiveDateFromBySettings={archiveDateFromBySettings}
      archiveDateTo={archiveDateTo}
      setArchiveTo={setArchiveTo}
      dispatch={dispatch}
    />
  )

  const StaticControls = (
    <IconFrame>
      {count > 0 ? (
        <Tooltip title='Для скачивания PDF отключите фильтры'>
          <div style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}>
            <Pdf />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title='Скачать в формате PDF'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => {
              !entryId && handleDownload('pdf')
              !!entryId && handleDownloadSelectionPdf()
            }}
          >
            <Pdf />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Csv'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('csv')}
          >
            <Csv />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <Tooltip title='Скачать документ в Excel'>
          <div
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => handleDownload('excel')}
          >
            <Excel />
          </div>
        </Tooltip>
      )}
      {!entryId && (
        <React.Fragment>
          {count > 0 ? (
            <Tooltip title='Для архивации документа отключите фильтры'>
              <div
                style={{ cursor: 'default', opacity: '40%', display: 'flex', alignItems: 'center' }}
              >
                <ArchiveIcon />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title='Архивировать документ'>
              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <ArchiveIcon
                  onClick={() => {
                    dispatch(setTitle('Архивация документа'))
                    dispatch(setChildren(modalContent))
                    dispatch(setBA('Архивировать'))
                    dispatch(setBD('Отменить'))
                    dispatch(showBD(true))
                    dispatch(setConfirmAction(handleArchiveClick))
                    dispatch(setOpen(true))
                  }}
                />
              </div>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </IconFrame>
  )

  return (
    <React.Fragment>
      <RightSideContainer blockTitle={'Фильтры'}>
        <Wrapper>
          <FrameFilter>
            <Typography variant='subtitle1'>Область измерения </Typography>
            <Measurement
              measurementId={measurementId}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>Номер комплекта СП </Typography>
            <InnerName innerName={innerName} setInnerName={changeInnerName} />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>Принявший (ФИО и должность)</Typography>
            <Users userId={receivedByName} setUserId={changeUserId} />
          </FrameFilter>

          <FrameFilter>
            <Typography variant='subtitle1'>Отметки о возврате</Typography>
            <RadioButton
              value={isReturned}
              onChange={changeIsReturned}
              trueLabel={'Да'}
              falseLabel={'Нет'}
              trueValue={'checked'}
              falseValue={'not-checked'}
            />
          </FrameFilter>

          {!entryId && (
            <FrameFilter>
              <Typography variant='subtitle1'>Заполнение записи</Typography>
              <RadioButton
                value={completenessStatus}
                onChange={changeCompletenessStatus}
                trueLabel={'Частичное'}
                falseLabel={'Полное'}
                trueValue={'non-complete'}
                falseValue={'complete'}
              />
            </FrameFilter>
          )}

          <FrameFilter>
            <Typography variant='subtitle1'>Создатель записи</Typography>
            <RadioButton
              value={createdByRole}
              onChange={changeCreatedByRole}
              trueLabel={'Система'}
              falseLabel={'Пользователь'}
              trueValue={'system'}
              falseValue={'user'}
            />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата выдачи</Typography>
            <DatePicker selectedDate={issuedDateFrom} dateChange={handleIssuedDateFromChange} />

            <DatePicker selectedDate={issuedDateTo} dateChange={handleIssuedDateToChange} />
          </FrameFilter>
          <FrameFilter>
            <Typography variant='subtitle1'>Дата создания записи</Typography>
            <DatePicker
              selectedDate={verificationDateFrom}
              dateChange={handleVerificationDateFromChange}
            />

            <DatePicker
              selectedDate={verificationDateTo}
              dateChange={handleVerificationDateToChange}
            />
          </FrameFilter>
          <Button
            onClick={handleResetFilter}
            label='Сбросить все значения'
            color='secondary'
            fullWidth
          />
        </Wrapper>
      </RightSideContainer>
      <Table
        colData={colData}
        rowData={data.map(item => ({
          id: item.id,
          number: item?.number,
          innerName: item?.inner_name,
          issueDate: item?.issued_date,
          issuedByName: item?.issued_by_name,
          receivedByName: item?.received_by_name,
          receivedDate: item?.received_date ? item?.received_date : '-',
          isReturned: item?.is_returned,
          isAutogenerated: item?.is_autogenerated,
          returnedAt: item?.returned_at,
        }))}
        hasCheckboxSelection={false}
        showToolbar={true}
        noCreating={!entryId ? false : true}
        actions={{
          static: StaticControls,
          dinamic: null,
        }}
        filtersVariant={'server'}
        addButtonCallback={handleAddClick}
        setIsDrawerOpenServer={() => {
          dispatch(setOpenRightContainer(true))
        }}
        noRowsText={'Записи отсутствуют'}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </React.Fragment>
  )
}

export default TabIssueCase
