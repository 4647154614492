// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../../services/app/client-server/axios-config.service'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import R from '../../../services/app/client-server/request.service.ts'
import { changeData } from '../../../store/slices/app/views/cases'
import { LabelFrame } from '../../pages/Cases/style'
import { Typography } from '@mui/material'

const Kits = () => {
  const token = useSelector(state => state.authReducer.authData.token)
  const dispatch = useDispatch()
  const [kits, setKits] = useState([])
  const [selectedKits, setSelectedKits] = useState({})
  const methodPointIds = useSelector(state => state.casesReducer.createData.methodPoints)

  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const referenceKits = kits.filter(kit => kit.kit_group_id === 1)
  const verificationKits = kits.filter(kit => kit.kit_group_id === 2)
  const auxiliaryEquipment = kits.filter(kit => kit.kit_group_id === 3)
  const deviceIds = useSelector(state => state.casesReducer.createData.deviceIds)

  const [data, setData] = useState([])
  const optionsLitsList = {}

  kits.forEach(kit => {
    optionsLitsList[kit.id] = data
      ? data
          .filter(item => item.device_id === kit.id)
          .map(data => ({
            value: data.id,
            label: data.manufacturer_number,
          }))
      : []
  })

  const getValue = (selectedOption, kitId) => {
    setSelectedKits(prevSelectedKits => {
      const updatedSelectedKits = {
        ...prevSelectedKits,
        [kitId]: selectedOption ? selectedOption.value : null,
      }
      const updatedKitIds = Object.values(updatedSelectedKits).filter(id => id !== null)
      dispatch(changeData({ value: 'kitIds', label: updatedKitIds }))

      return updatedSelectedKits
    })
  }

  useEffect(() => {
    if (methodPointIds.length === 0) {
      return
    }

    const fetchKits = async () => {
      try {
        const uniquePointIds = [...new Set(methodPointIds)]
        const pointsIds = uniquePointIds.map(id => `point_ids[]=${id}`).join('&')
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/devices/list-for-case?${pointsIds}`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const kits = response.data.data
        setKits(kits)
      } catch (error) {
        console.error(error)
      }
    }

    fetchKits()
  }, [methodPointIds, token])

  useEffect(() => {
    if (deviceIds.length > 0) {
      const initialSelectedKits = deviceIds.reduce((acc, { deviceId, id }) => {
        acc[deviceId] = id
        return acc
      }, {})
      setSelectedKits(initialSelectedKits)

      const updatedKitIds = deviceIds.map(item => item.id)
      dispatch(changeData({ value: 'kitIds', label: updatedKitIds }))
    }
  }, [deviceIds, dispatch])

  useEffect(() => {
    ;(async () => {
      const { data: listKits } = await R.getKitsList(`?company_id=${companyId}`)
      const data = listKits.data
      setData(data)
    })()
  }, [companyId])

  return (
    <React.Fragment>
      <Typography
        variant='body1'
        style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 500 }}
      >
        СИ, применяемые в качестве эталонов
      </Typography>
      {referenceKits.map(kit => (
        <LabelFrame sx={{ width: '50%' }} key={kit.id}>
          <Selector
            placeholder={
              kit.is_required
                ? 'Выберите эталон из списка'
                : 'Выберите эталон из списка (необязательно)'
            }
            options={optionsLitsList[kit.id]}
            isClearable={true}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных эталонов'}
            value={
              selectedKits[kit.id]
                ? optionsLitsList[kit.id].find(option => option.value === selectedKits[kit.id])
                : null
            }
            onChange={selectedOption => getValue(selectedOption, kit.id)}
            isRequired={kit.is_required}
            label={{
              text: kit.name,
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </LabelFrame>
      ))}

      <Typography variant='body1' style={{ marginTop: '6px', fontSize: '15px', fontWeight: 500 }}>
        СИ, применяемые в ходе поверки
      </Typography>
      {verificationKits.map(kit => (
        <LabelFrame sx={{ width: '50%', marginTop: '10px' }} key={kit.id}>
          <Selector
            placeholder={
              kit.is_required
                ? 'Выберите эталон из списка'
                : 'Выберите эталон из списка (необязательно)'
            }
            options={optionsLitsList[kit.id]}
            isClearable={true}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных эталонов'}
            value={
              selectedKits[kit.id]
                ? optionsLitsList[kit.id].find(option => option.value === selectedKits[kit.id])
                : null
            }
            onChange={selectedOption => getValue(selectedOption, kit.id)}
            isRequired={kit.is_required}
            label={{
              text: kit.name,
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </LabelFrame>
      ))}

      {auxiliaryEquipment.length > 0 && (
        <Typography variant='body1' style={{ marginTop: '6px', fontSize: '15px', fontWeight: 500 }}>
          Вспомогательное оборудование
        </Typography>
      )}
      {auxiliaryEquipment.map(kit => (
        <LabelFrame sx={{ width: '50%', marginTop: '10px' }} key={kit.id}>
          <Selector
            placeholder={
              kit.is_required
                ? 'Выберите эталон из списка'
                : 'Выберите эталон из списка (необязательно)'
            }
            options={optionsLitsList[kit.id]}
            isClearable={true}
            isSearchable={false}
            noOptionsMessage={() => 'Нет доступных эталонов'}
            value={
              selectedKits[kit.id]
                ? optionsLitsList[kit.id].find(option => option.value === selectedKits[kit.id])
                : null
            }
            onChange={selectedOption => getValue(selectedOption, kit.id)}
            isRequired={kit.is_required}
            label={{
              text: kit.name,
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </LabelFrame>
      ))}
    </React.Fragment>
  )
}

export default Kits
