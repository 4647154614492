import React, { useState, useEffect } from 'react'
import Selector from '../Selector/reactSelect/Selector'

import R from '../../../../services/app/client-server/request.service.ts'

const FilterMeasurements = ({ measurementId, setMeasurementId, label, isRequired = true }) => {
  const [data, setData] = useState([])

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOption => {
    setMeasurementId(selectedOption ? selectedOption.value : '')
  }

  useEffect(() => {
    ;(async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  return (
    <Selector
      placeholder={'Выберите область измерения'}
      options={options}
      onChange={getValue}
      isRequired={isRequired}
      isClearable={true}
      isSearchable={true}
      value={measurementId ? options.find(option => option.value === measurementId) : null}
      noOptionsMessage={() => 'Нет доступных областей измерения'}
      label={label}
    />
  )
}

export default FilterMeasurements
