// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { Button, Typography, Tooltip, Popover } from '@mui/material'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import Input from '../../../../../../../ui/input/index.tsx'
import AccordionComponent from '../../../../../LocalElements/Accordion/Accordion.jsx'

import useGenerateProtocolDataMi1592 from '../../../../../../hooks/useGenerateProtocolDataMi1592'
import { setShow } from '../../../../../../../store/slices/app/comps/feedback'
import { changeProperty } from '../../state'
import { setName } from '../../../../../../../store/slices/app/controlers/pageName'
import { toggleEnableStep } from '../../../../../../../store/slices/app/controlers/stepper'

import R from '../../../../../../../services/app/client-server/request.service.ts'

import css from '../../style.css'

const {
  Container,
  IndicationBox,
  IndicationBoxLastElement,
  QActualBox,
  InputQActualElement,
  ParagraphIndications,
} = css

const Indications = () => {
  const dispatch = useDispatch()

  const [tariffIds, setTariffIds] = useState([])

  const [expanded_1, setExpanded_1] = useState(true)
  const [expanded_2, setExpanded_2] = useState(false)
  const [expanded_3, setExpanded_3] = useState(false)
  const [expanded_4, setExpanded_4] = useState(false)
  const [expanded_5, setExpanded_5] = useState(false)
  const [expanded_6, setExpanded_6] = useState(false)
  const [expanded_7, setExpanded_7] = useState(false)
  const [expanded_8, setExpanded_8] = useState(false)
  const [expanded_9, setExpanded_9] = useState(false)

  const { generateValuesStartFinish, defaultValuesStartFinish, generateQactual } =
    useGenerateProtocolDataMi1592()

  const [startEndDataUpdate, setStartEndDataUpdate] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [expandAll, setExpandAll] = useState(false)

  const open = Boolean(anchorEl)

  const id = open ? 'validate-check-popover' : undefined

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_max = useSelector(state => state.mi15922015Reducer.q_max)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)

  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)

  const q_i_1 = useSelector(state => state.mi15922015Reducer.q_i_1)
  const q_i_2 = useSelector(state => state.mi15922015Reducer.q_i_2)
  const q_i_3 = useSelector(state => state.mi15922015Reducer.q_i_3)
  const q_i_4 = useSelector(state => state.mi15922015Reducer.q_i_4)
  const q_i_5 = useSelector(state => state.mi15922015Reducer.q_i_5)
  const q_i_6 = useSelector(state => state.mi15922015Reducer.q_i_6)
  const q_i_7 = useSelector(state => state.mi15922015Reducer.q_i_7)
  const q_i_8 = useSelector(state => state.mi15922015Reducer.q_i_8)
  const q_i_9 = useSelector(state => state.mi15922015Reducer.q_i_9)

  const start_1 = useSelector(state => state.mi15922015Reducer.start_1)
  const start_2 = useSelector(state => state.mi15922015Reducer.start_2)
  const start_3 = useSelector(state => state.mi15922015Reducer.start_3)
  const start_4 = useSelector(state => state.mi15922015Reducer.start_4)
  const start_5 = useSelector(state => state.mi15922015Reducer.start_5)
  const start_6 = useSelector(state => state.mi15922015Reducer.start_6)
  const start_7 = useSelector(state => state.mi15922015Reducer.start_7)
  const start_8 = useSelector(state => state.mi15922015Reducer.start_8)
  const start_9 = useSelector(state => state.mi15922015Reducer.start_9)

  const finish_1 = useSelector(state => state.mi15922015Reducer.finish_1)
  const finish_2 = useSelector(state => state.mi15922015Reducer.finish_2)
  const finish_3 = useSelector(state => state.mi15922015Reducer.finish_3)
  const finish_4 = useSelector(state => state.mi15922015Reducer.finish_4)
  const finish_5 = useSelector(state => state.mi15922015Reducer.finish_5)
  const finish_6 = useSelector(state => state.mi15922015Reducer.finish_6)
  const finish_7 = useSelector(state => state.mi15922015Reducer.finish_7)
  const finish_8 = useSelector(state => state.mi15922015Reducer.finish_8)
  const finish_9 = useSelector(state => state.mi15922015Reducer.finish_9)

  const v_y_i_1 = useSelector(state => state.mi15922015Reducer.v_y_i_1)
  const v_y_i_2 = useSelector(state => state.mi15922015Reducer.v_y_i_2)
  const v_y_i_3 = useSelector(state => state.mi15922015Reducer.v_y_i_3)
  const v_y_i_4 = useSelector(state => state.mi15922015Reducer.v_y_i_4)
  const v_y_i_5 = useSelector(state => state.mi15922015Reducer.v_y_i_5)
  const v_y_i_6 = useSelector(state => state.mi15922015Reducer.v_y_i_6)
  const v_y_i_7 = useSelector(state => state.mi15922015Reducer.v_y_i_7)
  const v_y_i_8 = useSelector(state => state.mi15922015Reducer.v_y_i_8)
  const v_y_i_9 = useSelector(state => state.mi15922015Reducer.v_y_i_9)

  const mobileIndicationsExpanded1 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded1,
  )
  const mobileIndicationsExpanded2 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded2,
  )
  const mobileIndicationsExpanded3 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded3,
  )
  const mobileIndicationsExpanded4 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded4,
  )
  const mobileIndicationsExpanded5 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded5,
  )
  const mobileIndicationsExpanded6 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded6,
  )
  const mobileIndicationsExpanded7 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded7,
  )
  const mobileIndicationsExpanded8 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded8,
  )
  const mobileIndicationsExpanded9 = useSelector(
    state => state.mi15922015Reducer.mobileIndicationsExpanded9,
  )

  const enableNextStep = useMemo(() => {
    if (
      q_i_1 &&
      q_i_2 &&
      q_i_3 &&
      q_i_4 &&
      q_i_5 &&
      q_i_6 &&
      q_i_7 &&
      q_i_8 &&
      q_i_9 &&
      start_1 &&
      start_2 &&
      start_3 &&
      start_4 &&
      start_5 &&
      start_6 &&
      start_7 &&
      start_8 &&
      start_9 &&
      finish_1 &&
      finish_2 &&
      finish_3 &&
      finish_4 &&
      finish_5 &&
      finish_6 &&
      finish_7 &&
      finish_8 &&
      finish_9 &&
      v_y_i_1 &&
      v_y_i_2 &&
      v_y_i_3 &&
      v_y_i_4 &&
      v_y_i_5 &&
      v_y_i_6 &&
      v_y_i_7 &&
      v_y_i_8 &&
      v_y_i_9 &&
      q_actual
    )
      return true
    else return false
  }, [
    q_i_1,
    q_i_2,
    q_i_3,
    q_i_4,
    q_i_5,
    q_i_6,
    q_i_7,
    q_i_8,
    q_i_9,
    start_1,
    start_2,
    start_3,
    start_4,
    start_5,
    start_6,
    start_7,
    start_8,
    start_9,
    finish_1,
    finish_2,
    finish_3,
    finish_4,
    finish_5,
    finish_6,
    finish_7,
    finish_8,
    finish_9,
    v_y_i_1,
    v_y_i_2,
    v_y_i_3,
    v_y_i_4,
    v_y_i_5,
    v_y_i_6,
    v_y_i_7,
    v_y_i_8,
    v_y_i_9,
    q_actual,
  ])

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleQ_actualChange = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_actual', label: value }))
  }

  const handleQ_i_1Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_1',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_1Change = param => {
    dispatch(
      changeProperty({
        value: 'start_1',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_1Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_1',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_1Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_1',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_1 = (parseFloat(finish_1) - parseFloat(start_1)).toFixed(4)
  const permissible_error_1 = (
    ((parseFloat(v_u_i_1) - parseFloat(v_y_i_1)) / parseFloat(v_y_i_1)) *
    100
  ).toFixed(2)
  const indicationPass_1 =
    permissible_error_1 >= -parseFloat(q_min_limit) &&
    permissible_error_1 <= parseFloat(q_min_limit)

  const handleQ_i_2Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_2',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_2Change = param => {
    dispatch(
      changeProperty({
        value: 'start_2',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_2Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_2',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_2Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_2',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_2 = (parseFloat(finish_2) - parseFloat(start_2)).toFixed(4)
  const permissible_error_2 = (
    ((parseFloat(v_u_i_2) - parseFloat(v_y_i_2)) / parseFloat(v_y_i_2)) *
    100
  ).toFixed(2)
  const indicationPass_2 =
    permissible_error_2 >= -parseFloat(q_min_limit) &&
    permissible_error_2 <= parseFloat(q_min_limit)

  const handleQ_i_3Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_3',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_3Change = param => {
    dispatch(
      changeProperty({
        value: 'start_3',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_3Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_3',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_3Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_3',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_3 = (parseFloat(finish_3) - parseFloat(start_3)).toFixed(4)
  const permissible_error_3 = (
    ((parseFloat(v_u_i_3) - parseFloat(v_y_i_3)) / parseFloat(v_y_i_3)) *
    100
  ).toFixed(2)
  const indicationPass_3 =
    permissible_error_3 >= -parseFloat(q_min_limit) &&
    permissible_error_3 <= parseFloat(q_min_limit)

  const handleQ_i_4Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_4',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_4Change = param => {
    dispatch(
      changeProperty({
        value: 'start_4',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_4Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_4',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_4Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_4',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_4 = (parseFloat(finish_4) - parseFloat(start_4)).toFixed(4)
  const permissible_error_4 = (
    ((parseFloat(v_u_i_4) - parseFloat(v_y_i_4)) / parseFloat(v_y_i_4)) *
    100
  ).toFixed(2)
  const indicationPass_4 =
    permissible_error_4 >= -parseFloat(q_max_limit) &&
    permissible_error_4 <= parseFloat(q_max_limit)

  const handleQ_i_5Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_5',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_5Change = param => {
    dispatch(
      changeProperty({
        value: 'start_5',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_5Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_5',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_5Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_5',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_5 = (parseFloat(finish_5) - parseFloat(start_5)).toFixed(4)
  const permissible_error_5 = (
    ((parseFloat(v_u_i_5) - parseFloat(v_y_i_5)) / parseFloat(v_y_i_5)) *
    100
  ).toFixed(2)
  const indicationPass_5 =
    permissible_error_5 >= -parseFloat(q_max_limit) &&
    permissible_error_5 <= parseFloat(q_max_limit)

  const handleQ_i_6Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_6',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_6Change = param => {
    dispatch(
      changeProperty({
        value: 'start_6',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_6Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_6',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_6Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_6',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_6 = (parseFloat(finish_6) - parseFloat(start_6)).toFixed(4)
  const permissible_error_6 = (
    ((parseFloat(v_u_i_6) - parseFloat(v_y_i_6)) / parseFloat(v_y_i_6)) *
    100
  ).toFixed(2)
  const indicationPass_6 =
    permissible_error_6 >= -parseFloat(q_max_limit) &&
    permissible_error_6 <= parseFloat(q_max_limit)

  const handleQ_i_7Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_7',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_7Change = param => {
    dispatch(
      changeProperty({
        value: 'start_7',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_7Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_7',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_7Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_7',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_7 = (parseFloat(finish_7) - parseFloat(start_7)).toFixed(4)
  const permissible_error_7 = (
    ((parseFloat(v_u_i_7) - parseFloat(v_y_i_7)) / parseFloat(v_y_i_7)) *
    100
  ).toFixed(2)
  const indicationPass_7 =
    permissible_error_7 >= -parseFloat(q_max_limit) &&
    permissible_error_7 <= parseFloat(q_max_limit)

  const handleQ_i_8Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_8',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_8Change = param => {
    dispatch(
      changeProperty({
        value: 'start_8',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_8Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_8',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_8Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_8',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_8 = (parseFloat(finish_8) - parseFloat(start_8)).toFixed(4)
  const permissible_error_8 = (
    ((parseFloat(v_u_i_8) - parseFloat(v_y_i_8)) / parseFloat(v_y_i_8)) *
    100
  ).toFixed(2)
  const indicationPass_8 =
    permissible_error_8 >= -parseFloat(q_max_limit) &&
    permissible_error_8 <= parseFloat(q_max_limit)

  const handleQ_i_9Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_9',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleStart_9Change = param => {
    dispatch(
      changeProperty({
        value: 'start_9',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleFinish_9Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_9',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }
  const handleV_y_i_9Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_9',
        label: param.replace(',', '.').replace(/[^0-9.]/g, ''),
      }),
    )
  }

  const v_u_i_9 = (parseFloat(finish_9) - parseFloat(start_9)).toFixed(4)
  const permissible_error_9 = (
    ((parseFloat(v_u_i_9) - parseFloat(v_y_i_9)) / parseFloat(v_y_i_9)) *
    100
  ).toFixed(2)
  const indicationPass_9 =
    permissible_error_9 >= -parseFloat(q_max_limit) &&
    permissible_error_9 <= parseFloat(q_max_limit)

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indicationsPass',
        label: [
          indicationPass_1,
          indicationPass_2,
          indicationPass_3,
          indicationPass_4,
          indicationPass_5,
          indicationPass_6,
          indicationPass_7,
          indicationPass_8,
          indicationPass_9,
        ],
      }),
    )
  }, [
    indicationPass_1,
    indicationPass_2,
    indicationPass_3,
    indicationPass_4,
    indicationPass_5,
    indicationPass_6,
    indicationPass_7,
    indicationPass_8,
    indicationPass_9,
    dispatch,
  ])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indications',
        label: [
          {
            q_i_1: q_i_1,
            q_i_2: q_i_2,
            q_i_3: q_i_3,
            start_1: start_1,
            start_2: start_2,
            start_3: start_3,
            finish_1: finish_1,
            finish_2: finish_2,
            finish_3: finish_3,
            v_y_i_1: v_y_i_1,
            v_y_i_2: v_y_i_2,
            v_y_i_3: v_y_i_3,
            v_u_i_1: v_u_i_1,
            v_u_i_2: v_u_i_2,
            v_u_i_3: v_u_i_3,
            permissible_error_1: permissible_error_1,
            permissible_error_2: permissible_error_2,
            permissible_error_3: permissible_error_3,
          },
          {
            q_i_1: q_i_4,
            q_i_2: q_i_5,
            q_i_3: q_i_6,
            start_1: start_4,
            start_2: start_5,
            start_3: start_6,
            finish_1: finish_4,
            finish_2: finish_5,
            finish_3: finish_6,
            v_y_i_1: v_y_i_4,
            v_y_i_2: v_y_i_5,
            v_y_i_3: v_y_i_6,
            v_u_i_1: v_u_i_4,
            v_u_i_2: v_u_i_5,
            v_u_i_3: v_u_i_6,
            permissible_error_1: permissible_error_4,
            permissible_error_2: permissible_error_5,
            permissible_error_3: permissible_error_6,
          },
          {
            q_i_1: q_i_7,
            q_i_2: q_i_8,
            q_i_3: q_i_9,
            start_1: start_7,
            start_2: start_8,
            start_3: start_9,
            finish_1: finish_7,
            finish_2: finish_8,
            finish_3: finish_9,
            v_y_i_1: v_y_i_7,
            v_y_i_2: v_y_i_8,
            v_y_i_3: v_y_i_9,
            v_u_i_1: v_u_i_7,
            v_u_i_2: v_u_i_8,
            v_u_i_3: v_u_i_9,
            permissible_error_1: permissible_error_7,
            permissible_error_2: permissible_error_8,
            permissible_error_3: permissible_error_9,
          },
        ],
      }),
    )
  }, [
    q_i_1,
    q_i_2,
    q_i_3,
    q_i_4,
    q_i_5,
    q_i_6,
    q_i_7,
    q_i_8,
    q_i_9,
    start_1,
    start_2,
    start_3,
    start_4,
    start_5,
    start_6,
    start_7,
    start_8,
    start_9,
    finish_1,
    finish_2,
    finish_3,
    finish_4,
    finish_5,
    finish_6,
    finish_7,
    finish_8,
    finish_9,
    v_y_i_1,
    v_y_i_2,
    v_y_i_3,
    v_y_i_4,
    v_y_i_5,
    v_y_i_6,
    v_y_i_7,
    v_y_i_8,
    v_y_i_9,
    v_u_i_1,
    v_u_i_2,
    v_u_i_3,
    v_u_i_4,
    v_u_i_5,
    v_u_i_6,
    v_u_i_7,
    v_u_i_8,
    v_u_i_9,
    permissible_error_1,
    permissible_error_2,
    permissible_error_3,
    permissible_error_4,
    permissible_error_5,
    permissible_error_6,
    permissible_error_7,
    permissible_error_8,
    permissible_error_9,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()
      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  useEffect(() => {
    dispatch(setName('Определение относительной погрешности'))
  }, [dispatch])

  useEffect(() => {
    dispatch(toggleEnableStep(enableNextStep))
  }, [enableNextStep])

  const block1Ref = useRef(null)
  const block2Ref = useRef(null)
  const block3Ref = useRef(null)
  const block4Ref = useRef(null)
  const block5Ref = useRef(null)
  const block6Ref = useRef(null)
  const block7Ref = useRef(null)
  const block8Ref = useRef(null)
  const block9Ref = useRef(null)

  const isBlock1Complete = useMemo(() => {
    if (q_i_1 && start_1 && finish_1 && v_y_i_1) {
      return true
    } else {
      return false
    }
  }, [q_i_1, start_1, finish_1, v_y_i_1])
  const isBlock2Complete = useMemo(() => {
    if (q_i_2 && start_2 && finish_2 && v_y_i_2) {
      return true
    } else {
      return false
    }
  }, [q_i_2, start_2, finish_2, v_y_i_2])
  const isBlock3Complete = useMemo(() => {
    if (q_i_3 && start_3 && finish_3 && v_y_i_3) {
      return true
    } else {
      return false
    }
  }, [q_i_3, start_3, finish_3, v_y_i_3])
  const isBlock4Complete = useMemo(() => {
    if (q_i_4 && start_4 && finish_4 && v_y_i_4) {
      return true
    } else {
      return false
    }
  }, [q_i_4, start_4, finish_4, v_y_i_4])
  const isBlock5Complete = useMemo(() => {
    if (q_i_5 && start_5 && finish_5 && v_y_i_5) {
      return true
    } else {
      return false
    }
  }, [q_i_5 || start_5 || finish_5 || v_y_i_5])
  const isBlock6Complete = useMemo(() => {
    if (q_i_6 && start_6 && finish_6 && v_y_i_6) {
      return true
    } else {
      return false
    }
  }, [q_i_6, start_6, finish_6, v_y_i_6])
  const isBlock7Complete = useMemo(() => {
    if (q_i_7 && start_7 && finish_7 && v_y_i_7) {
      return true
    } else {
      return false
    }
  }, [q_i_7, start_7, finish_7, v_y_i_7])
  const isBlock8Complete = useMemo(() => {
    if (q_i_8 && start_8 && finish_8 && v_y_i_8) {
      return true
    } else {
      return false
    }
  }, [q_i_8, start_8, finish_8, v_y_i_8])
  const isBlock9Complete = useMemo(() => {
    if (q_i_9 && start_9 && finish_9 && v_y_i_9) {
      return true
    } else {
      return false
    }
  }, [q_i_9, start_9, finish_9, v_y_i_9])

  useEffect(() => {
    const scrollToRef = ref => {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (expandAll === false) {
      if (!isBlock1Complete) {
        setExpanded_1(true)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock2Complete) {
        setExpanded_1(false)
        setExpanded_2(true)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock3Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(true)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock4Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(true)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock5Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(true)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock6Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(true)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock7Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(true)
        setExpanded_8(false)
        setExpanded_9(false)
        return
      }

      if (!isBlock8Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(true)
        setExpanded_9(false)
        return
      }

      if (!isBlock9Complete) {
        setExpanded_1(false)
        setExpanded_2(false)
        setExpanded_3(false)
        setExpanded_4(false)
        setExpanded_5(false)
        setExpanded_6(false)
        setExpanded_7(false)
        setExpanded_8(false)
        setExpanded_9(true)
        return
      }
    }
  }, [
    isBlock1Complete,
    isBlock2Complete,
    isBlock3Complete,
    isBlock4Complete,
    isBlock5Complete,
    isBlock6Complete,
    isBlock7Complete,
    isBlock8Complete,
    isBlock9Complete,
    expandAll,
  ])

  useEffect(() => {
    if (expandAll === true) {
      setExpanded_1(true)
      setExpanded_2(true)
      setExpanded_3(true)
      setExpanded_4(true)
      setExpanded_5(true)
      setExpanded_6(true)
      setExpanded_7(true)
      setExpanded_8(true)
      setExpanded_9(true)
    } else {
      setExpanded_1(true)
      setExpanded_2(false)
      setExpanded_3(false)
      setExpanded_4(false)
      setExpanded_5(false)
      setExpanded_6(false)
      setExpanded_7(false)
      setExpanded_8(false)
      setExpanded_9(false)
    }
  }, [expandAll])

  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#132532',
            borderRadius: '12px',
            boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)',
            padding: '10px 40px 13px',
            marginTop: '-10px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography
          sx={{
            p: 0,
            width: '100%',
            textAlign: 'center',
            lineHeight: '23px',
            fontSize: '15px',
            color: 'white',
          }}
        >
          Заполните показания на начало в первом замере
        </Typography>
      </Popover>
      <div className='flexContainerWithGap' style={{ marginTop: '0px', marginBottom: '2px' }}>
        {tariffIds && tariffIds.includes(5) && (
          <div className='width50Percent'>
            <Container style={{ gap: '14px', flexFlow: 'row' }}>
              <div
                className='width50Percent'
                aria-describedby={id}
                onClick={event => {
                  !((start_1 || start_1 === 0) && q_min && q_t && q_min_limit && diameter) &&
                    handleClickPopover(event)
                }}
              >
                {!!!startEndDataUpdate && (
                  <Button
                    disabled={
                      (start_1 || start_1 === 0) && q_min && q_t && q_min_limit && diameter
                        ? false
                        : true
                    }
                    onMouseDown={() => {
                      generateQactual()
                    }}
                    onClick={() => {
                      setStartEndDataUpdate(true)
                      dispatch(setShow(true))
                      setExpandAll(true)

                      setTimeout(() => {
                        generateValuesStartFinish()
                        setStartEndDataUpdate(false)
                        dispatch(setShow(false))
                      }, 1100)
                    }}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '16px 24px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Тестовая среда
                  </Button>
                )}
                {startEndDataUpdate && (
                  <Button
                    disabled={true}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '16px 24px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <CircularProgress
                        variant='determinate'
                        sx={{
                          color: theme =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant='indeterminate'
                        disableShrink
                        sx={{
                          color: theme => (theme.palette.mode === 'light' ? 'grey' : 'grey'),
                          animationDuration: '550ms',
                          position: 'absolute',
                          opacity: 0.6,
                          left: 0,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                        size={30}
                        thickness={4}
                      />
                    </Box>
                  </Button>
                )}
              </div>
              <div className='width50Percent'>
                <Button
                  disabled={
                    (start_1 || start_1 === 0) && q_min && q_max && q_t && q_min_limit && diameter
                      ? false
                      : true
                  }
                  onClick={() => {
                    setStartEndDataUpdate(true)
                    setExpandAll(false)

                    setTimeout(() => {
                      defaultValuesStartFinish()
                      dispatch(changeProperty({ value: 'q_actual', label: '' }))
                      setStartEndDataUpdate(false)
                    }, 1100)
                  }}
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '16px 24px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    width: '100%',
                  }}
                >
                  {'Сбросить все'}
                </Button>
              </div>
            </Container>
            <ParagraphIndications style={{ textAlign: 'center', width: '100%' }}>
              Генерация данных приведена как пример в помощь поверителю. При создании протокола все
              данные необходимо скорректировать
            </ParagraphIndications>
          </div>
        )}
      </div>

      <div>
        <Typography variant='h2' style={{ marginBottom: '14px' }}>
          {q_min ? `Значение Qmin : ${q_min}, м³/ч` : 'Введите Qmin'}
        </Typography>

        <AccordionComponent
          isExpanded={expanded_1}
          title={`Первый замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_1 ? start_1 : ''}
                  actions={{
                    change: handleStart_1Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_1 ? q_i_1 : ''}
                  actions={{
                    change: handleQ_i_1Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_1 ? finish_1 : ''}
                  actions={{
                    change: handleFinish_1Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_1 ? v_y_i_1 : ''}
                  actions={{
                    change: handleV_y_i_1Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_1 && finish_1 && v_y_i_1 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_1
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_1 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_1} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_1 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_1} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_1 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_min_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_2}
          title={`Второй замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          btlr={'12px'}
          btrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_2 ? start_2 : ''}
                  actions={{
                    change: handleStart_2Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_2 ? q_i_2 : ''}
                  actions={{
                    change: handleQ_i_2Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_2 ? finish_2 : ''}
                  actions={{
                    change: handleFinish_2Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_2 ? v_y_i_2 : ''}
                  actions={{
                    change: handleV_y_i_2Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_2 && finish_2 && v_y_i_2 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_2
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_2 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_2} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_2 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_2} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_2 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_min_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_3}
          title={`Третий замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          btlr={'12px'}
          btrr={'12px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_3 ? start_3 : ''}
                  actions={{
                    change: handleStart_3Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_3 ? q_i_3 : ''}
                  actions={{
                    change: handleQ_i_3Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_3 ? finish_3 : ''}
                  actions={{
                    change: handleFinish_3Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_3 ? v_y_i_3 : ''}
                  actions={{
                    change: handleV_y_i_3Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_3 && finish_3 && v_y_i_3 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_3
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_3 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_3} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_3 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_3} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_3 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_min_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>
      <div>
        <Typography variant='h2' style={{ marginBottom: '14px', marginTop: '16px' }}>
          {q_t ? `Значение 1.1 * Qt : ${(1.1 * q_t).toFixed(3)}, м³/ч` : 'Введите Qt'}
        </Typography>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_4}
          title={`Четвертый замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_4 ? start_4 : ''}
                  actions={{
                    change: handleStart_4Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_4 ? q_i_4 : ''}
                  actions={{
                    change: handleQ_i_4Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_4 ? finish_4 : ''}
                  actions={{
                    change: handleFinish_4Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_4 ? v_y_i_4 : ''}
                  actions={{
                    change: handleV_y_i_4Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_4 && finish_4 && v_y_i_4 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_4
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика:
                  <span className={`${indicationPass_4 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_4} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_4 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_4} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_4 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_5}
          title={`Пятый замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          btlr={'12px'}
          btrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_5 ? start_5 : ''}
                  actions={{
                    change: handleStart_5Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_5 ? q_i_5 : ''}
                  actions={{
                    change: handleQ_i_5Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_5 ? finish_5 : ''}
                  actions={{
                    change: handleFinish_5Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_5 ? v_y_i_5 : ''}
                  actions={{
                    change: handleV_y_i_5Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_5 && finish_5 && v_y_i_5 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_5
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_5 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_5} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_5 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_5} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_5 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_6}
          title={`Шестой замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          btlr={'12px'}
          btrr={'12px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_6 ? start_6 : ''}
                  actions={{
                    change: handleStart_6Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_6 ? q_i_6 : ''}
                  actions={{
                    change: handleQ_i_6Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_6 ? finish_6 : ''}
                  actions={{
                    change: handleFinish_6Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_6 ? v_y_i_6 : ''}
                  actions={{
                    change: handleV_y_i_6Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_6 && finish_6 && v_y_i_6 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_6
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_6 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_6} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_6 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_6} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_6 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <Typography variant='h2' style={{ marginBottom: '14px', marginTop: '16px' }}>
          {'Значение Qmax факт.'}
        </Typography>
      </div>

      <QActualBox style={{ margin: 0, padding: 0, marginBottom: '16px' }}>
        <InputQActualElement style={{ margin: 0 }}>
          <Input
            type={'text'}
            placeholder={'Qmax фактический'}
            value={q_actual}
            isDisabled={startEndDataUpdate}
            actions={{
              change: handleQ_actualChange,
            }}
            className={'backgroundColorWhite'}
          />
        </InputQActualElement>
      </QActualBox>

      <div>
        <AccordionComponent
          isExpanded={expanded_7}
          title={`Седьмой замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_7 ? start_7 : ''}
                  actions={{
                    change: handleStart_7Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_7 ? q_i_7 : ''}
                  actions={{
                    change: handleQ_i_7Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_7 ? finish_7 : ''}
                  actions={{
                    change: handleFinish_7Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_7 ? v_y_i_7 : ''}
                  actions={{
                    change: handleV_y_i_7Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_7 && finish_7 && v_y_i_7 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_7
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_7 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_7} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_7 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_7} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_7 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_8}
          title={`Восьмой замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          bblr={'12px'}
          bbrr={'12px'}
          btlr={'12px'}
          btrr={'12px'}
          dataType={'custom'}
          data={
            <Container margin={'0px 0px 0px'}>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_8 ? start_8 : ''}
                  actions={{
                    change: handleStart_8Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_8 ? q_i_8 : ''}
                  actions={{
                    change: handleQ_i_8Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_8 ? finish_8 : ''}
                  actions={{
                    change: handleFinish_8Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_8 ? v_y_i_8 : ''}
                  actions={{
                    change: handleV_y_i_8Change,
                  }}
                />
              </IndicationBox>
            </Container>
          }
        />
        <React.Fragment>
          {start_8 && finish_8 && v_y_i_8 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_8
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_8 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_8} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_8 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_8} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_8 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>

      <div>
        <AccordionComponent
          isExpanded={expanded_9}
          title={`Девятый замер`}
          titleSize={'17px'}
          titleMt={'4px'}
          iconSize={'large'}
          backgroundColor={'white'}
          padding={'0px 0px'}
          plSum={'8px'}
          prSum={'8px'}
          plDet={'8px'}
          prDet={'8px'}
          btlr={'12px'}
          btrr={'12px'}
          bblr={'12px'}
          bbrr={'12px'}
          dataType={'custom'}
          data={
            <Container>
              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на начало'}
                  placeholder={'0.0000'}
                  value={start_9 ? start_9 : ''}
                  actions={{
                    change: handleStart_9Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Расход во время измерения'}
                  placeholder={'0.0000'}
                  value={q_i_9 ? q_i_9 : ''}
                  actions={{
                    change: handleQ_i_9Change,
                  }}
                />
              </IndicationBox>

              <IndicationBox>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Показания на конец'}
                  placeholder={'0.0000'}
                  value={finish_9 ? finish_9 : ''}
                  actions={{
                    change: handleFinish_9Change,
                  }}
                />
              </IndicationBox>

              <IndicationBoxLastElement>
                <Input
                  isDisabled={startEndDataUpdate}
                  label={'Объем, по показаниям эталона, м³'}
                  placeholder={'0.0000'}
                  value={v_y_i_9 ? v_y_i_9 : ''}
                  actions={{
                    change: handleV_y_i_9Change,
                  }}
                />
              </IndicationBoxLastElement>
            </Container>
          }
        />
        <React.Fragment>
          {start_9 && finish_9 && v_y_i_9 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_9
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ margin: '10px 0px 0px', width: '100%', padding: '10px' }}
            >
              {true && (
                <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                  Объём по показаниям счётчика :
                  <span className={`${indicationPass_9 ? '' : ''}`} style={{ margin: '0 4px' }}>
                    {v_u_i_9} м³
                  </span>
                </p>
              )}
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Относительная погрешность :
                <span
                  className={`${indicationPass_9 ? 'text_true' : 'text_false'}`}
                  style={{ margin: '0 4px' }}
                >
                  {permissible_error_9} %
                </span>
              </p>
              <p className={`indications_box-gas_bottom`} style={{ margin: '0 2px' }}>
                Допускаемые пределы погрешности :
                <span className={`${indicationPass_9 ? '' : ''}`} style={{ margin: '0 4px' }}>
                  ± {q_max_limit} %
                </span>
              </p>
            </div>
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  )
}

export default Indications
