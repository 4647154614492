import React, { useState, useEffect } from 'react'
import Selector from '../Selector/reactSelect/Selector'
import R from '../../../../services/app/client-server/request.service.ts'
import { useSelector } from 'react-redux'

const ResponsibleName = ({
  isLabel = false,
  responsiblePassportName,
  setResponsiblePassportName,
}) => {
  const [data, setData] = useState([])
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const options = data
    ? data.map(data => ({
        value: data?.responsible_passport_name,
        label: data?.responsible_passport_name,
      }))
    : []

  const getValue = selectedOption => {
    setResponsiblePassportName(selectedOption ? selectedOption.value : '')
  }

  useEffect(() => {
    ;(async () => {
      const { data: responsibleName } = await R.getResponsibleNameList(companyId)
      setData(responsibleName.data)
    })()
  }, [companyId])

  return (
    <Selector
      placeholder={'Выберите ответственного из списка'}
      options={options}
      onChange={getValue}
      value={
        responsiblePassportName
          ? options.find(option => option.value === responsiblePassportName)
          : null
      }
      isClearable={true}
      isSearchable={true}
      noOptionsMessage={() => 'Нет доступных ответственных за ведение карточки'}
      label={
        isLabel
          ? {
              text: 'ФИО ответственного',
              topBg: 'white',
              bottomBg: 'white',
            }
          : null
      }
    />
  )
}

export default ResponsibleName
