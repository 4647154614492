// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------

import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import ArrowLeftIcon from '../../img/icon/ArrowLeftIcon'

const BackButton: React.FC<{ to: string; label?: string; margin?: string }> = ({
  to,
  label,
  margin,
}): ReactElement => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div
        style={{ margin: margin ? margin : '' }}
        className='path_to_back'
        onClick={() => navigate(to)}
      >
        <ArrowLeftIcon />
        <Typography variant='subtitle2'>{label}</Typography>
      </div>
    </React.Fragment>
  )
}

export default BackButton
