// ----------------------------------------------------------------
// просмотр страницы приборов
// ----------------------------------------------------------------
import { z } from 'zod'

export interface IImage {
  id: number
  name: string
  path: string
}

export interface IPdf {
  id: number
  name: string
  path: string
  size: number
}

export interface IMethod {
  id: number
  name: string
  measurement_id: number
}

export interface IAdditionals {
  mpi_cold: number
  mpi_hot: number
  mpi: number
  text: string
}

export interface IModification {
  name: string
  diameter: number
  type: string
  conversion_factor: string
  q_max_horizontal: number
  q_max_limit_cold: number | null
  q_max_limit_hot: number | null
  q_max_vertical: number
  q_min_horizontal: number
  q_min_limit_cold: number | null
  q_min_limit_hot: number | null
  q_min_vertical: number
  q_t_horizontal: number
  q_t_vertical: number
  mpi_cold?: number
  mpi_hot?: number
  correction: string | null
  max_working_pressure_alum: number
  max_working_pressure_iron: number
  max_working_pressure_steel: number
  q_max: number
  q_min: number
  q_nom: number
  standart_size: string
  additionals: IAdditionals[]
}

export interface IMeterData {
  fif_number: string
  name_si: string
  type_si: string
  base: string
  is_actual_type_link: boolean
  manufacturer_name: string
  manufacturer_cities: string
  manufacturer_countries: string
  methods: IMethod[]
  methods_text: string
  modifications: IModification[]
  pdf: IPdf[]
  images: IImage[]
  type_link: string
  q_t_formula: string
  q_min_limit_negative: string
  q_min_limit_positive: string
  q_max_limit_negative: string
  q_max_limit_positive: string
  mpi_cold?: number
  mpi_hot?: number
  mpi?: number
}

// ----------------------------------------------------------------
// просмотр страницы комплектов сп
// ----------------------------------------------------------------

export interface IMeasurement {
  id: number
  name: string
  doc_title_fgis?: string
}

export interface IMethod {
  id: number
  name: string
  measurement_id: number
  measurement: IMeasurement
}

export interface IPoint {
  id: number
  name: string
  verification_method: 'field' | 'lab' | string
  verification_type: string
  additional?: string | null
}

export interface IArshinMaster {
  id: number
  name: string
  surname: string
  patr: string
  snils: string
}

export interface IKit {
  id: number
  status: string
  is_sync_available: boolean
  device_id: number
  device_name: string
  name: string
  type: string
  kit_group_id: number
  kit_group_name: string
  register_number: string
  manufacturer_number: string
  inventory_number: string
  kit_verification_link?: string
  kit_verification_expired_date?: string
  kit_maintenance_expired_date?: string
  passport_pdf_id?: number
  passport_pdf_accounting_number?: number
}

export interface ICaseData {
  id: number
  inner_name: string
  method: IMethod
  points: IPoint[]
  arshinMaster?: IArshinMaster
  kits: IKit[]
}

// ----------------------------------------------------------------
// просмотр страницы протоколов ( временно - мобильная версия )
// ----------------------------------------------------------------

const PointSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  method_id: z.number().int().optional(),
  verification_type: z.string().max(20),
  verification_method: z.string().max(20),
  additional: z.string().max(50).nullable(),
})

const MethodSchema = z.object({
  id: z.number().int(),
  name: z.string().max(50),
  measurement_id: z.number().int(),
})

const MeasurementSchema = z.object({
  id: z.number().int(),
  name: z.string().max(50),
})

const SentBySchema = z.object({
  id: z.number().int(),
  name: z.string().max(100),
  surname: z.string().max(100),
  patr: z.string().max(100).nullable(),
})

const ArchivedCaseCompanySchema = z.object({
  id: z.number().int(),
  name: z.string().max(100),
  address: z.string().max(512),
  accreditation_unique_id: z.string().max(20),
})

const ArchivedCaseDeviceSchema = z.object({
  id: z.number().int(),
  name: z.string().max(100),
})

const ArchivedCaseKitSchema = z.object({
  id: z.number().int(),
  name: z.string().max(255),
  manufacturer_number: z.string().max(255),
  register_number: z.string().max(255),
  type: z.string().max(255),
  kit_verification_link: z.string().max(255),
  device: ArchivedCaseDeviceSchema,
})

const OriginalCaseArshinMasterSchema = z.object({
  id: z.number().int(),
  name: z.string().max(100),
  surname: z.string().max(100),
  patr: z.string().max(100),
})

const OriginalCaseSchema = z.object({
  id: z.number().int(),
  inner_name: z.string().max(64),
  arshinMaster: OriginalCaseArshinMasterSchema,
  verification_types: z.array(z.string().max(20)),
  verification_methods: z.array(z.string().max(20)),
  points: z.array(PointSchema),
  available_dates: z.array(z.string().max(10)),
  is_usable: z.boolean(),
})

const MeterModificationsSchema = z.object({
  name: z.string(),
  diameter: z.number(),
  type: z.string(),
  conversion_factor: z.string().max(12),
  q_min_vertical: z.number().nullable(),
  q_min_horizontal: z.number().nullable(),
  q_t_vertical: z.number().nullable(),
  q_t_horizontal: z.number().nullable(),
  q_max_vertical: z.number().nullable(),
  q_max_horizontal: z.number().nullable(),
  q_min_limit_cold: z.number().nullable(),
  q_max_limit_cold: z.number().nullable(),
  q_min_limit_hot: z.number().nullable(),
  q_max_limit_hot: z.number().nullable(),
  additionals: z.array(z.string()),
})

const MeterSchema = z.object({
  fif_number: z.string().max(10),
  name_si: z.string().max(120),
  type_si: z.string().max(150),
  manufacturer_name: z.string().max(255),
  mpi_cold: z.number().nullable(),
  mpi_hot: z.number().nullable(),
  modifications: z.array(MeterModificationsSchema),
})

const ProtocolIndicationSchema = z.object({
  id: z.number().int(),
  q_i_1: z.number(),
  q_i_2: z.number(),
  q_i_3: z.number(),
  start_1: z.number(),
  start_2: z.number(),
  start_3: z.number(),
  finish_1: z.number(),
  finish_2: z.number(),
  finish_3: z.number(),
  v_u_i_1: z.number(),
  v_u_i_2: z.number(),
  v_u_i_3: z.number(),
  v_y_i_1: z.number(),
  v_y_i_2: z.number(),
  v_y_i_3: z.number(),
  permissible_error_1: z.number(),
  permissible_error_2: z.number(),
  permissible_error_3: z.number(),
})

const ProtocolConditionSchema = z.object({
  id: z.number().int(),
  temperature_environment: z.number(),
  water_temp: z.number(),
  relative_humidity: z.number(),
  atmospheric_pressure: z.number(),
})

const ArchivedCaseSchema = z.object({
  id: z.number().int(),
  protocol_id: z.number().int(),
  company_id: z.number().int(),
  company: ArchivedCaseCompanySchema,
  inner_name: z.string().max(64),
  snils: z.string().max(15),
  arshin_master_name: z.string().max(100),
  arshin_master_surname: z.string().max(100),
  arshin_master_patr: z.string().max(100),
  arshin_master_post: z.string().max(100),
  archivedKits: z.array(ArchivedCaseKitSchema),
})

export const IModelProtocolMi1592MobileShowSchema = z.object({
  id: z.number().int(),
  number: z.number().int(),
  point_id: z.number().int(),
  point: PointSchema,
  method: MethodSchema,
  measurement: MeasurementSchema,
  meterable_fif_number: z.string().max(10),
  customer_arshin_name: z.string().max(255),
  customer_address: z.string().max(255),
  customer_fullname: z.string().max(255).nullable(),
  customer_phone: z.string().max(20).nullable(),
  verification_date: z.string().max(10),
  next_verification_date: z.string().max(10),
  status: z.enum(['ready', 'checked', 'sent']),
  arshin_id: z.number().nullable(),
  status_arshin: z.enum(['not-sent', 'publish-error', 'sent', 'published']),
  arshin_publication_at: z.string().max(19).nullable(),
  fsa_id: z.number().nullable(),
  status_fsa: z.enum(['not-sent', 'publish-error', 'sent', 'published']),
  fsa_publication_at: z.string().max(19).nullable(),
  sent_at: z.string().max(19).nullable(),
  sent_by: SentBySchema.nullable(),
  tightness_accepted: z.boolean(),
  total_accepted: z.boolean(),
  mechanical_accepted: z.boolean(),
  mark_passport: z.boolean(),
  mark_si: z.boolean(),
  agreement_number: z.string().max(20).nullable(),
  details: z.object({
    id: z.number().int(),
    meter_type: z.enum(['cold', 'hot']),
    meter_factory_number: z.string().max(64),
    meter_factory_year: z.number().int(),
    diameter: z.number(),
    q_t: z.number(),
    q_min: z.number(),
    q_max: z.number(),
    q_actual: z.number(),
    q_min_limit: z.number(),
    q_max_limit: z.number(),
    orientation: z.enum(['vertical', 'horizontal']),
    modification: z.string().max(64).nullable(),
    additional_option: z.string().max(64).nullable(),
    is_custom_modification: z.boolean(),
    name_si: z.string().max(120).nullable(),
    type_si: z.string().max(150).nullable(),
    manufacturer_name: z.string().max(255).nullable(),
    mpi: z.number().int(),
    conversion_factor: z.string().max(12).nullable(),
  }),
  protocolIndication: z.array(ProtocolIndicationSchema),
  protocolCondition: z.array(ProtocolConditionSchema),
  archivedCase: ArchivedCaseSchema,
  originalCase: OriginalCaseSchema,
  files: z.array(z.unknown()),
  meter: MeterSchema,
  created_at: z.string().max(19),
  created_by: SentBySchema,
})

export type IModelProtocolMi1592MobileShow = z.infer<typeof IModelProtocolMi1592MobileShowSchema>

// export interface IModelProtocolMi1592MobileShow {
//   arshin_id: string
//   agreement_number: string
//   archivedCase: {
//     archivedKits: Array<{
//       device: {
//         id: number
//         name: string
//       }
//       device_id: number
//       id: number
//       kit_group_id: number
//       kit_verification_link: string
//       manufacturer_number: string
//       name: string
//       protocol_archived_case_id: number
//       register_number: string
//       type: string
//     }>
//     arshin_master_name: string
//     arshin_master_patr: string
//     arshin_master_post: string
//     arshin_master_surname: string
//     company: {
//       accreditation_unique_id: string
//       activation_date: string
//       address: string
//       agreement_date: string
//       agreement_number: string
//       bank_address: string
//       bank_name: string
//       bic: string
//       bin: string
//       checking_account: string
//       correspondent_account: string
//       created_at: string
//       created_by: number
//       deleted_at: null | any
//       deleted_by: null | any
//       director_full_name: string
//       email: string
//       fix_rate_price: string
//       id: number
//       inn: string
//       kpp: string
//       lanbilling_id: number
//       licensed_from: string
//       licensed_to: string
//       logo_id: null | any
//       name: string
//       paid_to: null | any
//       phone: string
//       postpayment_from: string
//       region_id: number
//       sign_cipher: string
//       status: string
//       subscription_id: number
//       updated_at: string
//       updated_by: number
//     }
//     company_id: number
//     id: number
//     inner_name: string
//     protocol_id: number
//     snils: string
//   }
//   archived_at: null | any
//   checked_at: null | any
//   createdBy: {
//     created_at: null | any
//     deleted_at: null | any
//     email_verified_at: null | any
//     id: number
//     is_has_arshin_master: boolean
//     last_event: string
//     name: string
//     patr: string
//     surname: string
//     updated_at: null | any
//   }
//   created_at: {
//     full: string
//   }
//   customer_address: string
//   customer_arshin_name: string
//   customer_fullname: string
//   customer_phone: string
//   deleted_at: null | any
//   details: {
//     additional_option: null | any
//     conversion_factor: null | any
//     diameter: number
//     id: number
//     is_custom_modification: boolean
//     is_edited_by_user: boolean
//     manufacturer_name: string
//     meter_factory_number: string
//     meter_factory_year: number
//     meter_type: string
//     meter_updated_at: string
//     meter_view_status: string
//     modification: string
//     mpi: number
//     name_si: string
//     orientation: string
//     q_actual: string
//     q_max: string
//     q_max_limit: number
//     q_min: string
//     q_min_limit: number
//     q_t: string
//     type_si: string
//   }
//   files: Array<any>
//   id: number
//   mark_passport: boolean
//   mark_si: boolean
//   measurement: {
//     doc_title_fgis: string
//     id: number
//     name: string
//   }
//   mechanical_accepted: boolean
//   meter: {
//     fif_number: string
//     manufacturer_name: string
//     modifications: Array<{
//       additionals: Array<any>
//       conversion_factor: null | any
//       diameter: number
//       name: string
//       q_max_horizontal: null | number
//       q_max_limit_cold: null | number
//       q_max_limit_hot: null | number
//       q_max_vertical: null | number
//       q_min_horizontal: null | number
//       q_min_limit_cold: null | number
//       q_min_limit_hot: null | number
//       q_min_vertical: null | number
//       q_t_horizontal: null | number
//       q_t_vertical: null | number
//       type: string
//     }>
//     mpi_cold: null | number
//     mpi_hot: null | number
//     name_si: string
//     type_si: string
//   }
//   meterable_fif_number: string
//   method: {
//     id: number
//     measurement: {
//       doc_title_fgis: string
//       id: number
//       name: string
//     }
//     measurement_id: number
//     name: string
//   }
//   next_verification_date: string
//   number: number
//   originalCase: {
//     arshinMaster: {
//       id: number
//       name: string
//       patr: string
//       surname: string
//     }
//     available_dates: Array<string>
//     id: number
//     inner_name: string
//     is_usable: boolean
//     points: Array<{
//       additional: string
//       id: number
//       name: string
//       verification_method: string
//       verification_type: string
//     }>
//     verification_methods: Array<string>
//     verification_types: Array<string>
//   }
//   point: {
//     additional: string
//     id: number
//     name: string
//     verification_method: string
//     verification_type: string
//   }
//   point_id: number
//   protocolCondition: Array<{
//     atmospheric_pressure: string
//     id: number
//     relative_humidity: string
//     temperature_environment: string
//     water_temp: string
//   }>
//   protocolIndication: Array<{
//     finish_1: number
//     finish_2: number
//     finish_3: number
//     id: number
//     permissible_error_1: string | number
//     permissible_error_2: string | number
//     permissible_error_3: string | number
//     q_i_1: number
//     q_i_2: number
//     q_i_3: number
//     start_1: number
//     start_2: number
//     start_3: number
//     v_u_i_1: number
//     v_u_i_2: number
//     v_u_i_3: number
//     v_y_i_1: number
//     v_y_i_2: number
//     v_y_i_3: number
//   }>
//   publication_date: string
//   status: string
//   status_arshin: string
//   status_fsa: string
//   tightness_accepted: boolean
//   total_accepted: boolean
//   updated_at: {
//     full: string
//   }
//   uuid: string
//   verification_date: string
// }
