// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import NavBarMobile from '../components/NavBarMobile'
import NavBarMobileHeader from '../components/NavBarMobileHeader.tsx'
import Feedback from '../components/LocalElements/Feedback/Feedback'
import PositionedSnackbar from '../components/LocalElements/Snackbar/Snackbar.jsx'

import RoutesComponent from '../../routes/RouteComponent'
import ModalComponent from '../components/LocalElements/Modal/Modal'
import FileViewerComponent from '../components/LocalElements/Modal/FileViewer'
import ImageViewerComponent from '../components/LocalElements/Modal/ImageViewer'

import ErrorBoundary from '../../services/app/processes/error-boundary.service'

const Home = () => {
  return (
    <React.Fragment>
      <section
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <PositionedSnackbar />
        <Feedback />
        <NavBarMobileHeader />
        <ErrorBoundary key={new Date().getTime()}>
          <RoutesComponent />
        </ErrorBoundary>
        <ModalComponent />
        <FileViewerComponent />
        <ImageViewerComponent />
        <NavBarMobile />
      </section>
    </React.Fragment>
  )
}

export default React.memo(Home)
